<script>
  import Vue from 'vue';
  import Vuex from 'vuex';

  Vue.use(Vuex);

  export default new Vuex.Store({
    state: {
      colors: []
    },
    mutations: {
      updateColors(state, array) {
        state.colors = array;
      }
    }
  });
</script>
