<template>
  <div class="float-btn-wrap">
    <a
      target="_blank"
      v-for="button in buttons"
      :key="button.social"
      class="float-btn"
      :href="button.link"
    >
      <img
        class="float-btn__icon"
        :src="`/images/icons/${button.social}.svg`"
        :alt="button.social"
      />
    </a>
  </div>
</template>

<script>
export default {
  name: "FloatActionButton",
  props: {
    buttons: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
.float-btn-wrap {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 30px;
  right: 20px;
  z-index: 200;

  .float-btn {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f0eeef;
    border: 1px solid #000;
    margin: 10px 0;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.6;
    }

    &__icon {
      width: 24px;
      height: 24px;
      color: #000;
    }
  }
}
</style>
