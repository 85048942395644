<template>
  <div class="header-popup-basket" v-if="cart">
    <div class="header-popup-basket__title h3 animate-delay">
      {{ $t("header.basket.title") }}
    </div>
    <div
      class="header-popup-basket__wrap animate-delay"
      v-if="cart.products.length > 0"
      data-custom_scroll
    >
      <div
        class="basket-item"
        v-for="(item, index) in cart.products"
        :key="index"
      >
        <div class="basket-item__header">
          <div class="basket-item__name">
            <router-link
              v-on:click.native="hidePopup"
              :to="'/product/' + item.slug"
              >{{ item.name }}</router-link
            >
          </div>
          <div
            class="basket-item__icon circle-btn-icon"
            @click="toggleFavorite(item)"
          >
            <div
              class="circle-btn-icon__icon"
              :class="{ active: item.favorites }"
            >
              <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path
                  d="M17.8754 5.31358C16.624 3.0898 13.8789 2.33824 11.7371 3.64059C11.2623 3.92886 10.8419 4.30464 10.5006 4.75248C8.9524 2.73976 6.12809 2.41031 4.18916 4.02667C3.75884 4.38185 3.39776 4.8194 3.12077 5.31358H3.12572C1.79023 7.68149 2.7597 9.85379 4.46121 11.9643L4.44637 11.9695C6.01433 13.8329 7.8296 15.4596 9.82789 16.8031C10.2384 17.0656 10.7578 17.0656 11.1683 16.8031C13.1715 15.4596 14.9819 13.8329 16.5498 11.9695L16.535 11.9643C18.2266 9.87438 19.2159 7.69178 17.8754 5.31358Z"
                  stroke="#9F9D9D"
                />
              </svg>
            </div>
          </div>
          <div
            class="basket-item__icon circle-btn-icon"
            @click="deleteItem(item)"
          >
            <div class="circle-btn-icon__icon">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M16.104 7.89017C16.104 7.89017 15.6515 13.5027 15.389 15.8668C15.264 16.996 14.5665 17.6577 13.424 17.6785C11.2499 17.7177 9.0732 17.7202 6.89986 17.6743C5.8007 17.6518 5.11486 16.9818 4.99236 15.8727C4.7282 13.4877 4.2782 7.89017 4.2782 7.89017"
                  stroke="#9F9D9D"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.2568 5.19975H3.12512"
                  stroke="#9F9D9D"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.5337 5.19974C13.8796 5.19974 13.3162 4.73724 13.1879 4.0964L12.9854 3.08307C12.8604 2.61557 12.4371 2.29224 11.9546 2.29224H8.42707C7.94457 2.29224 7.52123 2.61557 7.39623 3.08307L7.19373 4.0964C7.0654 4.73724 6.50207 5.19974 5.8479 5.19974"
                  stroke="#9F9D9D"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="basket-item__row">
          <div class="basket-item__photo">
            <router-link
              v-on:click.native="hidePopup"
              :to="'/product/' + item.slug"
              ><img :src="item.main_image" :alt="item.name" />
            </router-link>
          </div>
          <div class="basket-item__body">
            <div class="basket-item__cost-group" v-if="item.discount_price">
              <div class="product-cost">
                {{ dividingNumberDigits(item.discount_price) }} {{ getSymbol() }}
              </div>
              <div class="product-cost-old">
                {{ dividingNumberDigits(item.price) }} {{ getSymbol() }}
              </div>
            </div>

            <div class="basket-item__cost-group" v-if="!item.discount_price">
              <div class="product-cost product-cost-current">
                {{ dividingNumberDigits(item.price) }} {{ getSymbol() }}
              </div>
            </div>
            <div class="basket-item-prop">
              <div class="basket-item-prop__group">
                <div class="basket-item-prop__item d-flex align-items-center">
                  <div class="basket-item-prop__name">
                    {{ $t("header.basket.size") }}:
                  </div>
                  <div class="size-item" data-active>{{ item.size }}</div>
                </div>
                <div class="basket-item-prop__item d-flex align-items-center">
                  <div class="basket-item-prop__name">
                    {{ $t("header.basket.color") }}:
                  </div>
                  <div
                    class="color-item"
                    :class="{
                      'color-item-multicolor':
                        getColorById(item.color_id) == '#FFFFFE',
                    }"
                    :style="{ color: getColorById(item.color_id) }"
                    data-active
                  ></div>
                </div>
              </div>
              <div class="basket-item-prop__item">
                <div class="basket-item-prop__name">
                  {{ $t("header.basket.quantity") }}:
                </div>
                <ui-quantity
                  :quantity.sync="item.quantity"
                  :item.sync="item"
                  :min="1"
                  :max.sync="item.max_quantity"
                ></ui-quantity>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="cart.products.length > 0"
      class="header-popup-basket__price animate-delay"
    >
      {{ $t("header.basket.sum") }}:
      <span
        >{{ dividingNumberDigits(parseFloat(cart.total_price).toFixed(2)) }}
        <span class="product-cost__rub">{{ getSymbol() }}</span></span
      >
    </div>
    <div
      v-if="cart.products.length > 0"
      class="header-popup-basket__info animate-delay"
    >
      {{ $t("header.basket.withoutDel") }}
    </div>
    <div
      v-if="cart.products.length < 1"
      class="header-popup-basket__empty animate-delay"
      v-html="$t('basket.empty')"
    ></div>
    <router-link
      v-if="cart.products.length > 0"
      v-on:click.native="hidePopup"
      class="button w-100 animate-delay"
      to="/basket/"
      >{{ $t("header.basket.buttonText") }}
    </router-link>
  </div>
</template>

<script>
import Cart from "../../Stores/Cart.vue";
import FavoriteStore from "../../Stores/Favorite.vue";
import Colors from "../../Stores/Colors.vue";
import UiQuantity from "../../Components/UI/Quantity.vue";
import { dividingNumberDigits } from "../../Utilts/utilts";

export default {
  name: "Basket",
  components: {
    UiQuantity,
  },
  data() {
    return {
      currentCurrency: null,
    };
  },
  methods: {
    dividingNumberDigits,
    getCart() {
      this.$http
        .get("api/cart/", {
          params: {
            with_promocode: true,
          },
        })
        .then((response) => {
          setTimeout(() => {
            this.$ui.scroll.addScroll();
          }, 100);

          if (!response.body.cart_user_chosen) {
            this.$ui.popup.togglePopup("choose-cart");
          }

          Cart.commit("updateCart", response.body);
        });
    },
    getColorById(id) {
      let color = null;

      if (this.colors) {
        if (this.colors.colors) {
          for (let i = 0; i < this.colors.colors.length; i++) {
            if (id == this.colors.colors[i].id) {
              color = this.colors.colors[i].color_code;
            }
          }
        }
      }

      return color;
    },
    getColorNameById(id) {
      let color = null;

      for (let i = 0; i < this.colors.length; i++) {
        if (id == this.colors[i].id) {
          color = this.colors[i].name;
        }
      }

      return color;
    },
    hidePopup() {
      try {
        window.yaCounter44189699.reachGoal("perehod_v_korzinu");
      } catch (err) {
        console.error(err);
      }

      this.$ui.popup.closePopup();
    },
    deleteItem(item) {
      const ecommerceProduct = {
        id: item.color_image_id.toString(),
        name: item.name,
        price: item.discount_price
          ? item.discount_price.toString()
          : item.price.toString(),
        brand: "Clanvi",
        category: item.category_name,
        quantity: item.quantity,
        variant: `color:${item.color_id}/size:${item.size}`,
      };

      window.dataLayer.push({
        ecommerce: {
          currencyCode: this.currentCurrency,
          remove: {
            products: [ecommerceProduct],
          },
        },
        event: "pixel-mg-event", // Обязательно
        "pixel-mg-event-category": "Enhanced Ecommerce", // Обязательно
        "pixel-mg-event-action": "Removing a Product from a Shopping Cart", // Обязательно
        "pixel-mg-event-non-interaction": "False",
      });
      this.$http
        .delete("api/cart/remove/", {
          body: {
            color_id: parseInt(item.color_id),
            size: item.size,
            product_id: parseInt(item.product_id),
          },
          params: {
            with_promocode: true,
          },
        })
        .then((response) => {
          Cart.commit("updateCart", response.body);
        });
      if (window.VK)
        window.VK.Retargeting.ProductEvent(
          VK_PIXEL_PRICE_LIST_ID,
          "remove_from_cart",
          { products: { id: `${item.product_id}` } }
        );
    },
    toggleFavorite(item) {
      if (item.favorites) {
        this.removeFavorite(item);
        if (window.VK)
          window.VK.Retargeting.ProductEvent(
            VK_PIXEL_PRICE_LIST_ID,
            "remove_from_wishlist",
            { products: { id: `${item}` } }
          );
      } else {
        this.addFavorite(item);
        if (window.VK) {
          window.VK.Retargeting.ProductEvent(
            VK_PIXEL_PRICE_LIST_ID,
            "add_to_wishlist",
            { products: { id: `${item}` } }
          );
          let _tmr = window._tmr || (window._tmr = []);
          _tmr.push({
            type: "reachGoal",
            id: VK_PIXEL_ID,
            value: item.price,
            goal: "addToWishlist",
            params: { product_id: `${item.product_id}` },
          });
        }
      }
    },
    addFavorite(item) {
      item.favorites = !item.favorites;

      const data = {
        product_id: parseInt(item.product_id),
        color_id: item.color_id,
        size: item.size,
      };

      this.$http.post("api/favorites/add/", data).then((response) => {
        FavoriteStore.commit("updateFavorite", response.body.products);
      });
    },
    removeFavorite(item) {
      item.favorites = !item.favorites;

      const data = {
        product_id: parseInt(item.product_id),
        color_id: item.color_id,
        size: item.size,
      };

      this.$http
        .delete("api/favorites/remove/", {
          body: data,
        })
        .then((response) => {
          FavoriteStore.commit("updateFavorite", response.body.products);
        });
    },
    getSymbol() {
      let symbol = null;

      switch (this.currentCurrency) {
        case "RUB":
          symbol = "₽";
          break;

        case "USD":
          symbol = "$";
          break;

        case "EUR":
          symbol = "€";
          break;
      }

      return symbol;
    },
  },
  computed: {
    cart() {
      return Cart.state.cart;
    },
    colors() {
      return Colors.state.colors;
    },
  },
  created() {
    this.currentCurrency = this.$ls.get("currency") || "RUB";

    this.getCart();
  },
};
</script>
<style lang="scss" scoped>
.header-popup-basket__wrap .basket-item:first-child {
  .basket-item__header {
    @media (max-width: 665.98px) {
      margin-top: 6px !important;
    }
  }
}

.basket-item__name {
  @media (max-width: 665.98px) {
    margin-right: 10px;
  }
}

.header-popup-basket {
  @media (max-width: 665.98px) {
    max-width: 290px;
  }
}

.header-popup-basket__price {
  @media (max-width: 665.98px) {
    font-size: 16px;
  }
}
</style>
