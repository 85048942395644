import axios from "axios";

const baseUrl = () => {
  return API_URL;
};

export const Axios = axios.create({
  baseURL: baseUrl()
});

Axios.interceptors.response.use(undefined, (err) => {
  const {
    config,
    message,
  } = err;

  if (!config || !config.retry) {
    return Promise.reject(err);
  }

  if (!message.includes("Network Error")) {
    return Promise.reject(err);
  }

  config.retry -= 1;
  const delayRetryRequest = new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, config.retryDelay || 1000);
  });
  return delayRetryRequest.then(() => axios(config));
});