<template>
    <div class="popup popup-wholesale-mail">
        <div class="popup__close" data-close_popup></div>
        <div class="popup__wrap animate-delay" data-custom_scroll>
            <div class="popup_login--title mb-12">
                Оставить заявку
            </div>
            <form 
            class="form"
            @submit.prevent="sendMail"
            data-vv-scope="sendMail"
                >
                <input
                  class="input-text mb-12"
                  type="text"
                  v-model="fio"
                  v-validate="'required'"
                  data-vv-name="fio"
                  placeholder="ФИО"
                />

                <input
                  class="input-text mb-12"
                  type="text"
                  v-model="phone"
                  v-validate="'required'"
                  v-mask="phoneMask"
                  @blur="checkValidPhone()"
                  @input="phonePrint($event.target.value)"
                  data-vv-name="phone"
                  :data-vv-as="$t('checkout.registration.placeholder.number')"
                  :class="{ 'input-text_error': !validPhone }"
                  :placeholder="$t('checkout.registration.placeholder.number')"
                /> 
                
                <input
                  class="input-text w-100"
                  :class="{ 'input-text_error': errors.has('authorize.email') }"
                  :data-vv-as="$t('popup.placeholder.email')"
                  v-model="email"
                  name="email"
                  v-validate="{ required: true, email: true }"
                  data-vv-name="email"
                  type="email"
                  :placeholder="$t('popup.placeholder.email')"
                />
                <div class="input__error" v-show="errors.has('authorize.email')">
                  {{ errors.first("authorize.email") }}
                </div>

                <input
                  class="input-text mb-12"
                  type="text"
                  v-model="city"
                  v-validate="'required'"
                  data-vv-name="city"
                  placeholder="Город"
                />

                <div class="text mb-12">Юридическое лицо</div>

                <div class="popup-wholesale-switcher mb-12">
                  <p
                    class="popup-wholesale-switcher-label"
                    :class="{ disabled: type }"
                  >
                    ИП
                  </p>
                  <WideCheckbox
                    v-model="type"
                  />
                  <p
                    class="popup-wholesale-switcher-label"
                    :class="{ disabled: !type }"
                  >
                    "ООО"
                  </p>
                </div>
                
                <div class="text mb-12">Есть ли у вас магазин?</div>

                <div class="popup-wholesale-switcher mb-12">
                  <p
                    class="popup-wholesale-switcher-label"
                    :class="{ disabled: shop }"
                  >
                    Да
                  </p>
                  <WideCheckbox
                    v-model="shop"
                  />
                  <p
                    class="popup-wholesale-switcher-label"
                    :class="{ disabled: !shop }"
                  >
                    Нет
                  </p>
                </div>

                <button class="button w-100">Отправить</button>
            </form>
        </div>
    </div>
</template>


<script>
import WideCheckbox from "../UI/WideCheckbox.vue";
import { Axios } from "../../Plugins/axios.js";

export default {
  components: {
    WideCheckbox
  },
  data: () => ({
    fio: null,
    phone: null,
    email: null,
    city: null,
    type: false,
    company_type: null,
    shop: false,
    validPhone: true,
    phoneMask: "+#(###)-###-##-##",
    maskType: "ru",
  }),

  methods: {
    checkValidPhone() {
      if (this.phone.length !== 17) this.validPhone = false;
    },
    phonePrint(newValue) {
      if (this.phone.length === 17) this.validPhone = true;
      if (newValue === "+") {
        this.phone = "+";
        this.phoneMask = "+###-##-###-##-##";
      } else if (newValue.substr(0, 1) === "3") {
        this.maskType = "int";
        this.phoneMask = "+###-##-###-##-##";
      } else if (newValue.substr(0, 2) === "+3") {
        this.maskType = "int";
        this.phoneMask = "+###-##-###-##-##";
      } else if (
        newValue.substr(0, 1) === "7" ||
        newValue.substr(0, 1) === "8"
      ) {
        this.phone = "+7";
        this.phoneMask = "+7(###)-###-##-##";
        this.maskType = "ru";
      } else if (newValue.substr(0, 2) === "+8") {
        this.phone = "+7";
        this.phoneMask = "+7(###)-###-##-##";
        this.maskType = "ru";
      } else {
        this.phoneMask = "+7(###)-###-##-##";
        this.maskType = "ru";
      }
    },
    sendMail() {
      if (this.type) {
        this.company_type = "ooo"
      } else {
        this.company_type = "ip"
      }
        const data = {
          fio: this.fio,
          city: this.city,
          phone: this.phone,
          email: this.email,
          company_type: this.company_type,
          market: !this.shop
        };

        this.$http.post('api/send_wholesale_mail_request/', data)
        .then(
        response => {
          this.$ui.popup.togglePopup('wholesale-mail-success');
        },
        response => {
          // const field = this.$validator.fields.find({
          //   name: "email"
          // });

          // if (!field) {
          //   return;
          // }

          // this.$validator.errors.add({
          //   id: field.id,
          //   field: "authorize.email",
          //   msg: "Введите корректный адрес электронной почты",
          //   scope: this.$options.scope
          // });

          // field.setFlags({
          //   invalid: true,
          //   valid: false, 
          //   validated: true
          // });
        }
        );
    }
  }
};
</script>

<style lang="scss" scoped>

.popup-wholesale-switcher {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  grid-gap: 16px;
  align-items: center;
}

.popup-wholesale-switcher-label {
  margin: 0;
  font-family: CoFoSans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;

  color: #333333;
  transition: 0.2s;
  &.disabled {
    color: #bdbdbd;
  }
}
</style>
