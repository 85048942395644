<template>
  <section class="promo">
    <div class="promo-menu">
      <div v-for="(item, index) in banners.serializer_background_menu" v-bind:key="index" class="promo-menu__item">
        <a :href="item.link">{{ item.header }}</a>
      </div>
    </div>
    <div class="promo-slider">
      <div class="promo-slider__wrap">
        <div v-for="(item, index) in banners.serializer_background" class="promo-slider__item" v-bind:key="index"
          :data-active="index == 0">
          <div class="promo-slider__item-bg promo-slider__item-bg_big"
            :style="'background-image: url(' + item.main_image + ')'"></div>
          <div class="promo-slider__item-bg promo-slider__item-bg_small"
            :style="'background-image: url(' + item.main_image_mobile + ')'"></div>
          <a v-if="item.link" class="promo-slider__link" :href="item.link.replace('https://clanvi.com', '')"></a>
        </div>
      </div>
      <div class="dots-group"></div>
    </div>

    <InstaStories></InstaStories>


    <div class="banner-narrow">
      <div class="banner-narrow__text onphone-hide" v-html="banners.serializer_tight[0].header"></div>
      <div class="banner-narrow__text onphone-show" v-html="banners.serializer_tight[0].header"></div>
      <a class="banner-narrow__link" :href="banners.serializer_tight[0].link"></a>
      <!-- <div class="links">
        <a href="">
          <img src="/images/diamond.svg" alt="">
          Эксклюзивный дизайн изделий
        </a>
        <a href="/delivery">
          <img src="/images/delivery.svg" alt="">
          Бесплатная доставка от 6 000 р.
        </a>
        <a href="">
          <img src="/images/bonus.svg" alt="">
          Накопительная система бонусов
        </a>
        <a href="/packing">
          <img src="/images/package.svg" alt="">
          Подарочная упаковка
        </a>
      </div> -->
    </div>

    <div class="content-layout">
      <section class="promo-grid">
        <div class="promo-grid__item">
          <div class="promo-item">
            <div class="promo-item__bg promo-item__bg_big"
              :style="'background-image: url(' + banners.serializer_simple[0].main_image + ')'"></div>
            <div class="promo-item__bg promo-item__bg_small"
              :style="'background-image: url(' + banners.serializer_simple[0].main_image_mobile + ')'"></div>
            <div class="promo-item__wrap" :style="{color: banners.serializer_simple[0].header_color}">
              <div class="promo-item__title">{{ banners.serializer_simple[0].header }}</div>
              <div class="promo-item__text" v-html="banners.serializer_simple[0].description"></div>
            </div>
            <a v-if="banners.serializer_simple[0].link" class="promo-item__link"
              :href="banners.serializer_simple[0].link.replace('https://clanvi.com', '')"></a>
          </div>
          <div class="promo-item">
            <div class="promo-item__bg promo-item__bg_big"
              :style="'background-image: url(' + banners.serializer_simple[1].main_image + ')'"></div>
            <div class="promo-item__bg promo-item__bg_small"
              :style="'background-image: url(' + banners.serializer_simple[1].main_image_mobile + ')'"></div>
            <div class="promo-item__wrap" :style="{color: banners.serializer_simple[1].header_color}">
              <div class="promo-item__title">{{ banners.serializer_simple[1].header }}</div>
              <div class="promo-item__text" v-html="banners.serializer_simple[1].description"></div>
            </div>
            <a v-if="banners.serializer_simple[1].link" class="promo-item__link"
              :href="banners.serializer_simple[1].link.replace('https://clanvi.com', '')"></a>
          </div>
        </div>
        <div class="promo-grid__item promo-grid__item_big">
          <div class="promo-item promo-item_hover">
            <div class="promo-item__bg promo-item__bg_big"
              :style="'background-image: url(' + banners.serializer_big[0].main_image + ')'"></div>
            <div class="promo-item__bg promo-item__bg_small"
              :style="'background-image: url(' + banners.serializer_big[0].main_image_mobile + ')'"></div>
            <div class="promo-item__wrap" :style="{color:  banners.serializer_big[0].header_color}">
              <div class="promo-item__title">{{ banners.serializer_big[0].header }}</div>
              <div class="promo-item__text" v-html="banners.serializer_big[0].description"></div>
            </div>
            <a v-if="banners.serializer_big[0].link" class="promo-item__link"
              :href="banners.serializer_big[0].link.replace('https://clanvi.com', '')"></a>
          </div>
        </div>
      </section>
    </div>

    <section class="promo-video">
      <div class="promo-video__bg">
        <video :src="banners.serializer_video[0].video" playsinline autoplay loop muted></video>
      </div>
      <div class="promo-video__wrap">
        <div class="promo-video__icon far fa-play"></div>
        <!-- <div class="title-line"><span>{{ banners.serializer_video[0].header }}</span></div> -->
        <div class="promo-video__text">{{ banners.serializer_video[0].description }}</div>
      </div>
      <a v-if="banners.serializer_video[0].link" class="promo-video__link"
        :href="banners.serializer_video[0].link.replace('https://clanvi.com', '')"></a>
    </section>

    <section class="photo-collection">
      <div class="content-layout">
        <div class="title-line"><span>{{ $t('main.collectionTitle') }}</span></div>
        <div class="photo-collection__text">{{ $t('main.collectionText') }}</div>
        <div class="photo-collection__row">
          <div class="photo-collection__col" v-for="(item, index) in categories.products.slice(0, 4)" v-bind:key="index">
            <div class="photo-collection-item" :style="'background-image: url(' + item.main_image + ')'">
              <router-link class="photo-collection-item__link" :to="'/product/' + item.slug"></router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="photo-insta" v-if="visible.reviews">
      <div class="photo-insta__head">
        <div class="content-layout">
          <div class="title-line"><span>{{ $t('main.instaTitle') }}</span></div>
          <div class="photo-insta__text">{{ $t('main.instaText') }}</div>
        </div>
      </div>
      <div class="photo-insta__body">
        <div class="content-layout">
          <div class="photo-insta__row">
            <div class="photo-insta__col" v-for="(item, index) in reviews.slice(0, 12)" v-bind:key="index">
              <div class="photo-insta-item" :style="'background-image: url(' + item.image + ')'">
                <div class="photo-insta-item__text">{{ item.text.split(' ').slice(0, 20).join(" ") }}...</div>
                <a v-if="item.review_link" class="photo-insta-item__link"
                  :href="item.review_link.replace('https://clanvi.com', '')"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
// import {clanvi} from '../../../js/action.js';
import InstaStories from '../../Components/Stories/Insta.vue';

export default {
  name: 'Main',
  components: {
    InstaStories
  },
  data() {
    return {
      date: '15 сент',
      categories: {
        products: []
      },
      reviews: [],
      banners: {
        serializer_background: [{}],
        serializer_background_menu: [{}],
        serializer_big: [{}],
        serializer_video: [{}],
        serializer_simple: [{}, {}],
        serializer_tight: [{}]
      },
      visible: {
        banners: false,
        categories: false,
        reviews: false
      }
    };
  },
  methods: {
    getCategories() {
      this.$http.get('api/categories/main/').then(response => {
        this.categories = response.body;

        setTimeout(() => {
          this.visible.categories = true;
        }, 100);
      });
    },
    getBanners() {
      this.$http.get('api/new_banners/main/').then(response => {
        this.banners = response.body;
        setTimeout(() => {
          this.$ui.promoSlider.init();
          this.visible.banners = true;
        }, 100);
        if (window.VK)
          window.VK.Retargeting.ProductEvent(VK_PIXEL_PRICE_LIST_ID, "view_home")
      });
    },
    getReviews() {
      this.$http.get('api/reviews/').then(response => {
        this.reviews = response.body;

        setTimeout(() => {
          this.visible.reviews = true;
        }, 100);
      });
    },
    replaceUrl(newUrl) {
      if (SERVER_MODE === "prod") {
        return newUrl
      }
      newUrl.replace(SITE_URL, '')
      console.log(newUrl)
      return newUrl
    }

  },

  created() {
    this.$title = typeof this.$t === "function" ? this.$t('main.title') : "Интернет-магазин женской одежды";
    this.getCategories();
    this.getBanners();
    this.getReviews();
  },
  mounted() {
    if (this.$route.query.registered) {
      this.$http.post('api/auth/token-verify/', {
        token: this.$ls.get('access')
      }).then(response => {
        if (response.body.wholesale_access === false && response.body.person_type === 'WB') {

        } else {
          this.$ui.popup.togglePopup('registered');
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.promo {
  .banner-narrow {
    .links {
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      flex-wrap: wrap;

      a {
        display: flex;
        width: 100%;
        max-width: 250px;
        font-family: 'CoFoHolz';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #000000;

        img {
          margin-right: 12px;
        }

        @media (max-width: 1114px) {
          margin-bottom: 20px;
        }
      }
    }
  }
}

.promo-item__title{
  @media (max-width: 666px) {
    color: #000!important;
  }
}

.promo-video__icon::before{
  @media (max-width: 666px) {
    display: none;
  }
}
</style>