<template>
  <div>
    <a class="popup__bg" href="#" data-close_popup></a>

    <div
      class="popup-menu"
      :class="{ 'popup-menu_404': $route.name === 'Error404' }"
    >
      <div class="popup-menu-404" v-if="$route.name === 'Error404'">
        <div class="popup-menu-404__title h1">{{ $t("page404.title") }}</div>
        <router-link class="popup-menu-404__link" to="/">{{
          $t("page404.links.main")
        }}</router-link>
        <router-link class="popup-menu-404__link" to="/catalog/">{{
          $t("page404.links.catalog")
        }}</router-link>
      </div>
      <div class="popup-menu__header">
        <a
          v-if="!isUser"
          class="header-link link-with-icon"
          href="#"
          data-open_popup="login"
        >
          <span class="link-with-icon__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M6.83374 7.55193C6.83374 10.3652 9.14749 12.6381 11.9901 12.6381C14.8327 12.6381 17.1464 10.3558 17.1464 7.55193C17.1464 4.74801 14.8421 2.46576 11.9995 2.46576C9.15693 2.46576 6.84318 4.74801 6.84318 7.55193"
                stroke="black"
                stroke-miterlimit="10"
              />
              <path
                d="M20.5278 21.5342C20.5278 16.8859 16.7125 13.1225 12 13.1225C7.28749 13.1225 3.47217 16.8859 3.47217 21.5342"
                stroke="black"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
            </svg>
          </span>
          {{ $t("popup.common.personal") }}
        </a>
        <router-link
          v-on:click.native="hideMenu"
          v-if="isUser"
          class="header-link link-with-icon"
          to="/cabinet"
        >
          <span class="link-with-icon__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M6.83374 7.55193C6.83374 10.3652 9.14749 12.6381 11.9901 12.6381C14.8327 12.6381 17.1464 10.3558 17.1464 7.55193C17.1464 4.74801 14.8421 2.46576 11.9995 2.46576C9.15693 2.46576 6.84318 4.74801 6.84318 7.55193"
                stroke="black"
                stroke-miterlimit="10"
              />
              <path
                d="M20.5278 21.5342C20.5278 16.8859 16.7125 13.1225 12 13.1225C7.28749 13.1225 3.47217 16.8859 3.47217 21.5342"
                stroke="black"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
            </svg>
          </span>
          {{ $t("header.links.personal") }}
        </router-link>
        <div class="search-input">
          <form @submit.prevent="search">
            <input
              v-model="query"
              class="input-text"
              type="text"
              :placeholder="$t('popup.placeholder.search')"
            />
            <div
              @click="search"
              class="link-with-icon__icon link-with-icon__icon_1"
            >
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                <circle cx="7.5" cy="7.5" r="5.5" stroke="black" />
                <path
                  d="M11.5 11.5L14 14"
                  stroke="black"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </form>
          <div class="form-separator"></div>
        </div>
        <div class="switcher ">
          <lang-switcher :current.sync="currentLang"></lang-switcher>
          <!-- TODO: CLA-352 -->
          <!--<currency-switcher
            :current.sync="currentCurrency"
          ></currency-switcher> -->
        </div>
      </div>
      <div class="popup-menu__wrap" data-custom_scroll>
        <section class="catalog-parts">
          <div class="content-layout">
            <div class="animate-delay">
              <div class="popup-menu__banner" v-if="banner">
                <div class="popup-menu__banner-text">НОВАЯ КОЛЛЕКЦИЯ</div>
                <a class="popup-menu__banner-link" :href="banner.link"></a>
              </div>
              <div class="popup-menu__row">
                <div class="popup-menu__col">
                  <div
                    :class="{ 'popup-menu__item': cat.show_in_main_menu }"
                    v-for="(cat, index) in categories"
                    v-bind:key="index"
                  >
                    <template v-if="cat.show_in_main_menu">
                      <!--                      <div v-if="index === 1" class="popup-menu__item">-->
                      <!--                        <router-link-->
                      <!--                          v-on:click.native="hideMenu"-->
                      <!--                          to="/catalog/obuv"-->
                      <!--                          v-if="currentLang === 'ru'"-->
                      <!--                          >Обувь</router-link-->
                      <!--                        >-->
                      <!--                        <router-link-->
                      <!--                          v-on:click.native="hideMenu"-->
                      <!--                          to="/catalog/shoes"-->
                      <!--                          v-if="currentLang === 'en'"-->
                      <!--                          >Shoes</router-link-->
                      <!--                        >-->
                      <!--                      </div>-->
                      <router-link
                        v-on:click.native="hideMenu"
                        :to="'/catalog/' + cat.slug"
                      >
                        <span
                          class="glif"
                          v-if="
                            cat.name === 'Все товары' ||
                            cat.name === 'All products'
                          "
                          > </span
                        >{{ cat.name }}</router-link
                      >
                    </template>
                  </div>
                </div>
                <div class="popup-menu__col">
                  <div
                    class="popup-menu__item"
                    v-for="item in siteMenu"
                    :key="item.index"
                    v-show="showSiteMenu(item, 3)"
                    v-on:click="hideMenu"
                  >
                    <a :href="item.link.replace('https://clanvi.com', '')">
                      {{ item.title }}
                    </a>
                  </div>
                </div>
                <div class="popup-menu__col">
                  <div
                    class="popup-menu__item"
                    v-for="item in siteMenu"
                    :key="item.index"
                    v-show="showSiteMenu(item, 4)"
                    v-on:click="hideMenu"
                  >
                    <a :href="item.link.replace('https://clanvi.com', '')">
                      {{ item.title }}
                    </a>
                  </div>
                </div>
                <div class="popup-menu__col">
                  <div
                    class="popup-menu__item"
                    v-for="item in siteMenu"
                    :key="item.index"
                    v-show="showSiteMenu(item, 5)"
                    v-on:click="hideMenu"
                  >
                    <a :href="item.link.replace('https://clanvi.com', '')">
                      {{ item.title }}
                    </a>
                  </div>
                </div>
              </div>
              <!-- <div class="popup-menu__auth-wrap">
                <div class="popup-menu__auth-item">
                  <a href="#" data-open_popup="login">{{
                    $t("popup.common.entry")
                  }}</a>
                </div>
                <div class="popup-menu__auth-item">
                  <a href="#" data-open_popup="registration">{{
                    $t("popup.common.registry")
                  }}</a>
                </div>
              </div> -->
              <div class="social-links">
                <div class="left">
                  <div class="social-links__text">
                    Подпишитесь, чтобы оставаться на связи
                  </div>
                  <div class="social-links-item-wrap">
                    <div class="social-links__item">
                      <div class="social-links__icon fab fa-vk"></div>
                      <a
                        target="_blank"
                        href="https://vk.com/clanvi"
                        class="social-links__link"
                      ></a>
                    </div>
                    <div class="social-links__item">
                      <div class="social-links__icon fab fa-telegram-plane"></div>
                      <a
                        target="_blank"
                        href="https://t.me/clanvi"
                        class="social-links__link"
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="phone">
                  <a href="tel:88007006506">8 800 700 65 06</a>
                </div>
              </div>
              <div class="popup-menu__mobile">
                <div
                  class="popup-menu__item"
                  v-for="item in siteMenu"
                  :key="item.index"
                  v-on:click="hideMenu"
                >
                  <a :href="item.link.replace('https://clanvi.com', '')">
                    <!-- <span
                      class="link-with-icon__icon-custom"
                      v-if="item.menu_image"
                    >
                      <img :src="item.menu_image" alt="" />
                    </span> -->
                    {{ item.title }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <PopupLogin />
    <GetCode />
    <PopupWholesale />

    <div class="popup popup-registration">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap animate-delay" data-custom_scroll>
        <div class="popup__title">{{ $t("popup.common.registry") }}</div>
        <form
          class="form"
          @submit.prevent="registerRB"
          data-vv-scope="registerRB"
        >
          <input
            class="input-text"
            v-model="register.phone"
            :class="{ 'input-text_error': errors.has('registerRB.phone') }"
            v-validate="{ required: true }"
            :data-vv-as="$t('popup.placeholder.number')"
            data-vv-name="phone"
            ref="phone"
            type="tel"
            :placeholder="$t('popup.placeholder.number')"
          />
          <div class="input__error" v-show="errors.has('registerRB.phone')">
            {{ errors.first("registerRB.phone") }}
          </div>

          <input
            class="input-text"
            v-model="register.email"
            :class="{ 'input-text_error': errors.has('registerRB.email') }"
            v-validate="{ required: true, email: true }"
            :data-vv-as="$t('popup.placeholder.email')"
            data-vv-name="email"
            ref="email"
            type="email"
            :placeholder="$t('popup.placeholder.email')"
          />
          <div class="input__error" v-show="errors.has('registerRB.email')">
            {{ errors.first("registerRB.email") }}
          </div>

          <input
            type="password"
            class="input-text"
            :class="{
              'input-text_error': errors.has('registerRB.register.password'),
            }"
            v-validate="'required|min:8'"
            v-model="register.password"
            ref="register.password"
            data-vv-name="register.password"
            :data-vv-as="$t('popup.placeholder.password')"
            :placeholder="$t('popup.placeholder.password')"
          />

          <div
            class="input__error"
            v-show="errors.has('registerRB.register.password')"
          >
            {{ errors.first("registerRB.register.password") }}
          </div>

          <input
            class="input-text"
            v-validate="'required|confirmed:register.password'"
            :class="{
              'input-text_error': errors.has(
                'registerRB.register.password_repeat'
              ),
            }"
            data-vv-name="register.password_repeat"
            type="password"
            :data-vv-as="$t('popup.placeholder.passwordAgain')"
            :placeholder="$t('popup.placeholder.passwordAgain')"
            v-model="register.password_repeat"
          />

          <div
            class="input__error"
            v-show="errors.has('registerRB.register.password_repeat')"
          >
            {{ errors.first("registerRB.register.password_repeat") }}
          </div>

          <label class="input-checkbox">
            <input type="checkbox" v-model="register.agreement" />
            <div class="input-checkbox__checkbox fas fa-check"></div>
            <div
              class="input-checkbox__text"
              v-html="$t('checkout.delivery.check.agree')"
            ></div>
          </label>

          <button type="submit" :disabled="!register.agreement" class="button">
            {{ $t("popup.registration.registry") }}
          </button>
        </form>
      </div>
    </div>

    <div class="popup popup-registration-opt">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap animate-delay" data-custom_scroll>
        <div class="popup__title">{{ $t("popup.common.registry") }}</div>
        <form
          class="form"
          @submit.prevent="registerOpt"
          data-vv-scope="registerOpt"
        >
          <input
            class="input-text"
            v-model="register_opt.email"
            :class="{ 'input-text_error': errors.has('registerOpt.email') }"
            v-validate="{ required: true, email: true }"
            :data-vv-as="$t('popup.placeholder.email')"
            data-vv-name="email"
            type="email"
            :placeholder="$t('popup.placeholder.email')"
          />
          <div class="input__error" v-show="errors.has('registerOpt.email')">
            {{ errors.first("registerOpt.email") }}
          </div>

          <input
            class="input-text"
            type="tel"
            data-vv-name="phone"
            v-model="register_opt.phone"
            :placeholder="$t('popup.placeholder.number')"
            :class="{ 'input-text_error': errors.has('registerOpt.phone') }"
            v-validate="{ required: true }"
            :data-vv-as="$t('popup.placeholder.number')"
          />
          <div class="input__error" v-show="errors.has('registerOpt.phone')">
            {{ errors.first("registerOpt.phone") }}
          </div>

          <input
            class="input-text"
            type="text"
            v-model="register_opt.city"
            v-validate="{ required: true }"
            :data-vv-as="$t('popup.placeholder.city')"
            data-vv-name="city"
            :placeholder="$t('popup.placeholder.city')"
          />

          <div class="input__error" v-show="errors.has('registerOpt.city')">
            {{ errors.first("registerOpt.city") }}
          </div>

          <div class="popup-registration-opt__shops">
            <div class="popup-registration-opt__shops-text">
              {{ $t("popup.registration.opt.shop") }}
            </div>
            <label class="input-radio">
              <input
                type="radio"
                name="shop"
                v-model="register_opt.market"
                v-validate="{ required: true }"
                data-vv-name="market"
                :data-vv-as="$t('popup.registration.opt.shop')"
                :value="true"
                data-slide_down=".popup-registration-opt__link-shop"
              />
              <div class="input-radio__radio"></div>
              <div class="input-radio__text">
                {{ $t("popup.registration.opt.have") }}
              </div>
            </label>
            <label class="input-radio">
              <input
                type="radio"
                name="shop"
                v-model="register_opt.market"
                data-vv-name="market"
                :data-vv-as="$t('popup.registration.opt.shop')"
                v-validate="{ required: true }"
                :value="false"
                checked
                data-slide_up=".popup-registration-opt__link-shop"
              />
              <div class="input-radio__radio"></div>
              <div class="input-radio__text">
                {{ $t("popup.registration.opt.no") }}
              </div>
            </label>

            <div class="input__error" v-show="errors.has('registerOpt.market')">
              {{ errors.first("registerOpt.market") }}
            </div>
          </div>
          <div class="form__hide popup-registration-opt__link-shop">
            <input
              v-model="register_opt.market_url"
              class="input-text"
              type="text"
              :placeholder="$t('popup.placeholder.linkShop')"
            />
          </div>
          <input
            class="input-text"
            v-model="register_opt.fio"
            type="text"
            data-vv-name="fio"
            :data-vv-as="$t('popup.placeholder.fullName')"
            v-validate="{ required: true }"
            :placeholder="$t('popup.placeholder.fullName')"
          />

          <div class="input__error" v-show="errors.has('registerOpt.fio')">
            {{ errors.first("registerOpt.fio") }}
          </div>

          <input
            class="input-text"
            v-validate="'required|min:8'"
            :class="{ 'input-text_error': errors.has('registerOpt.password') }"
            v-model="register_opt.password"
            name="password"
            :data-vv-as="$t('popup.placeholder.password')"
            ref="password"
            type="password"
            :placeholder="$t('popup.placeholder.password')"
          />

          <div class="input__error" v-show="errors.has('registerOpt.password')">
            {{ errors.first("registerOpt.password") }}
          </div>

          <input
            class="input-text"
            v-model="register_opt.password_repeat"
            v-validate="'required|confirmed:password'"
            :class="{
              'input-text_error': errors.has('registerOpt.password_repeat'),
            }"
            data-vv-as="пароль"
            name="password_repeat"
            type="password"
            :placeholder="$t('popup.placeholder.passwordAgain')"
          />

          <div
            class="input__error"
            v-show="errors.has('registerOpt.password_repeat')"
          >
            {{ errors.first("registerOpt.password_repeat") }}
          </div>

          <label class="input-checkbox">
            <input
              type="checkbox"
              data-slide_toggle=".popup-registration-opt__requisites"
            />
            <div class="input-checkbox__checkbox fas fa-check"></div>
            <div class="input-checkbox__text">
              {{ $t("popup.registration.opt.fillDetails") }}
            </div>
          </label>
          <div class="form__hide popup-registration-opt__requisites">
            <label class="input-radio">
              <input
                type="radio"
                name="entity_type"
                v-model="register_opt.company_type"
                value="OOO"
                checked
                data-slide_down=".popup-registration-opt__requisites-kpp"
              />
              <div class="input-radio__radio"></div>
              <div class="input-radio__text">
                {{ $t("popup.registration.opt.stockCompany") }}
              </div>
            </label>
            <label class="input-radio">
              <input
                type="radio"
                name="entity_type"
                v-model="register_opt.company_type"
                value="IP"
                data-slide_up=".popup-registration-opt__requisites-kpp"
              />
              <div class="input-radio__radio"></div>
              <div class="input-radio__text">
                {{ $t("popup.registration.opt.enterp") }}
              </div>
            </label>
            <input
              class="input-text"
              v-model="register_opt.inn"
              type="text"
              :placeholder="$t('popup.placeholder.inn')"
            />
            <div class="form__hide popup-registration-opt__requisites-kpp">
              <input
                class="input-text"
                type="text"
                v-model="register_opt.kpp"
                :placeholder="$t('popup.placeholder.kpp')"
              />
            </div>
            <input
              class="input-text"
              type="text"
              v-model="register_opt.okpo"
              :placeholder="$t('popup.placeholder.okpo')"
            />
            <input
              class="input-text"
              type="text"
              v-model="register_opt.company_name"
              :placeholder="$t('popup.placeholder.shortLegal')"
            />
          </div>

          <label class="input-checkbox">
            <input type="checkbox" v-model="register_opt.agreement" />
            <div class="input-checkbox__checkbox fas fa-check"></div>
            <div
              class="input-checkbox__text"
              v-html="$t('checkout.delivery.check.agree')"
            ></div>
          </label>

          <button class="button" :disabled="!register_opt.agreement">
            {{ $t("popup.registration.registry") }}
          </button>
        </form>
      </div>
    </div>

    <div class="popup popup-recovery">
      <!-- <div class="popup__close" data-close_popup></div> -->
      <div class="popup__wrap animate-delay" data-custom_scroll>
        <div class="popup__return">
          <a class="popup__return-btn" data-open_popup="login"
            ><img src="/images/arrow_left.svg"
          /></a>
          <div class="popup__title">
            {{ $t("popup.recovery.recoveryPass") }}
          </div>
        </div>
        <form
          class="form"
          @submit.prevent="recoveryAccount"
          data-vv-scope="recoveryAccount"
        >
          <input
            class="input-text"
            v-model="recovery.email"
            :class="{ 'input-text_error': errors.has('recoveryAccount.email') }"
            v-validate="{ required: true, email: true }"
            :data-vv-as="$t('popup.placeholder.email')"
            data-vv-name="email"
            type="email"
            :placeholder="$t('popup.placeholder.email')"
          />
          <div
            class="input__error"
            v-show="errors.has('recoveryAccount.email')"
          >
            {{ errors.first("recoveryAccount.email") }}
          </div>

          <button type="submit" class="button">
            {{ $t("popup.recovery.button") }}
          </button>
        </form>
      </div>
    </div>

    <div class="popup popup-discount">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup__title">{{ $t("popup.discount.purchase") }}</div>
        <div class="popup__text">{{ $t("popup.discount.enterMail") }}</div>
        <form class="form" action="#" method="post">
          <input
            class="input-text"
            type="email"
            :placeholder="$t('popup.placeholder.yourEmail')"
            required
          />
          <button class="button">{{ $t("popup.discount.button") }}</button>
        </form>
      </div>
    </div>

    <div class="popup popup-discount-img">
      <div class="popup__close popup__close-discount" data-close_popup>
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27.0015 27L17.9634 18.0007L27.0015 9" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M9 27L18.0381 18.0007L9 9" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <!-- <div
        class="popup-discount-img__img"
        style="background-image: url(/images/popup_discount_img.jpg)"
      ></div> -->
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup__title">{{ $t("popup.discount.purchase") }}</div>
        <div class="popup__text">
          {{ $t("popup.discount.enterMail", { discount: promocode_discount }) }}
        </div>
        <form
          class="form"
          data-vv-scope="sendEmail"
          @submit.prevent="sendEmail"
        >
          <input
            class="input-text"
            type="text"
            v-model="popup.email"
            v-validate="{ required: true }"
            :data-vv-as="$t('popup.placeholder.yourEmail')"
            data-vv-name="email"
            :class="{ 'input-text_error': errors.has('sendEmail.email') }"
            placeholder=""
            required
          />
          <div class="input__error" v-show="errors.has('sendEmail.email')">
            {{ errors.first("sendEmail.email") }}
          </div>
          <button type="submit" class="button" :disabled="!popup.agree">
            {{ $t("popup.discount.button") }}
          </button>
          <label class="input-checkbox popup-checkbox">
              <input type="checkbox" v-model="popup.agree" />
              <div class="input-checkbox__checkbox fas fa-check"></div>
              <div
                class="input-checkbox__text"
                v-html="$t('checkout.delivery.check.agree_policy')"
              ></div>
          </label>
        </form>
      </div>
    </div>

    <div class="popup popup-payment-invoicing">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup__title">{{ $t("popup.invoicing.title") }}</div>
        <div class="popup__text">{{ $t("popup.invoicing.text") }}</div>
        <router-link class="button button_gray w-100" to="/">{{
          $t("popup.common.buttonMain")
        }}</router-link>
      </div>
    </div>

    <div class="popup popup-payment-success">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup-payment-success__icon fal fa-check"></div>
        <div class="popup__title">{{ $t("popup.success.title") }}</div>
        <div class="popup__text">{{ $t("popup.success.text") }}</div>
        <router-link class="button button_gray w-100" to="/">{{
          $t("popup.common.buttonMain")
        }}</router-link>
      </div>
    </div>

    <div class="popup popup-feedback-success">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup-payment-success__icon fal fa-check"></div>
        <div class="popup__title">{{ $t("popup.feedback.title") }}</div>
        <div class="popup__text">{{ $t("popup.feedback.text") }}</div>
      </div>
    </div>

    <div class="popup popup-after-register">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup-payment-success__icon fal fa-check"></div>
        <div class="popup__title">{{ $t("popup.afterRegister.title") }}</div>
        <div class="popup__text" v-html="$t('popup.afterRegister.text')"></div>
      </div>
    </div>

    <div class="popup popup-wholesale-order-rub">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup-payment-fail__icon far fa-frown"></div>
        <div class="popup__text">{{ $t("popup.wholesaleOrder.rub") }}</div>
      </div>
    </div>

    <div class="popup popup-wholesale-order-usd">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup-payment-fail__icon far fa-frown"></div>
        <div class="popup__text">{{ $t("popup.wholesaleOrder.usd") }}</div>
      </div>
    </div>

    <div class="popup popup-wholesale-order-eur">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup-payment-fail__icon far fa-frown"></div>
        <div class="popup__text">{{ $t("popup.wholesaleOrder.eur") }}</div>
      </div>
    </div>

    <div class="popup popup-payment-fail">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup-payment-fail__icon far fa-frown"></div>
        <div class="popup__title">{{ $t("popup.paymentFail.title") }}</div>
        <div class="popup__text">{{ $t("popup.paymentFail.text") }}</div>
        <router-link class="button button_gray w-100" to="/">{{
          $t("popup.common.buttonMain")
        }}</router-link>
      </div>
    </div>

    <div class="popup popup-authorization-fail">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup-payment-fail__icon far fa-frown"></div>
        <div class="popup__title">
          {{ $t("popup.authorizationFail.title") }}
        </div>
        <div class="popup__text">{{ $t("popup.authorizationFail.text") }}</div>
      </div>
    </div>

    <div class="popup popup-checkout-fail">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup-payment-fail__icon far fa-frown"></div>
        <div class="popup__title">{{ $t("popup.paymentFail.title") }}</div>
        <div class="popup__text">{{ $t("popup.paymentFail.text") }}</div>
        <router-link class="button button_gray w-100" to="/">{{
          $t("popup.common.buttonMain")
        }}</router-link>
      </div>
    </div>

    <div class="popup popup-api-down">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup-payment-fail__icon far fa-frown"></div>
        <div class="popup__title">{{ $t("popup.apiDown.title") }}</div>
        <div class="popup__text">{{ $t("popup.apiDown.text") }}</div>
        <button class="button button_gray w-100" @click="refreshPage">
          {{ $t("popup.apiDown.refresh") }}
        </button>
      </div>
    </div>

    <div class="popup popup-checkout-stockroom">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup-payment-fail__icon far fa-frown"></div>
        <div class="popup__title">
          {{ $t("popup.checkoutStockroom.title") }}
        </div>
        <div class="popup__text">{{ $t("popup.checkoutStockroom.text") }}</div>
      </div>
    </div>

    <div class="popup popup-review">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup__title">{{ $t("popup.review.title") }}</div>
        <form class="form" @submit.prevent="sendFeedback">
          <textarea
            class="textarea"
            v-model="feedback.text"
            :placeholder="$t('popup.placeholder.message')"
            required
          ></textarea>
          <button type="submit" class="button">
            {{ $t("popup.common.buttonSend") }}
          </button>
        </form>
      </div>
    </div>

    <div class="popup popup-registered">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup-payment-success__icon fal fa-check"></div>
        <div class="popup__title">{{ $t("popup.registered.title") }}</div>
        <div class="popup__text">{{ $t("popup.registered.text") }}</div>
        <a class="button button_gray w-100" data-open_popup="login">{{
          $t("popup.registered.link")
        }}</a>
      </div>
    </div>

    <div class="popup popup-notify-success">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup-payment-success__icon fal fa-check"></div>
        <div class="popup__title">{{ $t("popup.notifySuccess.title") }}</div>
        <div class="popup__text">{{ $t("popup.notifySuccess.text") }}</div>
      </div>
    </div>

    <div class="popup popup-notify-fail">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup-payment-fail__icon far fa-frown"></div>
        <div class="popup__title">{{ $t("popup.notifySuccess.title") }}</div>
        <div class="popup__text">{{ $t("popup.notifySuccess.text") }}</div>
      </div>
    </div>

    <div class="popup popup-email-success">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup-payment-success__icon fal fa-check"></div>
        <div class="popup__title">{{ $t("popup.emailSuccess.title") }}</div>
        <div class="popup__text">{{ $t("popup.emailSuccess.text") }}</div>
      </div>
    </div>

    <div class="popup popup-email-fail">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup-payment-fail__icon far fa-frown"></div>
        <div class="popup__title">{{ $t("popup.emailFail.title") }}</div>
        <div class="popup__text">{{ $t("popup.emailFail.text") }}</div>
      </div>
    </div>

    <div class="popup popup-change-password-success">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup-payment-success__icon fal fa-check"></div>
        <div class="popup__title">{{ $t("popup.successEdit.title") }}</div>
        <div class="popup__text">{{ $t("popup.passwordRecover.text") }}</div>
      </div>
    </div>

    <div class="popup popup-change-password-updated">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup-payment-success__icon fal fa-check"></div>
        <div class="popup__title">{{ $t("popup.passwordRecover.title2") }}</div>
        <div class="popup__text">{{ $t("popup.passwordRecover.text2") }}</div>
      </div>
    </div>

    <div class="popup popup-change-password-fail">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup-payment-fail__icon far fa-frown"></div>
        <div class="popup__title">{{ $t("popup.passwordRecover.title3") }}</div>
        <div class="popup__text">{{ $t("popup.passwordRecover.text3") }}</div>
      </div>
    </div>

    <div class="popup popup-change-data" id="change-data">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup-payment-success__icon fal fa-check"></div>
        <div class="popup__title">{{ $t("popup.successEdit.title") }}</div>
      </div>
    </div>

    <div class="popup popup-wholesale-mail-success">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup-payment-success__icon fal fa-check"></div>
        <div class="popup__title">Ваша заявка отправлена!</div>
      </div>
    </div>

    <div class="popup popup-change-data-fail">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup-payment-fail__icon far fa-frown"></div>
        <div class="popup__title">{{ $t("popup.failEdit.title") }}</div>
        <div class="popup__text">{{ $t("popup.failEdit.text") }}</div>
        <router-link class="button w-80" to="/cabinet">{{
          $t("popup.failEdit.link")
        }}</router-link>
      </div>
    </div>

    <div class="popup popup-wholesale-off">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup-payment-fail__icon far fa-frown"></div>
        <div class="popup__text" v-html="$t('popup.wholesaleOff.text')"></div>
      </div>
    </div>

    <div class="popup popup-country-choose">
      <div class="popup__close" data-close_popup></div>
      <div class="popup__wrap text-center animate-delay" data-custom_scroll>
        <div class="popup__title">{{ $t("popup.chooseCountry.title") }}</div>
        <div class="popup__text" v-html="$t('popup.chooseCountry.text')"></div>
      </div>
    </div>

    <div class="popup popup-choose-cart">
      <div class="popup__wrap animate-delay" data-custom_scroll>
        <div class="popup__title">{{ $t("popup.chooseBasket.title") }}</div>
        <div class="popup__text">{{ $t("popup.chooseBasket.text") }}</div>
        <!-- <div class="popup__text text-red">{{ $t("popup.deleteProfile.text_attention") }}</div> -->
        <div class="popup__buttons">
          <button
            type="button"
            class="button button_clear"
            @click="chooseCart('auth')"
          >
            {{ $t("popup.chooseBasket.button_restore") }}
          </button>
          <button type="button" class="button" @click="chooseCart('anon')">
            {{ $t("popup.chooseBasket.button_leave") }}
          </button>
        </div>
      </div>
    </div>

    <PopupAppVersion />
  </div>
</template>

<script>
import Vue from "vue";
import Cart from "../../Stores/Cart.vue";
import AuthStore from "../../Stores/AuthStore.vue";
import Categories from "../../Stores/Categories.vue";
import MenuBanner from "../../Stores/MenuBanner.vue";
import VeeValidate from "vee-validate";
import PhoneNumber from "awesome-phonenumber";
import LangSwitcher from "../UI/LangSwitcher.vue";
import CurrencySwitcher from "../UI/CurrencySwitcher.vue";
import PopupLogin from "./PopupLogin.vue";
import PopupWholesale from "./PopupWholesale.vue";
import PopupAppVersion from "./PopupAppVersion.vue";
import GetCode from "./GetCode.vue";

Vue.use(VeeValidate);

const phoneNumber = {
  getMessage: (field) => `${field} не является номером телефона!`,
  validate(value) {
    return new Promise((resolve) => {
      const phone = new PhoneNumber(value);
      resolve({ valid: phone.isValid() });
    });
  },
};

VeeValidate.Validator.extend("phoneNumber", phoneNumber);

VeeValidate.Validator.extend("verify_password", {
  getMessage: (field) =>
    `Ваш пароль должен содержать как минимум 1 большую букву, 1 маленькую и 1 цифру`,
  validate: (value) => {
    let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])");
    return strongRegex.test(value);
  },
});

export default {
  name: "Popup",
  components: {
    LangSwitcher,
    CurrencySwitcher,
    PopupLogin,
    GetCode,
    PopupWholesale,
    PopupAppVersion,
  },
  data: () => {
    return {
      categories: null,
      currentLang: null,
      currentCurrency: null,
      promocode_discount: null,
      banner: null,
      register: {
        email: null,
        password: null,
        phone: null,
        password_repeat: null,
        agreement: true,
      },
      register_opt: {
        email: null,
        password: null,
        password_repeat: null,
        phone: null,
        city: null,
        market: false,
        market_url: "",
        fio: null,
        company_type: "",
        company_name: "",
        inn: "",
        kpp: "",
        okpo: "",
        agreement: true,
      },

      recovery: {
        email: null,
      },
      auth_error: null,
      register_error: null,
      feedback: {
        text: null,
      },
      notify: {
        email: null,
      },
      popup: {
        email: null,
        agree: false
      },
      siteMenu: {},
      query: null,
    };
  },
  methods: {
    search() {
      this.$store.commit("setPageSearch", null);
      this.$store.commit("setGlobalSearch", this.query);
      this.$router.push({
        name: "Catalog",
        query: { query: this.query },
      });
      const html = document.querySelector("html");
      html.classList.remove("popup_menu");
      html.classList.remove("show_popup");
      html.style.paddingRight = "0";
    },
    sendEmail() {
      this.$validator.validateAll("sendEmail").then((response) => {
        if (response) {
          this.$http.post("api/popup/", this.popup).then(
            (response) => {
              this.$ui.popup.togglePopup("email-success");
              this.$ls.set('bottom_popup', true);
            },
            (response) => {
              const field = this.$validator.fields.find({
                name: response.body.field,
                scope: "sendEmail",
              });

              if (!field) {
                return;
              }

              this.$validator.errors.add({
                id: field.id,
                field: "sendEmail." + response.body.field,
                msg: response.body.message,
                scope: this.$options.scope,
              });

              field.setFlags({
                invalid: true,
                valid: false,
                validated: true,
              });
            }
          );
        }
      });
    },
    recoveryAccount() {
      this.$validator.validateAll("recovery").then((response) => {
        if (response) {
          this.$http.post("api/auth/password_reset/", this.recovery).then(
            (response) => {
              this.$ui.popup.togglePopup("change-password-success");
            },
            (response) => {
              const field = this.$validator.fields.find({
                name: "email",
                scope: "recoveryAccount",
              });

              if (!field) {
                return;
              }

              this.$validator.errors.add({
                id: field.id,
                field: "recoveryAccount.email",
                msg: response.body.error_message,
                scope: this.$options.scope,
              });

              field.setFlags({
                invalid: true,
                valid: false,
                validated: true,
              });
            }
          );
        }
      });
    },
    sendFeedback() {
      this.$http
        .post("api/feedback/create/", this.feedback)
        .then((response) => {
          this.$ui.popup.togglePopup("feedback-success");
        });
    },
    getMenuCategories() {
      if (Categories.state.categories.length < 1) {
        this.$http.get("api/categories/catalog/").then((response) => {
          this.categories = response.body;
          Categories.state.categories = this.categories;

          if (this.currentLang === "en") {
            Categories.state.categories.unshift({
              slug: "",
              name: "All products",
              show_in_main_menu: true,
            });
          } else {
            Categories.state.categories.unshift({
              slug: "",
              name: "Все товары",
              show_in_main_menu: true,
            });
          }

          setTimeout(() => {
            this.$ui.scroll.addScroll();
          }, 100);
        });
      } else {
        this.categories = Categories.state.categories;

        setTimeout(() => {
          this.$ui.scroll.addScroll();
        }, 100);
      }
    },
    refreshPage() {
      window.location.reload();
    },
    getMenuBanner() {
      if (MenuBanner.state.banner.length < 1) {
        this.$http.get("api/new_banners/menu/").then((response) => {
          this.banner = response.body;

          MenuBanner.state.banner = this.banner;

          setTimeout(() => {
            this.$ui.scroll.addScroll();
          }, 100);
        });
      } else {
        this.banner = MenuBanner.state.banner;

        setTimeout(() => {
          this.$ui.scroll.addScroll();
        }, 100);
      }
    },

    registerOpt() {
      this.$validator.validateAll("registerOpt").then((response) => {
        if (response) {
          this.$http
            .post("api/auth/register/", {
              user_type: "WB",
              email: this.register_opt.email,
              password: this.register_opt.password,
              fio: this.register_opt.fio,
              phone: this.register_opt.phone,
              city: this.register_opt.city,
              market: this.register_opt.market,
              market_url: this.register_opt.market_url,
              company_type: this.register_opt.company_type,
              company_name: this.register_opt.company_name,
              inn: this.register_opt.inn,
              kpp: this.register_opt.kpp,
            })
            .then(
              (response) => {
                this.$ui.popup.closePopup();

                setTimeout(() => {
                  this.$ui.popup.togglePopup("after-register");
                }, 100);
              },
              (response) => {
                const field = this.$validator.fields.find({
                  name: response.body.error_message.field,
                  scope: "registerOpt",
                });

                if (!field) {
                  return;
                }

                this.$validator.errors.add({
                  id: field.id,
                  field: "registerOpt." + response.body.error_message.field,
                  msg: response.body.error_message.message,
                  scope: this.$options.scope,
                });

                field.setFlags({
                  invalid: true,
                  valid: false,
                  validated: true,
                });
              }
            );
        }
      });
    },
    registerRB() {
      this.$validator.validateAll("registerRB").then((response) => {
        if (response) {
          this.$http
            .post("api/auth/register/", {
              user_type: "RB",
              email: this.register.email,
              phone: this.register.phone,
              password: this.register.password,
            })
            .then(
              (response) => {
                this.$ui.popup.closePopup();

                setTimeout(() => {
                  this.$ui.popup.togglePopup("after-register");
                }, 100);
              },
              (response) => {
                const field = this.$validator.fields.find({
                  name: response.body.error_message.field,
                  scope: "registerRB",
                });

                if (!field) {
                  return;
                }

                this.$validator.errors.add({
                  id: field.id,
                  field: "registerRB." + response.body.error_message.field,
                  msg: response.body.error_message.message,
                  scope: this.$options.scope,
                });

                field.setFlags({
                  invalid: true,
                  valid: false,
                  validated: true,
                });
              }
            );
        }
      });
    },

    hideMenu() {
      this.$ui.popup.closePopup();
    },
    getPromocodeDiscount() {
      this.$http.get("api/popup-discount/").then((response) => {
        this.promocode_discount = response.body.promocode_popup_discount;
      });
    },
    chooseCart(type) {
      this.$http
        .post("api/cart/chose_cart/", {
          set_cart: type,
        })
        .then((response) => {
          this.$ui.popup.togglePopup("choose-cart");

          Cart.commit("updateCart", response.body);
        });
    },
    getSiteMenu() {
      this.$http.get("api/menu/site_menu/").then((response) => {
        this.siteMenu = response.body;
      });
    },
    showSiteMenu(item, itemPlace) {
      if (item.columns) {
        for (let i = 0; i < item.columns.length; i++) {
          if (item.columns[i].id === itemPlace) {
            return true;
          }
        }
      }
      return false;
    },
  },
  computed: {
    isUser() {
      return AuthStore.state.status.auth;
    },
  },
  created() {
    this.currentLang = this.$ls.get("lang") || "ru";
    this.currentCurrency = this.$ls.get("currency") || "ru";
    this.getMenuCategories();
    this.getMenuBanner();
    this.getPromocodeDiscount();
    this.getSiteMenu();
  },
  watch: {
    "$store.state.pageSearch": {
      handler() {
        if (!this.$store.state.globalSearch) {
          this.query = null;
        }
      },
    },
    "$route.params.category": {
      handler(newVal, oldVal) {
        if (!oldVal && newVal) {
          this.$store.commit("setGlobalSearch", null);
          this.query = null;
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.glif {
  font-family: "CoFoHolz";
}
</style>
