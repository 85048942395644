<template>
  <div class="popup popup-login">
    <div class="popup__close" data-close_popup></div>
    <div class="popup__wrap animate-delay">
      <div class="popup_login--title">
        {{ $t("popup.login.title") }}
      </div>
      <!-- <div class="popup__text">{{ $t("popup.login.entrySocial") }}</div> -->
      <!-- <div class="social-login">
        <div class="social-login__item">
          <div class="social-login__btn social-login__btn_fb">
            <div class="social-login__icon fab fa-facebook-f"></div>
            <a
              class="social-login__link"
              href="#"
              @click="authSocial($event, 'fb')"
            ></a>
          </div>
        </div>
        <div class="social-login__item">
          <div class="social-login__btn social-login__btn_ok">
            <div class="social-login__icon fab fa-odnoklassniki"></div>
            <a
              class="social-login__link"
              href="#"
              @click="authSocial($event, 'ok')"
            ></a>
          </div>
        </div>
        <div class="social-login__item">
          <div class="social-login__btn social-login__btn_vk">
            <div class="social-login__icon fab fa-vk"></div>
            <a
              class="social-login__link"
              href="#"
              @click="authSocial($event, 'vk')"
            ></a>
          </div>
        </div>
      </div> -->
      <!-- <PageSwitcher v-model="currentPage" /> -->
      <div v-show="currentPage === 1" class="popup-login-phone-email-switcher">
        <p
          class="popup-login-phone-email-switcher-label"
          :class="{ disabled: isEmail }"
        >
          {{ $t("popup.login.phone") }}
        </p>
        <WideCheckbox
          v-model="isEmail"
        />
        <p
          class="popup-login-phone-email-switcher-label"
          :class="{ disabled: !isEmail }"
        >
          {{ $t("popup.login.email") }}
        </p>
      </div>
      <p
        class="popup_login--text mb-0"
        v-show="
          currentPage === 1 || 2
        "
      >
        {{
          currentPage === 2 || isEmail
            ? $t("popup.login.enterMail")
            : $t("popup.login.enterPhone")
        }}
      </p>

      <div class="d-flex justify-content-between popup_login--content">
        <!-- <div class="popup__text">{{ $t("popup.login.haveAccount") }}</div> -->

        <form
          class="form"
          @submit.prevent="authorize"
          data-vv-scope="authorize"
          v-show="currentPage === 2 || isEmail"
        >
          <input
            class="input-text"
            v-model="auth.email"
            :class="{ 'input-text_error': errors.has('authorize.email') }"
            :data-vv-as="$t('popup.placeholder.email')"
            v-validate="{ required: true, email: true }"
            data-vv-name="email"
            type="email"
            :placeholder="$t('popup.placeholder.email')"
          />

          <div class="input__error" v-show="errors.has('authorize.email')">
            {{ errors.first("authorize.email") }}
          </div>

          <input
            class="input-text mb-12"
            v-model="auth.password"
            v-validate="'required'"
            :data-vv-as="$t('popup.placeholder.password')"
            data-vv-name="password"
            :class="{ 'input-text_error': errors.has('authorize.password') }"
            type="password"
            :placeholder="$t('popup.placeholder.password')"
          />

          <div class="input__error" v-show="errors.has('authorize.password')">
            {{ errors.first("authorize.password") }}
          </div>

          <input type="hidden" v-validate name="common" />

          <div class="input__error" v-show="errors.has('authorize.common')">
            {{ errors.first("authorize.common") }}
          </div>

          <label class="input-checkbox popup-checkbox margin-bottom">
            <input type="checkbox" v-model="auth.agree_email" :disabled="!auth.agree_emai"/>
            <div class="input-checkbox__checkbox fas fa-check"></div>
            <div
              class="input-checkbox__text"
              v-html="$t('checkout.delivery.check.agree_policy')"
            ></div>
          </label>

          <button type="submit" class="button" >
            {{ $t("popup.login.entry") }}
          </button>
        </form>
        <form
          class="form"
          @submit.prevent="submitPhone()"
          data-vv-scope="authorize"
          v-show="
            currentPage === 1 && !isEmail
          "
        >
          <input
            class="input-text mb-12"
            type="text"
            v-model="auth.phone"
            v-validate="'required'"
            v-mask="phoneMask"
            @blur="checkValidPhone()"
            @input="phonePrint($event.target.value)"
            data-vv-name="phone"
            :data-vv-as="$t('checkout.registration.placeholder.number')"
            :class="{ 'input-text_error': !validPhone || $ls.get('countOfAttempts') >= 3 }"
            :placeholder="$t('checkout.registration.placeholder.number')"
          />

          <div class="input__error" v-show="errors.has('authorize.password')">
            {{ errors.first("authorize.password") }}
          </div>

          <input type="hidden" v-validate name="common" />

          <div class="input__error" v-show="!validPhone">
            {{
              maskType === "ru"
                ? $t("popup.login.phoneErrors[0]")
                : $t("popup.login.phoneErrors[1]")
            }}
          </div>

          <label class="input-checkbox popup-checkbox margin-bottom">
            <input type="checkbox" v-model="auth.agree_phone" />
            <div class="input-checkbox__checkbox fas fa-check"></div>
            <div
              class="input-checkbox__text"
              v-html="$t('checkout.delivery.check.agree_policy')"
            ></div>
          </label>

          <button
            type="submit"
            class="button mb-0"
            :disabled="!validPhone || !auth.phone || !auth.agree_phone || $ls.get('countOfAttempts') >= 3"
          >
            {{ $t("popup.login.getCode") }}
          </button>
          <!-- <p class="popup_login-wholesale-text wholesale text-center mb-0">
            {{ $t("popup.login.wholesale") }}
          </p> -->
        </form>
        <div
          class="after-attempts-limit"
          v-show="currentPage === 1 && $ls.get('countOfAttempts') >= 3 && !isEmail"
        >
          <p class="after-attempts--error">
            {{ $t("popup.login.loginError") }}
          </p>
          <p class="after-attempts--text">
            {{ $t("popup.login.afterAttempts") }}
          </p>
          <a href="tel:8 800 700 65 06" class="phone-after-attempts"
            >8 800 700 65 06</a
          >
        </div>
        <div class="popup_login--footer" v-show="currentPage === 2 || isEmail">
          <div class="popup__text text-center mb-0">
            <a href="#" data-open_popup="recovery">{{
              $t("popup.login.forgetPass")
            }}</a>
          </div>
          <!-- <div class="popup__text mb-0">
            <a href="#" data-open_popup="registration">{{
              $t("popup.common.registry")
            }}</a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSwitcher from "./PageSwitcher.vue";
import WideCheckbox from "../UI/WideCheckbox.vue";
import { Axios } from "../../Plugins/axios.js";
import {CONFIG} from "../../config";
import {cryptoSign} from "../../Utilts/utilts";

export default {
  components: {
    PageSwitcher,
    WideCheckbox
  },
  data: () => ({
    auth: {
      email: null,
      password: null,
      phone: null,
      agree_phone: false,
      agree_email: false,
    },
    isEmail: false,
    validPhone: true,
    phoneMask: "+#(###)-###-##-##",
    maskType: "ru",
    currentPage: 1
  }),

  methods: {
    async submitPhone() {
      const matchArray = this.auth.phone.match(/[0-9]/g);

      try {
        const r = await Axios.post(CONFIG.AUTH_SEND_CODE_URL, {
          phone: cryptoSign(matchArray.join(""))
        });
        if (r.status === 200) {
          this.auth.phone = null;
          this.$ls.set("currentPhone", r.data.formatted_phone_number);
          if (this.$ls.get("countOfAttempts")) {
            this.$ls.set(
              "countOfAttempts",
              +this.$ls.get("countOfAttempts") + 1
            );
            this.$ls.set("countOfAttemptsDate", new Date(+new Date() + 600000));
          } else {
            this.$ls.set("countOfAttempts", 1);
            this.$ls.set("countOfAttemptsDate", new Date(+new Date() + 600000));
          }
          console.log("date: ", new Date(this.$ls.get("countOfAttemptsDate")).getTime(), "count: ", this.$ls.get("countOfAttempts"))
          console.log("time", new Date().getTime())
          this.$root.$emit("codePopup:opened");
        }
      } catch (e) {
        alert(e);
      }

      this.$ui.popup.togglePopup("get-code");
    },
    checkValidPhone() {
      if (this.auth.phone) {
        if (this.auth.phone.length !== 17) this.validPhone = false;
      }
    },
    phonePrint(newValue) {
      if (this.auth.phone.length === 17) this.validPhone = true;
      if (newValue === "+") {
        this.auth.phone = "+";
        this.phoneMask = "+###-##-###-##-##";
      } else if (newValue.substr(0, 1) === "3") {
        this.maskType = "int";
        this.phoneMask = "+###-##-###-##-##";
      } else if (newValue.substr(0, 2) === "+3") {
        this.maskType = "int";
        this.phoneMask = "+###-##-###-##-##";
      } else if (
        newValue.substr(0, 1) === "7" ||
        newValue.substr(0, 1) === "8"
      ) {
        this.auth.phone = "+7";
        this.phoneMask = "+7(###)-###-##-##";
        this.maskType = "ru";
      } else if (newValue.substr(0, 2) === "+8") {
        this.auth.phone = "+7";
        this.phoneMask = "+7(###)-###-##-##";
        this.maskType = "ru";
      } else {
        this.phoneMask = "+7(###)-###-##-##";
        this.maskType = "ru";
      }
    },
    authSocial(e, type) {
      e.preventDefault();

      this.$http
        .get("api/oauth/authorize_redirect/" + type + "/")
        .then(response => {
          window.location = response.body.redirect_url;
        });
    },
    authorize() {
      // this.$validator.validateAll("authorize").then(response => {
      //   if (response) {
      this.$http
        .post("api/auth/token-get/", {
          username: this.auth.email,
          password: this.auth.password
        })
        .then(
          response => {
            this.$ui.popup.closePopup();

            this.$ls.set("access", response.body.access);
            this.$ls.set("refresh", response.body.refresh);

            if (this.$route.name === "Recovery") {
              this.$router.push("/");
            } else {
              window.location.reload(true);
            }
          },
          response => {
            if (!response.body.error_message || !response.body.error_message.field) return

            const field = this.$validator.fields.find({
              name: response.body.error_message.field,
              scope: "authorize"
            });

            if (!field) {
              return;
            }

            this.$validator.errors.add({
              id: field.id,
              field: "authorize." + response.body.error_message.field,
              msg: response.body.error_message.message,
              scope: this.$options.scope
            });

            field.setFlags({
              invalid: true,
              valid: false,
              validated: true
            });
          }
        );
      // }
      // });
    }
  },
  mounted() {
    if (new Date(this.$ls.get("countOfAttemptsDate")).getTime() < new Date().getTime()) {
      this.$ls.set("countOfAttempts", 0);
    }
  }
};
</script>

<style lang="scss" scoped>

.popup-login-phone-email-switcher {
  padding-top: 35px;
  display: grid;
  grid-template-columns: max-content max-content max-content;
  grid-gap: 16px;
  align-items: center;
}

.popup-login-phone-email-switcher-label {
  margin: 0;
  font-family: CoFoSans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;

  color: #333333;
  transition: 0.2s;
  &.disabled {
    color: #bdbdbd;
  }
}
</style>
