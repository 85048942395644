<template>
  <div class="popup popup-app-version">
    <div class="popup__close" data-close_popup></div>
    <div class="popup__wrap animate-delay" data-custom_scroll>
      <div class="popup__title">Обновление приложения</div>
      <div class="app-version__text">
        <span>Ваша версия приложения устарела!</span><br />Это может привести к
        некорректной работе.<br />Пожалуйста, обновите страницу, для загрузки
        новой версии.
      </div>
      <div class="app-version__btns">
        <button type="button" class="button" @click="remindLater">
          Напомнить позже
        </button>
        <button type="button" class="button" @click="reloadPage">
          Обновить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopupAppVersion",
  methods: {
    reloadPage() {
      window.location.reload();
    },
    remindLater() {
      this.$ui.popup.closePopup("app-version");
      this.$store.commit("setVersionLongPollingInterval", 1800000);
      if (this.fetchVersion) this.fetchVersion(true);
    }
  }
};
</script>

<style lang="scss" scoped>
.popup__wrap {
  text-align: center;
}
.app-version__text {
  font-size: 14px;
  color: #322f2b;
  line-height: 1.2;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 10px;

  span {
    color: #eb5757;
  }
}

.app-version__btns {
  display: flex;
  gap: 20px;

  @media (max-width: 665.98px) {
    flex-direction: column;
    gap: 10px;
  }
}
</style>
