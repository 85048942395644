<script>
  import Vue from 'vue';
  import Vuex from 'vuex';

  Vue.use(Vuex);

  export default new Vuex.Store({
    state: {
      status: {
        auth: false,
        currency: null,
        person_type: null,
        wholesale_access: true
      }
    },
    mutations: {
      updateStatus(state, status) {
        state.status.auth = status;
      },
      currency(state, status) {
        state.status.currency = status;
      },
      person_type(state, type) {
        state.status.person_type = type;
      },
      wholesale_access(state, status) {
        state.status.wholesale_access = status;
      }
    },
    getters: {
      auth: state => {
        return state.status.auth;
      }
    }
  });
</script>
