import AuthStore from '../Stores/AuthStore.vue';

export default {
  AuthStore,
  install(Vue) {
    Vue.$AuthStore = {
      auth: AuthStore.state.status.auth,
      wholesaleAccess: AuthStore.state.status.wholesale_access,
      currency: AuthStore.state.status.currency,
      commit: AuthStore.commit
    };
  }
};
