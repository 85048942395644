<template>
  <div class="header-popup-basket" v-if="favorite">
    <div class="header-popup-basket__title h3 animate-delay">
      {{ $t("header.favorites.title") }}
    </div>
    <div
      class="header-popup-basket__wrap animate-delay"
      v-if="favorite.length > 0"
      data-custom_scroll
    >
      <div class="basket-item" v-for="item in favorite">
        <div class="basket-item__header">
          <div class="basket-item__name">
            <router-link
              v-on:click.native="hidePopup"
              :to="'/product/' + item.slug"
              >{{ item.name }}</router-link
            >
          </div>
          <!--<div class="basket-item__icon circle-btn-icon"><div class="circle-btn-icon__icon far fa-heart"></div></div>-->
          <div
            class="basket-item__icon circle-btn-icon"
            @click="deleteItem(item)"
          >
            <div class="circle-btn-icon__icon">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M16.104 7.89017C16.104 7.89017 15.6515 13.5027 15.389 15.8668C15.264 16.996 14.5665 17.6577 13.424 17.6785C11.2499 17.7177 9.0732 17.7202 6.89986 17.6743C5.8007 17.6518 5.11486 16.9818 4.99236 15.8727C4.7282 13.4877 4.2782 7.89017 4.2782 7.89017"
                  stroke="#9F9D9D"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.2568 5.19975H3.12512"
                  stroke="#9F9D9D"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.5337 5.19974C13.8796 5.19974 13.3162 4.73724 13.1879 4.0964L12.9854 3.08307C12.8604 2.61557 12.4371 2.29224 11.9546 2.29224H8.42707C7.94457 2.29224 7.52123 2.61557 7.39623 3.08307L7.19373 4.0964C7.0654 4.73724 6.50207 5.19974 5.8479 5.19974"
                  stroke="#9F9D9D"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="basket-item__row">
          <div class="basket-item__photo">
            <router-link
              v-on:click.native="hidePopup"
              :to="'/product/' + item.slug"
              ><img :src="item.main_image" alt="" />
            </router-link>
          </div>
          <div class="basket-item__body">
            <div class="basket-item__cost-group" v-if="item.discount_price">
              <div class="product-cost">
                {{ dividingNumberDigits(item.discount_price) }} {{ getSymbol() }}
              </div>
              <div class="product-cost-old">
                {{ dividingNumberDigits(item.price) }} {{ getSymbol() }}
              </div>
            </div>

            <div class="basket-item__cost-group" v-if="!item.discount_price">
              <div class="product-cost product-cost-current">
                {{ dividingNumberDigits(item.price) }} {{ getSymbol() }}
              </div>
            </div>
            <div class="basket-item-prop">
              <div class="basket-item-prop__group">
                <div class="basket-item-prop__item d-flex align-items-center">
                  <div class="basket-item-prop__name">
                    {{ $t("header.basket.size") }}:
                  </div>
                  <div class="size-item" data-active>{{ item.size }}</div>
                </div>
                <div class="basket-item-prop__item d-flex align-items-center">
                  <div class="basket-item-prop__name">
                    {{ $t("header.basket.color") }}:
                  </div>
                  <div
                    class="color-item"
                    :class="{
                      'color-item-multicolor':
                        getColorById(item.color_id) == '#FFFFFE',
                    }"
                    :style="{ color: getColorById(item.color_id) }"
                    data-active
                  ></div>
                </div>
              </div>
              <div class="basket-item-prop__item">
                <div class="basket-item-prop__name">
                  {{ $t("header.basket.quantity") }}:
                </div>
                <p v-if="item.max_quantity < 1">
                  {{ $t("basket.outOfStock") }}
                </p>
                <ui-quantity
                  v-if="item.max_quantity > 0"
                  :quantity.sync="item.quantity"
                  :item="item"
                  :min="1"
                  :max.sync="item.max_quantity"
                  type="favorite"
                ></ui-quantity>
              </div>
            </div>
          </div>
        </div>
        <a
          class="button"
          v-if="item.max_quantity > 0"
          @click="item.max_quantity > 0 ? addToBasket(item) : null"
        >
          {{ $t("header.basket.buttonAdd") }}
        </a>
        <!--<a class="button button_gray" v-if="item.max_quantity < 1">-->
        <!--{{$t('basket.outOfStock')}}-->
        <!--</a>-->
      </div>
    </div>

    <div
      v-if="favorite.length < 1"
      class="header-popup-basket__empty animate-delay"
    >
      {{ $t("favorites.empty") }}
    </div>
  </div>
</template>

<script>
import FavoriteStore from "../../Stores/Favorite.vue";
import Cart from "../../Stores/Cart.vue";
import Colors from "../../Stores/Colors.vue";
import UiQuantity from "../../Components/UI/Quantity.vue";
import { dividingNumberDigits  } from "../../Utilts/utilts";
export default {
  name: "Favorite",
  components: {
    UiQuantity,
  },
  data() {
    return {
      currentCurrency: null,
    };
  },
  methods: {
    dividingNumberDigits,
    getFavorite() {
      this.$http.get("api/favorites/").then((response) => {
        FavoriteStore.commit("updateFavorite", response.body.products);

        setTimeout(() => {
          this.$ui.scroll.addScroll();
        }, 100);
      });
    },
    getColorById(id) {
      let color = null;

      if (this.colors) {
        if (this.colors.colors) {
          for (let i = 0; i < this.colors.colors.length; i++) {
            if (id == this.colors.colors[i].id) {
              color = this.colors.colors[i].color_code;
            }
          }
        }
      }

      return color;
    },
    deleteItem(item) {
      this.$http
        .delete("api/favorites/remove/", {
          body: {
            color_id: parseInt(item.color_id),
            size: item.size,
            product_id: parseInt(item.product_id),
          },
        })
        .then((response) => {
          FavoriteStore.commit("updateFavorite", response.body.products);
        });
    },
    addToBasket(item) {
      const data = {
        product_id: parseInt(item.product_id),
        color_id: item.color_id,
        size: item.size,
        quantity: item.quantity ? item.quantity : 1,
        update: false,
      };

      this.$http.post("api/cart/add/", data).then((response) => {
        Cart.commit("updateCart", response.body);
        if (VK) {
          let _tmr = window._tmr || (window._tmr = []);
          _tmr.push({
            type: "reachGoal",
            id: VK_PIXEL_ID,
            value: item.price,
            goal: "addToCart",
            params: { product_id: `${item.product_id}` },
          });
        }
      });
    },
    hidePopup() {
      this.$ui.popup.closePopup();
    },
    getSymbol() {
      let symbol = null;

      switch (this.currentCurrency) {
        case "RUB":
          symbol = "₽";
          break;

        case "USD":
          symbol = "$";
          break;

        case "EUR":
          symbol = "€";
          break;
      }

      return symbol;
    },
  },
  computed: {
    favorite() {
      return FavoriteStore.state.favorite;
    },
    colors() {
      return Colors.state.colors;
    },
  },
  created() {
    this.currentCurrency = this.$ls.get("currency") || "RUB";

    this.getFavorite();

    // this.$root.$on('store:favorite:get', (data) => {
    //   this.favorite = (data) ? data : [];
    //
    //   setTimeout(() => {
    //     this.$ui.scroll.addScroll();
    //   }, 100);
    // });
  },
};
</script>
<style lang="scss" scoped>
.header-popup-basket__wrap .basket-item:first-child {
  .basket-item__header {
    @media (max-width: 665.98px) {
      margin-top: 6px !important;
    }
  }
}

.basket-item__name {
  @media (max-width: 665.98px) {
    margin-right: 10px;
  }
}

.header-popup-basket {
  @media (max-width: 665.98px) {
    max-width: 290px;
  }
}

.header-popup-basket .basket-item .button {
  @media (max-width: 665.98px) {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
</style>
