import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function(callback, thisArg) {
    thisArg = thisArg || window;
    for (let i = 0; i < this.length; i++)
      callback.call(thisArg, this[i], i, this);
  };
}
(function(ELEMENT) {
  ELEMENT.matches =
    ELEMENT.matches ||
    ELEMENT.mozMatchesSelector ||
    ELEMENT.msMatchesSelector ||
    ELEMENT.oMatchesSelector ||
    ELEMENT.webkitMatchesSelector;
  ELEMENT.closest =
    ELEMENT.closest ||
    function closest(selector) {
      if (!this) return null;
      if (this.matches(selector)) return this;
      if (!this.parentElement) {
        return null;
      } else return this.parentElement.closest(selector);
    };
})(Element.prototype);

const clanvi = {
  device_viewport: {
    tablet: 991,
    phone: 665
  },

  init: function() {
    this.header.init();
    this.popup.init();
    this.mask.init();
    this.promoSlider.init();
    this.productionSlider.init();
    this.imgSlider.init();
    this.reviewsSlider.init();
    this.circlePercent.init();
    this.slideToggle.init();
    this.showToggle.init();
    this.scroll.init();
    const wrapper = document.querySelector(".responsive-wrapper");
    const closeButton = document.querySelector("div[data-af-close-button]");
    if (wrapper) {
      const header = document.querySelector(".header");
      const popupMenu = document.querySelector(".popup-menu");
      header.classList.add("header_is_banner");
      popupMenu.classList.add("popup_menu_is_banner");
      closeButton.addEventListener("click", () => {
        header.classList.remove("header_is_banner");
        popupMenu.classList.remove("popup_menu_is_banner");
      });
    }
  },

  header: {
    init: function() {
      this.addEvents();
      // this.checkScroll();
    },
    addEvents: function() {
      this.eventListener &&
        window.removeEventListener("scroll", this.eventListener);
      this.eventListener &&
        window.removeEventListener("resize", this.eventListener);
      // this.eventListener = () => this.checkScroll();
      window.addEventListener("scroll", this.eventListener);
      window.addEventListener("resize", this.eventListener);
    }
    // checkScroll: function() {
    //   window.pageYOffset >= 0
    //     ? document.querySelector(".header").classList.add("header_fixed")
    //     : document.querySelector(".header").classList.remove("header_fixed");
    // }
  },

  popup: {
    init: function() {
      this.cur_popup_name = null;
      this.scrollbar_width = 0;
      this.addEvents();
    },
    addEvents: function() {
      this.eventListener &&
        window.removeEventListener("resize", this.eventListenerResize);
      this.eventListener &&
        document
          .querySelector("body")
          .removeEventListener("click", this.eventListener);
      this.eventListenerResize = () => this.checkAvaible();
      this.eventListener = e => {
        let el = e.target.closest("[data-toggle_popup]");
        if (el) {
          e.preventDefault();
          this.togglePopup(el.getAttribute("data-toggle_popup"));
          return;
        }
        el = e.target.closest("[data-open_popup]");
        if (el) {
          e.preventDefault();
          this.openPopup(el.getAttribute("data-open_popup"));
          return;
        }
        if (e.target.closest("[data-close_popup]")) {
          e.preventDefault();
          this.closePopup();
        }
      };
      window.addEventListener("resize", this.eventListenerResize);
      document
        .querySelector("body")
        .addEventListener("click", this.eventListener);
    },
    checkAvaible: function() {
      if (this.cur_popup_name === "filter") {
        if (window.innerWidth > clanvi.device_viewport.tablet) {
          this.closePopup();
          return false;
        }
        return true;
      }
      if (
        this.cur_popup_name === "currency" ||
        this.cur_popup_name === "language" ||
        this.cur_popup_name === "sort"
      ) {
        if (window.innerWidth <= clanvi.device_viewport.tablet) {
          this.closePopup();
          return false;
        }
        return true;
      }
      return true;
    },
    togglePopup: function(popup_name) {
      document.querySelector("html").classList.contains(`popup_${popup_name}`)
        ? this.closePopup()
        : this.openPopup(popup_name);
    },
    openPopup: function(popup_name) {
      this.closePopup();
      this.cur_popup_name = popup_name;
      if (!this.checkAvaible()) return;
      let el_html = document.querySelector("html");
      if (popup_name === "menu") {
        this.scrollbar_width = window.innerWidth - el_html.clientWidth;
        if (this.scrollbar_width) {
          el_html.style.paddingRight = this.scrollbar_width + "px";
          document.querySelector(".header").style.paddingRight =
            this.scrollbar_width + "px";
          document.querySelector(".popup-menu__wrap").style.paddingRight =
            this.scrollbar_width + "px";
        }
      }
      clanvi.scroll.disableScroll();
      el_html.classList.add(`popup_${popup_name}`);
      el_html.classList.add("show_popup");

      if (popup_name === "availability") {
        if (document.getElementById("scroller")) {
          // IE9, Chrome, Firefox, Opera
          document
            .getElementById("scroller")
            .addEventListener("mousewheel", this.scrollHorizontally, false);
          // Safari
          document
            .getElementById("scroller")
            .addEventListener("wheel", this.scrollHorizontally, false);
        }
      }
    },
    closePopup: function(popup_name) {
      let el_html = document.querySelector("html");
      this.cur_popup_name = null;
      if (this.scrollbar_width) {
        el_html.removeAttribute("style");
        document.querySelector(".header").removeAttribute("style");
        document.querySelector(".popup-menu__wrap").removeAttribute("style");
        this.scrollbar_width = 0;
      }
      clanvi.scroll.enableScroll();
      if (!el_html.classList.length) return;
      el_html.className.split(" ").forEach(class_name => {
        if (~class_name.indexOf("popup")) el_html.classList.remove(class_name);
      });

      if (document.getElementById("scroller")) {
        document
          .getElementById("scroller")
          .removeEventListener("mousewheel", this.scrollHorizontally, false);
        document
          .getElementById("scroller")
          .removeEventListener("wheel", this.scrollHorizontally, false);
      }
    },
    scrollHorizontally(e) {
      e = window.event || e;
      var delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail));
      document.getElementById("scroller").scrollLeft -= delta * 40; // Multiplied by 40
      e.preventDefault();
    }
  },

  mask: {
    init: function() {
      this.phone_mask_option = {
        numericOnly: true,
        blocks: [2, 0, 3, 0, 3, 2, 2],
        delimiters: [" ", "(", ")", " ", "-", "-"],
        prefix: "+7",
        noImmediatePrefix: true
      };
      this.addMask();
    },
    addEvents: function(el) {
      if (!el) return;
      el.addEventListener("blur", () => {
        if (el.Cleave.getRawValue().length < 12) el.Cleave.setRawValue();
      });
    },
    addMask: function() {
      document.querySelectorAll("input[data-mask_phone]").forEach(el => {
        if (el.Cleave) return;
        el.Cleave = new Cleave(el, this.phone_mask_option);
        this.addEvents(el);
      });
    }
  },

  promoSlider: {
    PromoSlider: class {
      constructor(el) {
        el.Slider = this;
        this.slider = el;
        this.el_slider_wrap = this.slider.querySelector(".promo-slider__wrap");
        this.el_slider_item = this.slider.querySelectorAll(
          ".promo-slider__item"
        );
        this.el_dots_group = this.slider.querySelector(".dots-group");
        this.el_dots_group_item = null;
        this.count_items = this.el_slider_item.length;
        this.dots_state = [];
        this.current_index = 0;
        this.prev_index = 0;
        this.touchstart_pos = 0;
        this.touchend_pos = 0;
        this.autoslide = true;
        this.autoslide_timeout = null;
        this.autoslide_timeout_delay = 5000;
        this._placeDots();
        if (this.count_items > 1) {
          this._getSliderPosition();
          this._addEvents();
        }
      }
      _addEvents() {
        window.addEventListener("resize", () => this._getSliderPosition());
        window.addEventListener("scroll", () => this._checkVisible());
        this.el_slider_wrap.addEventListener(
          "touchstart",
          e => (this.touchstart_pos = clanvi.pointerEventToXY(e))
        );
        this.el_slider_wrap.addEventListener("touchend", e => {
          this.touchend_pos = clanvi.pointerEventToXY(e);
          if (
            Math.abs(this.touchstart_pos.y - this.touchend_pos.y) < 30 &&
            Math.abs(this.touchstart_pos.x - this.touchend_pos.x) > 60
          ) {
            this.touchstart_pos.x > this.touchend_pos.x
              ? this.nextSlide()
              : this.prevSlide();
          }
        });
        this.el_dots_group.addEventListener("click", e => {
          if (e.target.closest(".dots-group__item"))
            this.setSlide(
              Array.prototype.indexOf.call(this.el_dots_group_item, e.target)
            );
        });
      }
      _getSliderPosition() {
        this.slider_position_y = this.slider.offsetTop;
        this.slider_height = this.slider.clientHeight;
        this._checkVisible();
      }
      _checkVisible() {
        let visible = false,
          offset_top = this.slider_position_y + this.slider_height / 3,
          offset_bottom = offset_top + this.slider_height / 3;
        if (
          window.pageYOffset < offset_top &&
          window.pageYOffset + window.innerHeight > offset_bottom
        )
          visible = true;
        if (visible !== this.is_visible) {
          this.is_visible = visible;
          if (visible) this._addTimeout();
          else clearTimeout(this.autoslide_timeout);
        }
      }
      _placeDots() {
        this.el_dots_group.innerHTML = "";
        this.dots_state = [];
        if (this.count_items < 2) return;
        this.el_dots_group.innerHTML = Array(this.count_items + 1).join(
          '<div class="dots-group__item"></div>'
        );
        this.el_dots_group_item = this.el_dots_group.querySelectorAll(
          ".dots-group__item"
        );
        this.el_dots_group_item[0].setAttribute("data-active", "");
        this._instaDots();
      }
      _instaDots() {
        if (this.count_items < 6) {
          this.el_dots_group_item.forEach((el, i) => {
            el.setAttribute("data-visible", "");
            el.removeAttribute("data-visible_middle");
            el.removeAttribute("data-visible_small");
          });
          return;
        }
        if (!this.dots_state.length) {
          this.el_dots_group_item.forEach((el, i) => {
            this.dots_state[i] = 0;
            el.removeAttribute("data-visible");
            el.removeAttribute("data-visible_middle");
            el.removeAttribute("data-visible_small");
            if (i < 3) {
              this.dots_state[i] = 1;
            }
            if (i < 5) {
              el.setAttribute("data-visible", "");
            }
            if (i === 3) {
              el.setAttribute("data-visible_middle", "");
            }
            if (i === 4) {
              el.setAttribute("data-visible_small", "");
            }
          });
        } else {
          if (this.dots_state[this.current_index] === 1) return;
          this.el_dots_group_item.forEach((el, i) => {
            el.removeAttribute("data-visible");
            el.removeAttribute("data-visible_middle");
            el.removeAttribute("data-visible_small");
            this.dots_state[i] = 0;
            if (this.current_index - this.prev_index > 0) {
              if (this.current_index - 4 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_small", "");
              }
              if (this.current_index - 3 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_middle", "");
              }
              if (this.current_index - 2 === i) {
                el.setAttribute("data-visible", "");
                this.dots_state[i] = 1;
              }
              if (this.current_index - 1 === i) {
                el.setAttribute("data-visible", "");
                this.dots_state[i] = 1;
              }
              if (this.current_index === i) {
                el.setAttribute("data-visible", "");
                this.dots_state[i] = 1;
              }
              if (this.current_index + 1 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_middle", "");
              }
              if (this.current_index + 2 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_small", "");
              }
            } else {
              if (this.current_index - 2 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_small", "");
              }
              if (this.current_index - 1 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_middle", "");
              }
              if (this.current_index === i) {
                el.setAttribute("data-visible", "");
                this.dots_state[i] = 1;
              }
              if (this.current_index + 1 === i) {
                el.setAttribute("data-visible", "");
                this.dots_state[i] = 1;
              }
              if (this.current_index + 2 === i) {
                el.setAttribute("data-visible", "");
                this.dots_state[i] = 1;
              }
              if (this.current_index + 3 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_middle", "");
              }
              if (this.current_index + 4 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_small", "");
              }
            }
          });
        }
      }
      _addTimeout() {
        clearTimeout(this.autoslide_timeout);
        if (!this.autoslide) return;
        if (!this.is_visible) return;
        this.autoslide_timeout = setTimeout(() => {
          if (!this.autoslide) return;
          if (!this.is_visible) return;
          this.nextSlide();
        }, this.autoslide_timeout_delay);
      }
      setSlide(index) {
        if (this.count_items < 2) return;
        this._addTimeout();
        if (index >= this.count_items) index = 0;
        if (index < 0) index = this.count_items - 1;
        if (index === this.current_index) return;
        this.prev_index = this.current_index;
        this.current_index = index;
        this.el_slider_item.forEach((el, i) => {
          index === i
            ? el.setAttribute("data-active", "")
            : el.removeAttribute("data-active");
        });
        this.el_dots_group_item.forEach((el, i) => {
          index === i
            ? el.setAttribute("data-active", "")
            : el.removeAttribute("data-active");
        });
        this._instaDots();
      }
      nextSlide() {
        this.setSlide(this.current_index + 1);
      }
      prevSlide() {
        this.setSlide(this.current_index - 1);
      }
    },
    init: function() {
      document.querySelectorAll(".promo-slider").forEach(el => {
        if (el.Slider) return;
        new this.PromoSlider(el);
      });
    }
  },

  productionSlider: {
    ProductionSlider: class {
      constructor(el) {
        el.Slider = this;
        this.slider = el;
        this.el_slider_body = this.slider.querySelector(
          ".production-slider__body"
        );
        this.el_slider_wrap = this.slider.querySelector(
          ".production-slider__wrap"
        );
        this.el_slider_item = this.slider.querySelectorAll(
          ".production-slider__items .catalog-item"
        );
        this.el_dots_group = this.slider.querySelector(".dots-group");
        this.el_slider_layer = this.slider.querySelectorAll(
          ".production-slider__layer"
        );
        this.el_dots_group_item = null;
        this.count_items = this.el_slider_item.length;
        this.count_on_viewport = {
          320: 2,
          768: 3,
          1200: 4
        };
        this.count_on_screen = 0;
        this.count_dots = 0;
        this.dots_state = [];
        this.current_index = 0;
        this.prev_index = 0;
        this.current_layer = 0;
        this.animated = false;
        this.touchstart_pos = 0;
        this.touchend_pos = 0;
        this.autoslide = true;
        this.autoslide_timeout = null;
        this.autoslide_timeout_delay = 5000;
        this.animation_duration = 400;
        this.is_visible = false;
        this._calcDots();
        if (this.count_items > 2) this._addEvents();
      }
      _addEvents() {
        window.addEventListener("resize", () => this._calcDots());
        window.addEventListener("scroll", () => this._checkVisible());
        this.el_slider_wrap.addEventListener(
          "touchstart",
          e => (this.touchstart_pos = clanvi.pointerEventToXY(e))
        );
        this.el_slider_wrap.addEventListener("touchend", e => {
          this.touchend_pos = clanvi.pointerEventToXY(e);
          if (
            Math.abs(this.touchstart_pos.y - this.touchend_pos.y) < 30 &&
            Math.abs(this.touchstart_pos.x - this.touchend_pos.x) > 60
          ) {
            this.touchstart_pos.x > this.touchend_pos.x
              ? this.nextSlide()
              : this.prevSlide();
          }
        });
        this.el_dots_group.addEventListener("click", e => {
          if (e.target.closest(".dots-group__item"))
            this.setSlide(
              Array.prototype.indexOf.call(this.el_dots_group_item, e.target)
            );
        });
      }
      _getSliderPosition() {
        this.slider_position_y = this.el_slider_body.offsetTop;
        this.slider_height = this.el_slider_wrap.clientHeight;
        this._checkVisible();
      }
      _checkVisible() {
        let visible = false,
          offset_top = this.slider_position_y + this.slider_height / 3,
          offset_bottom = this.slider_position_y + this.slider_height;
        if (
          window.pageYOffset < offset_top &&
          window.pageYOffset + window.innerHeight > offset_bottom
        )
          visible = true;
        if (visible !== this.is_visible) {
          this.is_visible = visible;
          if (visible) this._addTimeout();
          else clearTimeout(this.autoslide_timeout);
        }
      }
      _calcDots() {
        if (this.count_items < 3) return;
        this._getSliderPosition();
        let count_on_screen;
        for (let breakpoint in this.count_on_viewport) {
          if (window.innerWidth >= breakpoint)
            count_on_screen = this.count_on_viewport[breakpoint];
        }
        if (count_on_screen !== this.count_on_screen) {
          this.count_on_screen = count_on_screen;
          this._placeDots();
        }
      }
      _placeDots() {
        this.current_index = 0;
        this.count_dots = 0;
        this.dots_state = [];
        if (this.count_items > this.count_on_screen) {
          this.count_dots = Math.ceil(this.count_items / this.count_on_screen);
          this.el_dots_group.innerHTML = Array(this.count_dots + 1).join(
            '<div class="dots-group__item"></div>'
          );
          this.el_dots_group_item = this.el_dots_group.querySelectorAll(
            ".dots-group__item"
          );
          this.el_dots_group_item[0].setAttribute("data-active", "");
          this._instaDots();
        } else this.el_dots_group.innerHTML = "";
        if (
          this.el_slider_layer[this.current_layer].querySelectorAll(
            ".catalog-item"
          ).length !== this.count_on_screen
        )
          this._sliderRefresh();
      }
      _instaDots() {
        if (this.count_dots < 6) {
          this.el_dots_group_item.forEach((el, i) => {
            el.setAttribute("data-visible", "");
            el.removeAttribute("data-visible_middle");
            el.removeAttribute("data-visible_small");
          });
          return;
        }
        if (!this.dots_state.length) {
          this.el_dots_group_item.forEach((el, i) => {
            this.dots_state[i] = 0;
            el.removeAttribute("data-visible");
            el.removeAttribute("data-visible_middle");
            el.removeAttribute("data-visible_small");
            if (i < 3) {
              this.dots_state[i] = 1;
            }
            if (i < 5) {
              el.setAttribute("data-visible", "");
            }
            if (i === 3) {
              el.setAttribute("data-visible_middle", "");
            }
            if (i === 4) {
              el.setAttribute("data-visible_small", "");
            }
          });
        } else {
          if (this.dots_state[this.current_index] === 1) return;
          this.el_dots_group_item.forEach((el, i) => {
            el.removeAttribute("data-visible");
            el.removeAttribute("data-visible_middle");
            el.removeAttribute("data-visible_small");
            this.dots_state[i] = 0;
            if (this.current_index - this.prev_index > 0) {
              if (this.current_index - 4 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_small", "");
              }
              if (this.current_index - 3 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_middle", "");
              }
              if (this.current_index - 2 === i) {
                el.setAttribute("data-visible", "");
                this.dots_state[i] = 1;
              }
              if (this.current_index - 1 === i) {
                el.setAttribute("data-visible", "");
                this.dots_state[i] = 1;
              }
              if (this.current_index === i) {
                el.setAttribute("data-visible", "");
                this.dots_state[i] = 1;
              }
              if (this.current_index + 1 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_middle", "");
              }
              if (this.current_index + 2 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_small", "");
              }
            } else {
              if (this.current_index - 2 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_small", "");
              }
              if (this.current_index - 1 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_middle", "");
              }
              if (this.current_index === i) {
                el.setAttribute("data-visible", "");
                this.dots_state[i] = 1;
              }
              if (this.current_index + 1 === i) {
                el.setAttribute("data-visible", "");
                this.dots_state[i] = 1;
              }
              if (this.current_index + 2 === i) {
                el.setAttribute("data-visible", "");
                this.dots_state[i] = 1;
              }
              if (this.current_index + 3 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_middle", "");
              }
              if (this.current_index + 4 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_small", "");
              }
            }
          });
        }
      }
      _sliderRefresh() {
        this.el_slider_layer[
          this.current_layer
        ].innerHTML = this._getItemsContent(0);
      }
      _getItemsContent(index) {
        let items_html = "",
          start = this.count_on_screen * index,
          end = start + (this.count_on_screen - 1);
        if (end >= this.count_items) {
          let offset = end - (this.count_items - 1);
          start -= offset;
          end -= offset;
        }
        if (start < 0) start = 0;
        for (let i = start; i <= end; i++) {
          items_html += this.el_slider_item[i].outerHTML;
        }
        return items_html;
      }
      _addTimeout() {
        clearTimeout(this.autoslide_timeout);
        if (!this.autoslide) return;
        if (!this.is_visible) return;
        this.autoslide_timeout = setTimeout(() => {
          if (!this.autoslide) return;
          if (!this.is_visible) return;
          this.nextSlide();
        }, this.autoslide_timeout_delay);
      }
      _animateSlide() {
        this.animated = true;
        let base_layer = this.el_slider_layer[this.current_layer];
        this.current_layer++;
        if (this.current_layer > 1) this.current_layer = 0;
        let next_layer = this.el_slider_layer[this.current_layer];
        base_layer.style.opacity = "1";
        next_layer.style.opacity = "0";
        next_layer.innerHTML = this._getItemsContent(this.current_index);
        this.el_slider_wrap.style.height = "auto";
        let wrap_height = this.el_slider_wrap.clientHeight,
          height_diff = wrap_height - next_layer.clientHeight;
        clanvi.animate(progress => {
          base_layer.style.opacity = 1 - 1 * progress;
          next_layer.style.opacity = 1 * progress;
          if (height_diff)
            this.el_slider_wrap.style.height =
              wrap_height - height_diff * progress + "px";
          if (progress === 1) {
            base_layer.setAttribute("data-next", "");
            next_layer.removeAttribute("data-next");
            this.el_slider_wrap.style.height = "auto";
            this.animated = false;
          }
        }, this.animation_duration);
      }
      setSlide(index) {
        if (this.count_items < 3) return;
        this._addTimeout();
        if (this.animated) return;
        if (index >= this.count_dots) index = 0;
        if (index < 0) index = this.count_dots - 1;
        if (index === this.current_index) return;
        this.prev_index = this.current_index;
        this.current_index = index;
        this.el_dots_group_item.forEach((el, i) => {
          index === i
            ? el.setAttribute("data-active", "")
            : el.removeAttribute("data-active");
        });
        this._instaDots();
        this._animateSlide();
      }
      nextSlide() {
        this.setSlide(this.current_index + 1);
      }
      prevSlide() {
        this.setSlide(this.current_index - 1);
      }
    },
    init: function() {
      document.querySelectorAll(".production-slider").forEach(el => {
        if (el.Slider) return;
        new this.ProductionSlider(el);
      });
    }
  },

  imgSlider: {
    ImgSlider: class {
      constructor(el) {
        el.Slider = this;
        this.slider = el;
        this.el_slider_wrap = this.slider.querySelector(".img-slider__wrap");
        this.el_slider_item = this.slider.querySelectorAll(".img-slider__item");
        this.el_slider_area = this.slider.querySelectorAll(".img-slider__area");
        this.el_catalog_detail_col_photos = document.querySelector(
          ".catalog-detail__col_photos"
        );
        this.el_previews_item = document.querySelectorAll(
          ".img-previews__item"
        );
        this.el_dots_group = this.slider.querySelector(".dots-group");
        this.el_dots_group_item = null;
        this.count_items = this.el_slider_item.length;
        this.dots_state = [];
        this.current_index = 0;
        this.prev_index = 0;
        this.touchstart_pos = 0;
        this.touchend_pos = 0;
        this._placeDots();
        if (this.count_items > 1) this._addEvents();
      }
      _addEvents() {
        this.el_slider_wrap.addEventListener(
          "touchstart",
          e => (this.touchstart_pos = clanvi.pointerEventToXY(e))
        );
        this.el_slider_wrap.addEventListener("touchend", e => {
          this.touchend_pos = clanvi.pointerEventToXY(e);
          if (Math.abs(this.touchstart_pos.x - this.touchend_pos.x) > 20) {
            this.touchstart_pos.x > this.touchend_pos.x
              ? this.nextSlide()
              : this.prevSlide();
          }
        });
        this.el_dots_group.addEventListener("click", e => {
          if (e.target.closest(".dots-group__item"))
            this.setSlide(
              Array.prototype.indexOf.call(this.el_dots_group_item, e.target)
            );
        });
        this.el_slider_area.forEach(el => {
          el.addEventListener("click", () => {
            el.classList.contains("img-slider__area_right")
              ? this.nextSlide()
              : this.prevSlide();
          });
        });
        if (this.el_catalog_detail_col_photos) {
          this.el_catalog_detail_col_photos.addEventListener("click", e => {
            if (e.target.closest(".img-previews__img"))
              this.setSlide(parseInt(e.target.getAttribute("alt"), 10));
          });
        }
      }
      _placeDots() {
        this.el_dots_group.innerHTML = "";
        this.dots_state = [];
        if (this.count_items < 2) return;
        this.el_dots_group.innerHTML = Array(this.count_items + 1).join(
          '<div class="dots-group__item"></div>'
        );
        this.el_dots_group_item = this.el_dots_group.querySelectorAll(
          ".dots-group__item"
        );
        this.el_dots_group_item[0].setAttribute("data-active", "");
        this._instaDots();
      }
      _instaDots() {
        if (this.count_items < 6) {
          this.el_dots_group_item.forEach((el, i) => {
            el.setAttribute("data-visible", "");
            el.removeAttribute("data-visible_middle");
            el.removeAttribute("data-visible_small");
          });
          return;
        }
        if (!this.dots_state.length) {
          this.el_dots_group_item.forEach((el, i) => {
            this.dots_state[i] = 0;
            el.removeAttribute("data-visible");
            el.removeAttribute("data-visible_middle");
            el.removeAttribute("data-visible_small");
            if (i < 3) {
              this.dots_state[i] = 1;
            }
            if (i < 5) {
              el.setAttribute("data-visible", "");
            }
            if (i === 3) {
              el.setAttribute("data-visible_middle", "");
            }
            if (i === 4) {
              el.setAttribute("data-visible_small", "");
            }
          });
        } else {
          if (this.dots_state[this.current_index] === 1) return;
          this.el_dots_group_item.forEach((el, i) => {
            el.removeAttribute("data-visible");
            el.removeAttribute("data-visible_middle");
            el.removeAttribute("data-visible_small");
            this.dots_state[i] = 0;
            if (this.current_index - this.prev_index > 0) {
              if (this.current_index - 4 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_small", "");
              }
              if (this.current_index - 3 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_middle", "");
              }
              if (this.current_index - 2 === i) {
                el.setAttribute("data-visible", "");
                this.dots_state[i] = 1;
              }
              if (this.current_index - 1 === i) {
                el.setAttribute("data-visible", "");
                this.dots_state[i] = 1;
              }
              if (this.current_index === i) {
                el.setAttribute("data-visible", "");
                this.dots_state[i] = 1;
              }
              if (this.current_index + 1 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_middle", "");
              }
              if (this.current_index + 2 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_small", "");
              }
            } else {
              if (this.current_index - 2 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_small", "");
              }
              if (this.current_index - 1 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_middle", "");
              }
              if (this.current_index === i) {
                el.setAttribute("data-visible", "");
                this.dots_state[i] = 1;
              }
              if (this.current_index + 1 === i) {
                el.setAttribute("data-visible", "");
                this.dots_state[i] = 1;
              }
              if (this.current_index + 2 === i) {
                el.setAttribute("data-visible", "");
                this.dots_state[i] = 1;
              }
              if (this.current_index + 3 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_middle", "");
              }
              if (this.current_index + 4 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_small", "");
              }
            }
          });
        }
      }
      setSlide(index) {
        if (this.count_items < 2) return;
        if (index >= this.count_items) index = 0;
        if (index < 0) index = this.count_items - 1;
        if (index === this.current_index) return;
        this.prev_index = this.current_index;
        this.current_index = index;
        this.el_slider_item.forEach((el, i) => {
          index === i
            ? el.setAttribute("data-active", "")
            : el.removeAttribute("data-active");
        });
        this.el_previews_item.forEach((el, i) => {
          index === i
            ? el.classList.add("active")
            : el.classList.remove("active");
        });
        this.el_dots_group_item.forEach((el, i) => {
          index === i
            ? el.setAttribute("data-active", "")
            : el.removeAttribute("data-active");
        });
        this._instaDots();
      }
      nextSlide() {
        this.setSlide(this.current_index + 1);
      }
      prevSlide() {
        this.setSlide(this.current_index - 1);
      }
      update() {
        this.el_slider_item = this.slider.querySelectorAll(".img-slider__item");
        this.el_previews_item = document.querySelectorAll(
          ".img-previews__item"
        );
        this.count_items = this.el_slider_item.length;
        this._placeDots();
        this.setSlide(0);
        clanvi.scroll.init();
        if (this.el_catalog_detail_col_photos)
          this.el_catalog_detail_col_photos.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
      }
    },
    init: function() {
      document.querySelectorAll(".img-slider").forEach(el => {
        if (el.Slider) return;
        new this.ImgSlider(el);
      });
    }
  },

  reviewsSlider: {
    ReviewsSlider: class {
      constructor(el) {
        el.Slider = this;
        this.slider = el;
        this.el_slider_wrap = this.slider.querySelector(
          ".reviews-slider__wrap"
        );
        this.el_slider_item = this.slider.querySelectorAll(
          ".reviews-slider__items .reviews-slider-item"
        );
        this.el_dots_group = this.slider.querySelector(".dots-group");
        this.el_slider_layer = this.slider.querySelectorAll(
          ".reviews-slider__layer"
        );
        this.el_dots_group_item = null;
        this.count_items = this.el_slider_item.length;
        this.count_on_viewport = {
          320: 1,
          560: 2,
          900: 3
        };
        this.count_on_screen = 0;
        this.count_dots = 0;
        this.dots_state = [];
        this.current_index = 0;
        this.prev_index = 0;
        this.current_layer = 0;
        this.animated = false;
        this.touchstart_pos = 0;
        this.touchend_pos = 0;
        this.animation_duration = 400;
        this._calcDots();
        if (this.count_items > 1) this._addEvents();
      }
      _addEvents() {
        window.addEventListener("resize", () => this._calcDots());
        this.el_slider_wrap.addEventListener(
          "touchstart",
          e => (this.touchstart_pos = clanvi.pointerEventToXY(e))
        );
        this.el_slider_wrap.addEventListener("touchend", e => {
          this.touchend_pos = clanvi.pointerEventToXY(e);
          if (
            Math.abs(this.touchstart_pos.y - this.touchend_pos.y) < 30 &&
            Math.abs(this.touchstart_pos.x - this.touchend_pos.x) > 60
          ) {
            this.touchstart_pos.x > this.touchend_pos.x
              ? this.nextSlide()
              : this.prevSlide();
          }
        });
        this.el_dots_group.addEventListener("click", e => {
          if (e.target.closest(".dots-group__item"))
            this.setSlide(
              Array.prototype.indexOf.call(this.el_dots_group_item, e.target)
            );
        });
      }
      _calcDots() {
        if (this.count_items < 2) return;
        let count_on_screen;
        for (let breakpoint in this.count_on_viewport) {
          if (window.innerWidth >= breakpoint)
            count_on_screen = this.count_on_viewport[breakpoint];
        }
        if (count_on_screen !== this.count_on_screen) {
          this.count_on_screen = count_on_screen;
          this._placeDots();
        }
      }
      _placeDots() {
        this.current_index = 0;
        this.count_dots = 0;
        this.dots_state = [];
        if (this.count_items > this.count_on_screen) {
          this.count_dots = Math.ceil(this.count_items / this.count_on_screen);
          this.el_dots_group.innerHTML = Array(this.count_dots + 1).join(
            '<div class="dots-group__item"></div>'
          );
          this.el_dots_group_item = this.el_dots_group.querySelectorAll(
            ".dots-group__item"
          );
          this.el_dots_group_item[0].setAttribute("data-active", "");
          this._instaDots();
        } else this.el_dots_group.innerHTML = "";
        if (
          this.el_slider_layer[this.current_layer].querySelectorAll(
            ".catalog-item"
          ).length !== this.count_on_screen
        )
          this._sliderRefresh();
      }
      _instaDots() {
        if (this.count_dots < 6) {
          this.el_dots_group_item.forEach((el, i) => {
            el.setAttribute("data-visible", "");
            el.removeAttribute("data-visible_middle");
            el.removeAttribute("data-visible_small");
          });
          return;
        }
        if (!this.dots_state.length) {
          this.el_dots_group_item.forEach((el, i) => {
            this.dots_state[i] = 0;
            el.removeAttribute("data-visible");
            el.removeAttribute("data-visible_middle");
            el.removeAttribute("data-visible_small");
            if (i < 3) {
              this.dots_state[i] = 1;
            }
            if (i < 5) {
              el.setAttribute("data-visible", "");
            }
            if (i === 3) {
              el.setAttribute("data-visible_middle", "");
            }
            if (i === 4) {
              el.setAttribute("data-visible_small", "");
            }
          });
        } else {
          if (this.dots_state[this.current_index] === 1) return;
          this.el_dots_group_item.forEach((el, i) => {
            el.removeAttribute("data-visible");
            el.removeAttribute("data-visible_middle");
            el.removeAttribute("data-visible_small");
            this.dots_state[i] = 0;
            if (this.current_index - this.prev_index > 0) {
              if (this.current_index - 4 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_small", "");
              }
              if (this.current_index - 3 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_middle", "");
              }
              if (this.current_index - 2 === i) {
                el.setAttribute("data-visible", "");
                this.dots_state[i] = 1;
              }
              if (this.current_index - 1 === i) {
                el.setAttribute("data-visible", "");
                this.dots_state[i] = 1;
              }
              if (this.current_index === i) {
                el.setAttribute("data-visible", "");
                this.dots_state[i] = 1;
              }
              if (this.current_index + 1 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_middle", "");
              }
              if (this.current_index + 2 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_small", "");
              }
            } else {
              if (this.current_index - 2 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_small", "");
              }
              if (this.current_index - 1 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_middle", "");
              }
              if (this.current_index === i) {
                el.setAttribute("data-visible", "");
                this.dots_state[i] = 1;
              }
              if (this.current_index + 1 === i) {
                el.setAttribute("data-visible", "");
                this.dots_state[i] = 1;
              }
              if (this.current_index + 2 === i) {
                el.setAttribute("data-visible", "");
                this.dots_state[i] = 1;
              }
              if (this.current_index + 3 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_middle", "");
              }
              if (this.current_index + 4 === i) {
                el.setAttribute("data-visible", "");
                el.setAttribute("data-visible_small", "");
              }
            }
          });
        }
      }
      _sliderRefresh() {
        this.el_slider_layer[
          this.current_layer
        ].innerHTML = this._getItemsContent(0);
      }
      _getItemsContent(index) {
        let items_html = "",
          start = this.count_on_screen * index,
          end = start + (this.count_on_screen - 1);
        if (end >= this.count_items) {
          let offset = end - (this.count_items - 1);
          start -= offset;
          end -= offset;
        }
        if (start < 0) start = 0;
        for (let i = start; i <= end; i++) {
          items_html += this.el_slider_item[i].outerHTML;
        }
        return items_html;
      }
      _animateSlide() {
        this.animated = true;
        let base_layer = this.el_slider_layer[this.current_layer];
        this.current_layer++;
        if (this.current_layer > 1) this.current_layer = 0;
        let next_layer = this.el_slider_layer[this.current_layer];
        base_layer.style.opacity = "1";
        next_layer.style.opacity = "0";
        next_layer.innerHTML = this._getItemsContent(this.current_index);
        this.el_slider_wrap.style.height = "auto";
        let wrap_height = this.el_slider_wrap.clientHeight,
          height_diff = wrap_height - next_layer.clientHeight;
        clanvi.animate(progress => {
          base_layer.style.opacity = 1 - 1 * progress;
          next_layer.style.opacity = 1 * progress;
          if (height_diff)
            this.el_slider_wrap.style.height =
              wrap_height - height_diff * progress + "px";
          if (progress === 1) {
            base_layer.setAttribute("data-next", "");
            next_layer.removeAttribute("data-next");
            this.el_slider_wrap.style.height = "auto";
            this.animated = false;
          }
        }, this.animation_duration);
      }
      setSlide(index) {
        if (this.count_items < 2) return;
        if (this.animated) return;
        if (index >= this.count_dots) index = 0;
        if (index < 0) index = this.count_dots - 1;
        if (index === this.current_index) return;
        this.prev_index = this.current_index;
        this.current_index = index;
        this.el_dots_group_item.forEach((el, i) => {
          index === i
            ? el.setAttribute("data-active", "")
            : el.removeAttribute("data-active");
        });
        this._instaDots();
        this._animateSlide();
      }
      nextSlide() {
        this.setSlide(this.current_index + 1);
      }
      prevSlide() {
        this.setSlide(this.current_index - 1);
      }
    },
    init: function() {
      document.querySelectorAll(".reviews-slider").forEach(el => {
        if (el.Slider) return;
        new this.ReviewsSlider(el);
      });
    }
  },

  circlePercent: {
    CirclePercent: class {
      constructor(el, percent) {
        el.CP = this;
        this.el = el;
        this.el_bar = this.el.querySelector(".circle-percent__bar");
        this.radius = parseInt(this.el_bar.getAttribute("r"), 10);
        this.percent = 0;
        this.animated = false;
        this.animation_duration = 1500;
        this.animation_timing = clanvi.animationTimings.makeEaseOut(
          clanvi.animationTimings.quad
        );
        this.setPercent(percent);
      }
      setPercent(percent) {
        if (this.animated) return;
        percent = percent || 0;
        if (percent > 100) percent = 100;
        if (percent < 0) percent = 0;
        let percent_diff = this.percent - percent;
        if (percent_diff) {
          this.animated = true;
          clanvi.animate(progress => {
            let quad_progress = this.animation_timing(progress);
            this.el_bar.style.strokeDashoffset =
              ((100 - (this.percent - percent_diff * quad_progress)) / 100) *
                (Math.PI * (this.radius * 2)) +
              "px";
            if (progress === 1) {
              this.percent = percent;
              this.animated = false;
            }
          }, this.animation_duration);
        } else
          this.el_bar.style.strokeDashoffset =
            ((100 - percent) / 100) * (Math.PI * (this.radius * 2)) + "px";
      }
    },
    init: function() {
      document.querySelectorAll(".circle-percent").forEach(el => {
        let percent = parseInt(el.getAttribute("data-percent"), 10);
        if (isNaN(percent)) percent = 0;
        if (el.CP) el.CP.setPercent(percent);
        else new this.CirclePercent(el, percent);
      });
    }
  },

  slideToggle: {
    init: function() {
      this.addEvents();
    },
    addEvents: function() {
      this.eventListener &&
        document
          .querySelector("body")
          .removeEventListener("change", this.eventListener);
      this.eventListener &&
        document
          .querySelector("body")
          .removeEventListener("click", this.eventListener);
      this.eventListener = e => {
        if (e.target.tagName === "INPUT" && e.type === "click") return;
        let el = e.target.closest("[data-slide_toggle]");
        if (el) {
          let target = el.getAttribute("data-slide_toggle");
          if (e.type === "change") {
            document.querySelectorAll(target).forEach(el => {
              e.target.checked ? this.show(el) : this.hide(el);
            });
          } else {
            e.preventDefault();
            document.querySelectorAll(target).forEach(el => {
              el.hasAttribute("data-show") ? this.hide(el) : this.show(el);
            });
          }
          return;
        }
        el = e.target.closest("[data-slide_down]");
        if (el) {
          let target = el.getAttribute("data-slide_down");
          if (e.type === "change") {
            if (!el.checked) return;
          } else e.preventDefault();
          document.querySelectorAll(target).forEach(el => this.show(el));
        }
        el = e.target.closest("[data-slide_up]");
        if (el) {
          let target = el.getAttribute("data-slide_up");
          if (e.type === "change") {
            if (!el.checked) return;
          } else e.preventDefault();
          document.querySelectorAll(target).forEach(el => this.hide(el));
        }
      };
      document
        .querySelector("body")
        .addEventListener("change", this.eventListener);
      document
        .querySelector("body")
        .addEventListener("click", this.eventListener);
    },
    show: function(el) {
      if (el.hasAttribute("data-show")) return;
      el.setAttribute("data-show", "");
      el.style.height = "auto";
      let height = el.clientHeight;
      el.style.height = "0";
      clanvi.animate(progress => {
        el.style.height = height * progress + "px";
        if (progress === 1) el.style.height = "auto";
      }, 300);
    },
    hide: function(el) {
      el.style.height = "auto";
      let height = el.clientHeight;
      clanvi.animate(progress => {
        el.style.height = height - height * progress + "px";
        if (progress === 1) {
          el.removeAttribute("data-show");
          let el_scroll = el.closest("[data-custom_scroll]");
          if (el_scroll) el_scroll.Scroll.update();
        }
      }, 200);
    }
  },

  showToggle: {
    init: function() {
      this.addEvents();
    },
    addEvents: function() {
      this.eventListener &&
        document
          .querySelector("body")
          .removeEventListener("change", this.eventListener);
      this.eventListener &&
        document
          .querySelector("body")
          .removeEventListener("click", this.eventListener);
      this.eventListener = e => {
        if (e.target.tagName === "INPUT" && e.type === "click") return;
        let el = e.target.closest("[data-show_toggle]");
        if (el) {
          let target = el.getAttribute("data-show_toggle");
          if (e.type === "change") {
            document.querySelectorAll(target).forEach(el => {
              e.target.checked ? this.show(el) : this.hide(el);
            });
          } else {
            e.preventDefault();
            document.querySelectorAll(target).forEach(el => {
              el.hasAttribute("data-show") ? this.hide(el) : this.show(el);
            });
          }
          return;
        }
        el = e.target.closest("[data-show_on]");
        if (el) {
          let target = el.getAttribute("data-show_on");
          if (e.type === "change") {
            if (!el.checked) return;
          } else e.preventDefault();
          document.querySelectorAll(target).forEach(el => this.show(el));
        }
        el = e.target.closest("[data-show_off]");
        if (el) {
          let target = el.getAttribute("data-show_off");
          if (e.type === "change") {
            if (!el.checked) return;
          } else e.preventDefault();
          document.querySelectorAll(target).forEach(el => this.hide(el));
        }
      };
      document
        .querySelector("body")
        .addEventListener("change", this.eventListener);
      document
        .querySelector("body")
        .addEventListener("click", this.eventListener);
    },
    show: function(el) {
      el.setAttribute("data-show", "");
    },
    hide: function(el) {
      el.removeAttribute("data-show");
    }
  },

  auction: {
    init: function(chance, callback) {
      this.el_popup_auction_result = document.querySelector(
        ".popup-auction-result"
      );
      if (!this.el_popup_auction_result) return;
      this.el_popup_auction_result.removeAttribute("data-show");
      this.el_popup_auction_spin = document.querySelector(
        ".popup-auction-spin"
      );
      this.el_popup_auction_spin.style.transform = "rotate(0deg)";
      this.chance = chance;
      this.winner = null;
      this.animation_duration = 4000;
      this.min_angle = 720;
      this.timeout_delay = 500;
      this.ready = true;
      this.callback = callback;
      this.addEvents();
      clanvi.popup.openPopup("auction");
    },
    addEvents: function() {
      this.eventListener &&
        document
          .querySelector("body")
          .removeEventListener("click", this.eventListener);
      this.eventListener = e => {
        if (e.target.closest("[data-auction_start]")) {
          e.preventDefault();
          this.startAuction();
        }
      };
      document
        .querySelector("body")
        .addEventListener("click", this.eventListener);
    },
    startAuction: function() {
      if (!this.ready) return;
      this.ready = false;
      let random = Math.round(Math.random() * 100),
        pos = 0,
        i = 0,
        angle;
      for (i; i < this.chance.length; i++) {
        pos += this.chance[i];
        this.winner = i;
        if (pos >= random) break;
      }
      angle = 360 - 60 * this.winner;
      if (!this.winner) angle = 0;
      this.animateSpin(this.min_angle + angle);
    },
    animateSpin: function(angle) {
      let animation_timing = clanvi.animationTimings.makeEaseInOut(
        clanvi.animationTimings.quad
      );
      clanvi.animate(progress => {
        let quad_progress = animation_timing(progress);
        this.el_popup_auction_spin.style.transform = `rotate(${angle *
          quad_progress}deg)`;
        if (progress === 1) {
          this.el_popup_auction_spin.style.transform = `rotate(${angle}deg)`;
          setTimeout(() => {
            this.showResult();
          }, this.timeout_delay);
        }
      }, this.animation_duration);
    },
    showResult: function() {
      this.callback(this.winner);
      this.el_popup_auction_result.setAttribute("data-show", "");
    }
  },

  scroll: {
    init: function() {
      this.scroll_option = {
        minScrollbarLength: 20
      };
      this.addScroll();
      this.addEvents();
    },
    addEvents() {
      window.addEventListener("resize", () => this.addScroll());
    },
    addScroll: function() {
      if (window.innerWidth > clanvi.device_viewport.tablet) {
        document.querySelectorAll("[data-custom_scroll]").forEach(el => {
          if (el.Scroll) return;
          el.Scroll = new PerfectScrollbar(el, this.scroll_option);
        });
      } else {
        document.querySelectorAll("[data-custom_scroll]").forEach(el => {
          if (!el.Scroll) return;
          el.Scroll.destroy();
          el.Scroll = null;
        });
        this.enableScroll();
      }
    },
    preventDefault: function(e) {
      e = e || window.event;
      e.preventDefault();
      e.returnValue = false;
    },
    enableScroll: function() {
      window.removeEventListener("DOMMouseScroll", this.preventDefault, {
        passive: false
      });
      window.removeEventListener("wheel", this.preventDefault, {
        passive: false
      });
      window.removeEventListener("mousewheel", this.preventDefault, {
        passive: false
      });
      window.removeEventListener("touchmove", this.preventDefault, {
        passive: false
      });
      document.onkeydown = null;
    },
    disableScroll: function() {
      if (window.innerWidth <= clanvi.device_viewport.tablet) return;
      window.addEventListener("DOMMouseScroll", this.preventDefault, {
        passive: false
      });
      window.addEventListener("wheel", this.preventDefault, { passive: false });
      window.addEventListener("mousewheel", this.preventDefault, {
        passive: false
      });
      window.addEventListener("touchmove", this.preventDefault, {
        passive: false
      });
      document.onkeydown = e => {
        let key = {
          37: 1,
          38: 1,
          39: 1,
          40: 1
        };
        if (key[e.keyCode]) return this.preventDefault(e);
      };
    }
  },

  animationTimings: {
    quad: function(progress) {
      return Math.pow(progress, 2);
    },
    circ: function(progress) {
      return 1 - Math.sin(Math.acos(progress));
    },
    makeEaseOut: function(timing) {
      return function(progress) {
        return 1 - timing(1 - progress);
      };
    },
    makeEaseInOut: function(timing) {
      return function(progress) {
        if (progress < 0.5) return timing(2 * progress) / 2;
        else return (2 - timing(2 * (1 - progress))) / 2;
      };
    }
  },

  animate: function(draw, duration) {
    let start = performance.now();
    return requestAnimationFrame(function animate(time) {
      let time_fraction = (time - start) / duration;
      if (time_fraction > 1) time_fraction = 1;
      if (time_fraction > 0) draw(time_fraction);
      if (time_fraction < 1) requestAnimationFrame(animate);
    });
  },

  pointerEventToXY: function(e) {
    let out = { x: 0, y: 0 };
    if (
      e.type === "touchstart" ||
      e.type === "touchmove" ||
      e.type === "touchend" ||
      e.type === "touchcancel"
    ) {
      let touch = e.touches[0] || e.changedTouches[0];
      out.x = touch.clientX;
      out.y = touch.clientY;
    }
    return out;
  }
};

export { clanvi };
