<template>
  <div>
    <div class="header-auction" v-if="promocodeHeader">
      <div class="content-layout">
        <div class="header-auction__close" @click="promocodeHeader = false"></div>
        <div class="header-auction__wrap">
          <div class="header-auction__text">{{$t('promocode.useCode')}}</div>
          <div class="header-auction__code">{{promocode.promocode_code}}</div>
          <div class="header-auction__text">{{$t('promocode.for' + promocode.type)}}. {{$t('promocode.validTo')}}</div>
          <div class="header-auction__timer">
            <countdown :time="promocodeMs">
              <template slot-scope="props">{{ props.days }}{{$t('promocode.d')}} {{ props.hours }}{{$t('promocode.h')}} {{ props.minutes }}{{$t('promocode.m')}}</template>
            </countdown>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <keep-alive>
        <header-component></header-component>
      </keep-alive>

      <float-action-button :buttons="contacts" />
      <!-- <keep-alive include="Main"> -->
        <router-view/>
      <!-- </keep-alive> -->

      <keep-alive>
        <popup-component></popup-component>
      </keep-alive>
      <keep-alive>
        <footer-component v-if="$route.name !== 'Error404'"></footer-component>
      </keep-alive>

      <loader-component></loader-component>
    </div>

    <div class="gdpr"
      v-if="hideGdpr === false">
      <div class="gdpr-close"></div>

      <div class="gdpr-content">
        {{$t('gdpr')}}

        <button class="button" @click="gdprOk">OK</button>
      </div>
    </div>

    <div class="popup-auction">
      <div class="popup__close" data-close_popup></div>
      <div class="popup-auction-spin">
        <div class="popup-auction-spin__item"><div class="popup-auction-spin__item-wrap">{{$t('promocode.FS')}}</div></div>
        <div class="popup-auction-spin__item"><div class="popup-auction-spin__item-wrap">{{$t('promocode.PD15')}}</div></div>
        <div class="popup-auction-spin__item"><div class="popup-auction-spin__item-wrap">{{$t('promocode.PD10')}}</div></div>
        <div class="popup-auction-spin__item"><div class="popup-auction-spin__item-wrap">{{$t('promocode.FS')}}</div></div>
        <div class="popup-auction-spin__item"><div class="popup-auction-spin__item-wrap">{{$t('promocode.PD15')}}</div></div>
        <div class="popup-auction-spin__item"><div class="popup-auction-spin__item-wrap">{{$t('promocode.PD10')}}</div></div>
        <div class="popup-auction-spin__line"></div>
      </div>
      <div class="popup-auction-center">
        <div class="popup-auction-center__arrow"></div>
        <div class="popup-auction-center__wrap">
          <div class="popup-auction-center__instruction">{{$t('promocode.instruction')}}</div>
          <div class="popup-auction-center__title">{{$t('promocode.auctionTitle')}}<br>Clan VI</div>
          <a class="popup-auction-center__btn-start button button_black" href="#" data-auction_start>{{$t('promocode.spinWheel')}}</a>
          <a class="popup-auction-center__cancel" href="#" data-close_popup>{{$t('promocode.noThanks')}}</a>
        </div>
      </div>
      <div class="popup-auction-result">
        <div class="popup-auction-result__wrap">
          <div class="popup-auction-result__win">{{$t('promocode.youWin')}}</div>
          <div class="popup-auction-result__title"></div>
          <div class="popup-auction-result__subtitle" v-if="promocode.type">{{$t('promocode.' + promocode.type)}}</div>
          <div class="popup-auction-result__text">{{$t('promocode.useCode')}}</div>
          <div class="popup-auction-result__code">{{promocode.promocode_code}}</div>
          <a class="popup-auction-result__btn-buy button button_black" href="#" data-close_popup>{{$t('promocode.startBuying')}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Header from '../Components/Header/Header.vue';
  import Footer from '../Components/Footer/Footer.vue';
  import Loader from '../Components/Loader/Loader.vue';
  import Popup from '../Components/Popup/Popup.vue';
  import FloatActionButton from '../Components/FloatActionButton/FloatActionButton.vue';
  import Colors from "../Stores/Colors.vue";
  import AuthStore from '../Stores/AuthStore.vue';

  export default {
    name: 'Skeleton',
    components: {
      'header-component': Header,
      'footer-component': Footer,
      'popup-component': Popup,
      'loader-component': Loader,
      'float-action-button': FloatActionButton,
    },
    data() {
      return {
        contacts: [
          {
            social: 'telegram',
            link: 'https://t.me/+79537917666',
          },
          {
            social: 'whatsApp',
            link: 'https://wapp.click/79537917666',
          }
        ],
        hideGdpr: false,
        currentLang: null,
        promocodeHeader: false,
        promocodeMs: 0,
        promocode: {
          promocode_code: null,
          valid_to: null,
          valid_from: null,
          type: null,
          used: false
        }
      };
    },
    methods: {
      gdprOk() {
        this.hideGdpr = true;
        this.$ls.set('gdpr', true);
      },
      spinWheel() {
        console.log('let spin');
        this.$ui.auction.init([25, 10, 15, 25, 10, 15], (winner) => {
          console.log(winner);
        });
      },

      getWheelStatus() {
        let cookie_wheel = this.$cookies.get('wheel');

        if(cookie_wheel) {
          if (!cookie_wheel.used) {
            this.promocode = cookie_wheel;
            this.promocodeMs = Math.abs(new Date().getTime() - new Date(this.promocode.valid_to).getTime());
            this.promocodeHeader = true;
          }
        } else {
          this.$http.get('api/spin-to-win/').then(response => {
            this.wheel = response.data.spin_to_win;

            if (this.wheel) {

              this.$ui.auction.init([25, 10, 15, 25, 10, 15], (winner) => {
                let promocode_type = null;

                switch (winner%3+1) {
                  case 1:
                    promocode_type = "FS";
                  break;

                  case 2:
                    promocode_type = "PD15";
                  break;

                  case 3:
                    promocode_type = "PD10";
                  break;
                }

                this.$http.post('api/spin-to-win-promocode/', {
                  promocode_type: promocode_type
                }).then(response => {
                  this.promocode = response.body;
                  this.promocode.type = promocode_type;
                  this.promocode.used = false;

                  this.$cookies.set('wheel', this.promocode, new Date(this.promocode.valid_to).toUTCString());
                });
              });
            }
          });
        }
      },
      getColors() {
        if (Colors.state.colors.length < 1) {
          this.$http.get('api/products/colors/').then(response => {
            Colors.commit('updateColors', response.body);
          });
        }
      },
      getPopupInfoCtx(){
        const date = this.$ls.get('date_popup');
        const time = this.$ls.get('time_popup');
        const dateNow = this.$moment().format('DD.MM.YYYY');
        const timeNow = this.$moment().format('H');
        let isNextTime = false;
        const isNextDay = date !== dateNow;

        if(isNextDay){
          isNextTime = true;
        }else if(time){
          const _time = parseInt(time);
          const _time_now = parseInt(timeNow)
          if( (_time_now - _time >= 12 ||  _time_now - _time < 0) && _time_now - _time !==0 ){
            isNextTime  = true;
          }
        }
        
        return {
          date,
          dateNow,
          time,
          timeNow,
          isNextTime,
          isNextDay,
          bottomPopup: this.$ls.get('bottom_popup')
        }
      },
      events() {
        if (!this.$ls.get('access')) {
            const { bottomPopup, isNextTime } = this.getPopupInfoCtx();
            if (bottomPopup !== 'true' && isNextTime === true) {
              window.onscroll = () => {
                const { isNextTime, bottomPopup, dateNow, timeNow } = this.getPopupInfoCtx()
                if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) {
                  if (bottomPopup !== 'true' && isNextTime === true) {
                    //this.$ls.set('bottom_popup', true);
                    this.$ls.set('date_popup', dateNow);
                    this.$ls.set('time_popup', timeNow);
                    this.$ui.popup.togglePopup('discount-img');
                  }
                }
              };

              let timeout;

              document.onmousemove = () => {
                clearTimeout(timeout);
                const { isNextTime, bottomPopup, dateNow, timeNow } = this.getPopupInfoCtx();

                if (bottomPopup !== 'true' && isNextTime === true) {
                  timeout = setTimeout(() => {
                    //this.$ls.set('bottom_popup', true);
                    this.$ls.set('date_popup', dateNow);
                    this.$ls.set('time_popup', timeNow);
                    this.$ui.popup.togglePopup('discount-img');
                  }, 30000);
                }
              };

              document.addEventListener("mouseleave", (event) => {
                if (event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) {
                  const { isNextTime, timeNow, bottomPopup, dateNow } = this.getPopupInfoCtx();
                  if (bottomPopup !== 'true' && isNextTime === true) {
                    //this.$ls.set('bottom_popup', true);
                    this.$ls.set('date_popup', dateNow);
                    this.$ui.popup.togglePopup('discount-img');
                    this.$ls.set('time_popup', timeNow);
                  }
                }
              });
            }
        }
      }
    },
    computed: {
      isUser() {
        return AuthStore.getters.auth
      }
    },
    created() {
      this.getColors();

      this.events();

      this.getWheelStatus();

      this.currentLang = this.$ls.get('lang') || 'ru';
      this.hideGdpr = this.$ls.get('gdpr') || false;

      this.$moment.locale(this.currentLang);
    }
  };
</script>
