<template>
  <div class="counter">
    <div class="counter__btn" @click="minus"><div class="counter__icon fal fa-minus"></div></div>
    <div class="counter__val">{{ value }}</div>
    <div class="counter__btn" @click="plus"><div class="counter__icon fal fa-plus"></div></div>
  </div>
</template>

<script>
  import Cart from '../../Stores/Cart.vue';
  import AuthStore from '../../Stores/AuthStore.vue';

  export default {
    name: 'UiQuantity',
    props: ['quantity', 'max', 'min', 'item', 'type', 'unlimited'],
    data() {
      return {
        currentCurrency: null,
        value: (this.quantity) ? this.quantity : ((this.min) ? this.min : 0),
        product: this.item
      };
    },
    methods: {
      plus() {
        if (this.max && !this.unlimited) {
          if (this.value + 1 <= this.max) {
            this.value++;
            this.updateItem();
            if(this.product) {
              this.setDataLayer('add');
            }
          }
        } else {
          this.value++;
          this.updateItem();
          if(this.product) {
            this.setDataLayer('add');
          }
        }
      },
      minus() {
        if (this.value - 1 >= ((this.min) ? this.min : 1)) {
          this.value--;
          this.updateItem();
          if(this.product) {
            this.setDataLayer('remove');
          }
        }
      },
      updateItem() {
        this.$emit('update:quantity', this.value);

        if (this.type !== 'favorite') {
          if (this.product) {
            let updItem = {
              product_id: parseInt(this.product.product_id),
              color_id: this.product.color_id,
              size: this.product.size,
              quantity: this.value,
              update: true
            };

            this.$http.post('api/cart/add/', updItem, {
              params: {
                with_promocode: true
              }
            }).then(response => {
              Cart.commit('updateCart', response.body);
            });
          }
        }
      },
      setDataLayer(method){

        const ecommerceProduct = {
          id: this.product.color_image_id.toString(),
          name: this.product.name,
          price: this.product.discount_price ? this.product.discount_price.toString() : this.product.price.toString(),
          quantity: this.value,
          variant: `color:${this.product.color_id}/size:${this.product.size}`
        };

        const addParameters = {
          'goods_id': ecommerceProduct.id,	// Обязательно
          'goods_price': ecommerceProduct.price * ecommerceProduct.quantity,
          'page_type': 'add_product',	// Обязательно
        }

        window.dataLayer.push({
          ecommerce: {
            currencyCode: this.currentCurrency,
            [method]: {
              products: [ecommerceProduct]
            }
          },
          ...(method === 'add' && addParameters) ,
          'event': 'pixel-mg-event',	// Обязательно
          'pixel-mg-event-category': 'Enhanced Ecommerce',	// Обязательно
          'pixel-mg-event-action': 'Adding a Product to a Shopping Cart',	// Обязательно
          'pixel-mg-event-non-interaction': 'False'
        });
      }
    },
    computed: {
      person_type () {
        return AuthStore.state.status.person_type
      }
    },
    created() {
      this.currentCurrency = this.$ls.get("currency") || "RUB";
    },
    watch: {
      'quantity'() {
        if (this.value !== this.quantity) {
          this.value = this.quantity;
        }
      }
    }
  };
</script>
