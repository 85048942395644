<template>
  <div class="clanvi-loader" v-if="visible">
    <div class="clanvi-loader-logo"></div>
  </div>
</template>

<script>
import AuthStore from "../../Stores/AuthStore.vue";

export default {
  name: "Loader",
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    if (window.location.pathname === "/") {
      document.querySelector(".clanvi-loader-logo").addEventListener(
        "animationend",
        (e) => {
          this.visible = false;
          document.querySelector("body").style.overflow = "initial";
          document.querySelector("body").style.transform = "initial";

          if (this.wholesale_access === false && this.person_type === "WB") {
            this.$ui.popup.togglePopup("wholesale-off");
          }
        },
        false
      );
    }
  },
  created() {
    if (window.location.pathname === "/") {
      this.visible = true;
      document.querySelector("body").style.overflow = "hidden";
      document.querySelector("body").style.transform = "translate3d(0, 0, 0)";
    }
  },
};
</script>
