<template>
  <div class="popup popup-get-code" @click.stop>
    <div
      class="popup__close"
      @click="
        $ui.popup.togglePopup('get-code');
        closePopup();
      "
    ></div>
    <div class="popup__wrap animate-delay" data-custom_scroll>
      <div class="popup_login--title">
        {{ $t("popup.common.personal") }}
      </div>
      <div class="popup_get-code__content">
        <div class="popup_get-code__desc">
          {{ $t("popup.getCode.enterCode") }}
        </div>
      </div>
      <CodeTyper v-model="code" />
      <div class="input__error mt-20" v-show="errorText !== null">
        {{ errorText }}
      </div>
      <button
        type="submit"
        class="button"
        :disabled="code.length !== 4"
        @click="authorize()"
      >
        {{ $t("popup.login.entry") }}
      </button>

      <div class="popup_login--footer">
        <div class="popup__text text-center mb-0">
          <a
            href="#"
            data-open_popup="recovery"
            :class="{ disactive: timer !== 0 }"
            @click.prevent="getCodeAgain()"
            >{{ $t("popup.getCode.getCodeAgain")
            }}{{
              timer !== 0
                ? `${$t("popup.getCode.after")}${timer}${$t(
                    "popup.getCode.second"
                  )})`
                : ""
            }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CodeTyper from "./CodeTyper.vue";
import { Axios } from "../../Plugins/axios.js";
import {CONFIG} from "../../config";
import {cryptoSign} from "../../Utilts/utilts";

export default {
  components: {
    CodeTyper
  },
  data: () => ({
    code: "",
    timer: 60,
    errorText: null
  }),
  methods: {
    storeTokens(access, refresh) {
      this.$ls.set("access", access);
      this.$ls.set("refresh", refresh);
    },
    async authorize() {
      try {
        const r = await Axios.post(
          `api/mobile_auth/check_code/?lang=${this.$root.$i18n.locale}`,
          {
            phone: this.$ls.get("currentPhone"),
            code: this.code
          }
        );
        if (r.status === 200 && !r.data.detail) {
          this.storeTokens(r.data.access, r.data.refresh);
          this.closePopup();
          this.$ui.popup.closePopup();
          if (this.$route.name === "Recovery") {
            this.$router.push("/");
          } else {
            window.location.reload(true);
          }
        } else if (r.status === 200 && r.data.detail) {
          this.code = "";
          // if (!!r.data.detail.match(/[0-9]/g))
          //   this.errorText = r.data.detail.match(/[0-9]/g)[0];
          this.errorText = r.data.detail;

          this.$root.$emit("codeTyper:clean");
        }
      } catch (e) {
        alert(e);
      }
    },
    closePopup() {
      this.timer = 60;
      clearInterval(this.interval);
      clearTimeout(this.timeOut);
      this.errorText = null;
    },
    async getCodeAgain() {
      if (this.timer > 0) {
        return;
      } else if (this.$ls.get("countOfAttempts") >= 3) {
        this.closePopup();
        this.$ui.popup.closePopup();
        this.$ui.popup.togglePopup("login");
      } else {
        try {
          if (r.status === 200) {
            this.timer = 60;
            if (this.$ls.get("countOfAttempts")) {
              this.$ls.set(
                "countOfAttempts",
                +this.$ls.get("countOfAttempts") + 1
              );
            } else {
              this.$ls.set("countOfAttempts", 1);
            }
            const r = await Axios.post(CONFIG.AUTH_SEND_CODE_URL, {
              phone: cryptoSign(this.$ls.get("currentPhone"))
            });
          }
        } catch (e) {
          alert(e);
        }
      }
    }
  },
  mounted() {
    this.$root.$on("codePopup:opened", () => {
      this.interval = setInterval(() => {
        if (this.timer !== 0) this.timer--;
      }, 1000);
      this.timeOut = setTimeout(() => {
        if (this.code === "") this.$ui.popup.togglePopup("login");
      }, 300000);
    });
    document.body.addEventListener("click", () => {
      this.closePopup();
    });

    document.querySelector('.popup.popup-get-code').addEventListener('keypress', function (e) {
      if (e.key === 'Enter') {
        var element = document.querySelector('.popup.popup-get-code button[type=submit]');
        if (element.disabled !== 'disabled') {
          element.click();
        }
      }
    })
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearTimeout(this.timeOut);
  }
};
</script>

<style lang="scss">
.mt-20 {
  margin-top: 20px !important;
}
</style>
