<template>
  <div
    class="catalog-item"
    v-if="item"
    :class="{ 'catalog-item_interactive': item.colors[0] && item.colors[0].id }"
  >
    <router-link :to="'/product/' + item.slug">
      <div
        v-if="
          !isMobile && !bottomSlider && typeof item.videos[0] !== 'undefined'
        "
        class="catalog-item__photo"
      >
        <video
          loop
          autoplay
          muted
          preload
          :poster="item.preview_images[0]"
          class="catalog-item__photo"
        >
          <source type="video/mp4" :src="item.videos[0].url" />
        </video>

        <div class="catalog-item-info">
          <div class="size-group">
            <div class="size-group__item" v-for="size in item.sizes">
              <div class="size-item">{{ size }}</div>
            </div>
          </div>
          <div class="color-group">
            <div class="color-group__item" v-for="color in item.colors">
              <div
                class="color-item"
                :class="{
                  'color-item-multicolor': getColorById(color) == '#FFFFFE',
                  'color-item_border': $isColorLight(getColorById(color)),
                }"
                :style="{ color: getColorById(color) }"
              >
                <router-link
                  v-if="color.slug"
                  :to="'/product/' + color.slug"
                ></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="catalog-item__photo"
        v-if="
          (!isMobile || bottomSlider) &&
          (typeof item.videos === 'undefined' ||
            typeof item.videos[0] === 'undefined')
        "
        :style="{
          backgroundImage:
            type == 'blogger' && item.recommendation
              ? 'url(' + item.recommendation.blogger_image + ')'
              : 'url(' + item.main_image + ')',
        }"
      >
        <div class="catalog-item-info">
          <div class="size-group">
            <div class="size-group__item" v-for="size in item.sizes">
              <div class="size-item">{{ size }}</div>
            </div>
          </div>
          <div class="color-group">
            <div class="color-group__item" v-for="color in item.colors">
              <div
                class="color-item"
                :class="{
                  'color-item-multicolor': getColorById(color) == '#FFFFFE',
                  'color-item_border': $isColorLight(getColorById(color)),
                }"
                :style="{ color: getColorById(color) }"
              >
                <router-link
                  v-if="color.slug"
                  :to="'/product/' + color.slug"
                ></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isMobile && !bottomSlider">
        <div class="catalog-item__photo" v-if="product.videos[0]">
          <video
            loop
            autoplay
            muted
            preload
            playsinline
            :poster="item.preview_images[0]"
            class="catalog-item__photo"
          >
            <source type="video/mp4" :src="item.videos[0].url" />
          </video>
        </div>
        <slick ref="slick" :options="slickOptions" v-if="!product.videos[0]">
          <div v-for="photo in product.preview_images">
            <div
              class="catalog-item__photo"
              :style="{
                backgroundImage: 'url(' + photo + ')',
              }"
            />
          </div>
        </slick>
      </div>
    </router-link>

    <div
      class="catalog-item__recommends"
      v-if="type == 'blogger' && item.recommendation"
    >
      <a :href="item.recommendation.blogger_link" target="_blank"
        >@{{ item.recommendation.blogger_name }}</a
      >
      {{ $t("product.blogger.recommend") }}
    </div>
    <div
      class="catalog-item__subscribers"
      v-if="type == 'blogger' && item.recommendation"
    >
      {{ item.recommendation.subscribers }}
      {{ $t("product.blogger.subscribers") }}
    </div>

    <div
      class="catalog-item__cost-group"
      v-if="item.discount_price && type != 'blogger'"
    >
      <div
        class="product-cost"
        :class="{ priority_discount: item.priority_discount }"
      >
        {{ dividingNumberDigits(item.discount_price) }} {{ getSymbol() }}
      </div>
      <div class="product-cost-old">{{ dividingNumberDigits(item.price) }} {{ getSymbol() }}</div>
    </div>

    <div
      class="catalog-item__cost-group"
      v-if="!item.discount_price && type != 'blogger'"
    >
      <div class="product-cost product-cost-current">
        {{ dividingNumberDigits(item.price) }} {{ getSymbol() }}
      </div>
    </div>

    <div class="catalog-item__name" v-if="type != 'blogger'">
      <div class="catalog-item-priority" v-if="item.priority_discount">
        PRIORITY
      </div>
      <router-link :to="'/product/' + item.slug">{{ item.name }}</router-link>
    </div>

    <div class="favorites">
      <div
        class="icon-favorites"
        :class="{ 'icon-favorites_active': favorite }"
        @click="toggleFavorite"
      >
        <div class="icon-favorites__icon">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M22.1412 6.13985C20.4205 3.12187 16.6459 2.1019 13.7011 3.86938C13.0482 4.2606 12.4701 4.77058 12.0008 5.37837C9.87205 2.64681 5.98863 2.1997 3.3226 4.39333C2.7309 4.87537 2.23442 5.46919 1.85356 6.13985H1.86036C0.0240669 9.35345 1.35708 12.3016 3.69666 15.1659L3.67625 15.1729C5.8322 17.7018 8.3282 19.9094 11.0758 21.7328C11.6403 22.0891 12.3544 22.0891 12.9189 21.7328C15.6734 19.9094 18.1626 17.7018 20.3185 15.1729L20.2981 15.1659C22.6241 12.3295 23.9843 9.36742 22.1412 6.13985Z"
              stroke="#9F9D9D"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slick from "vue-slick";
import { isMobile } from "../../Utilts/utilts.js";
import FavoriteStore from "../../Stores/Favorite.vue";
import { dividingNumberDigits as dividingNumberDigitsUtils } from "../../Utilts/utilts";

export default {
  name: "ProductCard",
  components: { Slick },
  props: ["item", "colors", "type", "bottomSlider"],
  data() {
    return {
      currentCurrency: "RUB",
      favorite: false,
      slickOptions: {
        slidesToShow: 1,
        arrows: false,
      },
    };
  },
  computed: {
    favorites() {
      return FavoriteStore.state.favorite;
    },
    isMobile() {
      return isMobile();
    },

    product: {
      get() {
        return this.item;
      },
      set(v) {
        if (this.isMobile) {
          console.log("set");
          this.reInit();
          return v;
        }
      },
    },
  },

  watch: {
    item(v) {
      if (v) {
        return (this.product = v);
      }
    },
    // favorites(newVall) {
    //   if (newVall) {
    //     this.checkFavorite(this.item.slug)
    //   }
    // },
  },
  mounted() {
    this.checkFavorite(this.item.slug);
  },
  methods: {
    dividingNumberDigits(price) {
      return dividingNumberDigitsUtils(price)
    },
    checkFavorite(slug) {
      if (!this.favorites.length) return;
      if (this.favorites.some((item) => item.slug === slug)) {
        this.favorite = true;
      } else {
        this.favorite = false;
      }
    },
    reInit() {
      this.$nextTick(() => {
        if (this.$refs.slick) {
          this.$refs.slick.reSlick();
        }
      });
    },

    getColorById(id) {
      id = id.id || id;
      let color = null;

      if (this.colors) {
        if (this.colors.colors) {
          for (let i = 0; i < this.colors.colors.length; i++) {
            if (id == this.colors.colors[i].id) {
              color = this.colors.colors[i].color_code;
            }
          }
        }
      }

      return color;
    },
    getSymbol() {
      let symbol = null;

      switch (this.currentCurrency) {
        case "RUB":
          symbol = "₽";
          break;

        case "USD":
          symbol = "$";
          break;

        case "EUR":
          symbol = "€";
          break;
      }

      return symbol;
    },
    toggleFavorite() {
      if (this.favorite) {
        this.removeFavorite();
        this.favorite = false;
      } else {
        this.addFavorite();
        this.favorite = true;
        if (window.VK) {
          let _tmr = window._tmr || (window._tmr = []);
          _tmr.push({
            type: "reachGoal",
            id: VK_PIXEL_ID,
            value: this.item.price,
            goal: "addToWishlist",
            params: { product_id: `${this.item.id}` },
          });
        }
      }

      this.item.favorites = !this.item.favorites;
    },
    addFavorite() {
      const item = {
        product_id: this.item.id,
        color_id: this.item.colors[0].id,
        size: this.item.sizes[0],
      };

      this.$http.post("api/favorites/add/", item).then((response) => {
        FavoriteStore.commit("updateFavorite", response.body.products);
      });
    },
    removeFavorite() {
      const item = {
        product_id: this.item.id,
        color_id: this.item.colors[0].id,
        size: this.item.sizes[0],
      };

      this.$http
        .delete("api/favorites/remove/", {
          body: item,
        })
        .then((response) => {
          FavoriteStore.commit("updateFavorite", response.body.products);
        });
    },
  },
  created() {
    this.currentCurrency = this.$ls.get("currency") || "RUB";
  },
};
</script>

<style lang="scss" scoped>
.catalog-item {
  position: relative;
  .favorites {
    position: absolute;
    top: 15px;
    right: 15px;
    .icon-favorites {
      position: relative;
      width: 24px;
      height: 24px;

      @media (max-width: 665.98px) {
        width: 16px;
        height: 16px;
      }

      &:hover {
        opacity: 0.6;
      }

      .icon-favorites__icon {
        color: #d3d3d3;
        width: 24px;
        height: 24px;
        top: 0;
        left: 0;
        transform: none;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 665.98px) {
          width: 16px;
          height: 16px;
        }

        svg {
          vertical-align: baseline;
        }
      }
    }

    .icon-favorites_active {
      path {
        stroke: #efb9c3;
        fill: #efb9c3;
      }
    }
  }
}
</style>
