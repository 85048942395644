<script>
  import Vue from 'vue';
  import Vuex from 'vuex';

  Vue.use(Vuex);

  export default new Vuex.Store({
    state: {
      categories: [],
      categoryColors: null
    },
    mutations: {
      updateCategoryColors(state, data) {
        state.categoryColors = data;
      }
    }
  });
</script>
