<script>
  import Vue from 'vue';
  import Vuex from 'vuex';

  Vue.use(Vuex);

  export default new Vuex.Store({
    state: {
      favorite: []
    },
    mutations: {
      updateFavorite(state, array) {
        state.favorite = array;
      }
    }
  });
</script>
