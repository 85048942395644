<template>
  <div class="header-dropdown"
       :class="{'header-dropdown_currency': type === 'header' && !tokenCurrency}"
       :data-toggle_popup="(type === 'header'  && !tokenCurrency) ? 'currency' : null">
    <div class="header-dropdown__wrap">
      <div class="header-dropdown__text" v-if="tokenCurrency">{{tokenCurrency}}</div>
      <div class="header-dropdown__text" v-if="!tokenCurrency">{{currentCurrency}}</div>
      <div class="header-dropdown__arrow fal fa-angle-down" v-if="!tokenCurrency"></div>
      <select v-if="type !== 'header' && !tokenCurrency"
              @change="switchCurrency"
              v-model="currency">
        <option v-for="item in currencies"
                :value="item">
          {{item}}
        </option>
      </select>
    </div>
    <div class="header-popup" v-if="type === 'header' && !tokenCurrency">
      <ul class="header-popup-list list-unstyled">
        <li class="animate-delay" v-for="item in currencies" :class="{inactive: currentCurrency == item}"
            @click="(currentCurrency == item) ? null : switchCurrency(item)">
          <div>{{item}}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import AuthStore from '../../Stores/AuthStore.vue';

  export default {
    name: 'CurrencySwitcher',
    props: ['type', 'current'],
    data() {
      return {
        currentCurrency: this.current,
        currency: null,
        currencies: ['RUB', 'USD', 'EUR']
      };
    },
    methods: {
      switchCurrency(currency) {
        if(this.type !== 'header') {
          currency = this.currency;
        }

        this.$ls.set('currency', currency);

        const route = this.$route.name;

        window.history.go();
      }
    },
    computed: {
      tokenCurrency () {
        return AuthStore.state.status.currency
      },
    },
    created() {
      if (this.type !== 'header') {
        this.currency = this.currentCurrency;
      }
    }
  };
</script>
