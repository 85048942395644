<template>
  <div class="stories-list" v-if="isMobile">
    <div id="stories" class="storiesWrapper"></div>

    <!-- <div v-show="showProducts">
      <div class="product-card" v-for="(item, index) in changeStories.items[storiesIndex].products" :key="index" :style="{top: storiesHeight + 'px'}">
        <router-link :to="'/product/' + item.slug"
                    style="text-decoration: none">
          <div class="product-info">
            <slick ref="slick" :options="slickOptions">
              <div v-for="photo in item.preview_images">
                <img
                  class="product-img"
                  :src="photo"
                  alt=""
                />
              </div>
            </slick>
            <p class="product-price">{{ item.price }} ₽</p>
            <p class="product-name">{{ item.name }}</p>
          </div>
        </router-link>
      </div>
    </div> -->
  </div>
</template>

<script>
import Zuck from "./zuck.js";
import "./zuck.css";
import ProductCard from "../../Components/UI/ProductCard.vue";
import { isMobile } from "../../Utilts/utilts.js";
import Slick from "vue-slick";

export default {
  name: "stories",
  components: {
    ProductCard,
    Slick,
  },
  data() {
    return {
      showProducts: false,
      storiesList: [],
      changeStories: [],
      slickOptions: {
        slidesToShow: 1,
        arrows: false,
      },
      toggleProduct: false,
    };
  },
  created() {},
  mounted() {
    window.addEventListener("load", () => {
      this.getStories();
    });
  },
  watch: {
    storiesList: function () {
      var t = this;
      if (this.isMobile) {
        t.changeStories = new Zuck("stories", {
          skin: "snapgram",
          // container class
          avatars: true,
          // shows user photo instead of last story item preview
          list: false,
          // displays a timeline instead of carousel
          openEffect: false,
          // enables effect when opening story
          cubeEffect: true,
          // enables the 3d cube effect when sliding story
          autoFullScreen: false,
          // enables fullscreen on mobile browsers
          backButton: false,
          // adds a back button to close the story viewer
          backNative: true,
          // uses window history to enable back button on browsers/android
          previousTap: true,
          // use 1/3 of the screen to navigate to previous item when tap the story
          localStorage: true,
          // set true to save "seen" position. Element must have a id to save properly.
          stories: this.storiesList,
        });
      }
    },
  },
  methods: {
    getStories() {
      this.$http
        .get("api/stories/")
        .then((res) => res.json())
        .then((res) => {
          this.storiesList = res;
          // console.log(res)
        });
    },
  },
  computed: {
    isMobile() {
      return isMobile();
    },
  },
};
</script>
<style lang="scss">
.storiesWrapper {
  padding: 0 10px;
}
.stories-list {
  width: 100vw;
  padding: 0 0 5px 0;
  overflow: hidden;
}

.product-card-container-visible-y {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 2000;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: fixed;
  bottom: 0;
  transform: translate3d(0, 0, 200px);
  // height: 460px;
  height: 100vh;
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.product-wrap {
  position: absolute;
  bottom: 0;
  height: 375px;
  min-width: 100%;
  display: flex;
  padding: 0 16px 79px;
  background-color: #fff;
  box-shadow: 0 -10px 25px rgba(150, 150, 150, 0.3);
  & * {
    position: relative;
    z-index: 100100;
    transform: translate3d(0, 0, 200px);
  }
  .product-card {
    margin-right: 16px;
    width: 145px;
    position: relative;
    top: -110px;
    z-index: 2000;
    transform: translate3d(0, 0, 200px);

    &:last-child {
      margin-right: 0;
    }

    .product-img {
      width: 145px;
      height: 220px;
    }

    .product-price {
      margin-top: 12px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
    }

    .product-name {
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #322f2b;
    }
  }
}

.product-card-on {
  visibility: visible !important;
  opacity: 1 !important;
  height: 285px !important;
  transition: all ease 0.5s;
}

.product-card-off {
  visibility: hidden !important;
  opacity: 0 !important;
  height: 0 !important;
  padding: 0;
  transition: all ease 0.5s;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: none !important;
  box-shadow: none !important;
}

.product-link-toggle {
  position: fixed;
  bottom: 5px;
  width: 100%;
  text-align: center;
  z-index: 2000;
  transform: translate3d(0, 0, 200px);
  text-decoration: none !important;
  span {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      margin: 4px 0;
    }
  }
  > * {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  &__on {
    color: #fff;
    padding-bottom: 5px;
  }

  &__off {
    color: #333;
  }
}

.story-viewer .head .right .close {
  opacity: 1 !important;
  &:before,
  &:after {
    content: "";
    right: 23px;
    top: 35px;
    position: absolute;
    height: 3px;
    width: 26px;
    background: #fff;
    pointer-events: none;
  }
  &:before {
    content: "";
    transform: rotate(45deg);
  }
  &:after {
    content: "";
    transform: rotate(-45deg);
  }
}
.story-viewer .slides-pointers > * > * {
  box-shadow: 0px 0px 2px rgb(185, 185, 185);
  background: rgba(226, 226, 226, 0.8) !important;
}
.story-viewer .slides-pointers > * > .seen {
  background: #fff !important;
}
.stories.snapgram .story.seen > a > .item-preview {
  padding: 1px;
  background-color: #c3c3c3;
}
.stories.snapgram .story.seen > a > .item-preview img {
  border: 3px solid #fff;
}
.stories.snapgram .story > .item-link > .item-preview {
  padding: 3px;
  border: 1px solid #9F9D9D;
  background: #F0EEEF;

}
.stories.snapgram .story > .item-link > .item-preview img {
  border-radius: 50%;
  border: none;
}
.stories.carousel .story > .item-link > .info .name {
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}
</style>
