<script>
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cart: {
      products: []
    }
  },
  mutations: {
    updateCart(state, data) {
      state.cart = data;
    }
  },
});
</script>
