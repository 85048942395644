const localeEn = {
  gdpr: "BY CONTINUING USE THIS SITE, YOU AGREE TO THE RULES FOR THE USE OF COOKIES.",
  promocode: {
    youWin: "You win",
    useCode: "Use the code",
    startBuying: "Start shopping",
    spinWheel: "Spin wheel",
    noThanks: "No, thanks",
    auctionTitle: "Auction",
    instruction: "Spin to win",
    PD15: "Discount 15%",
    PD10: "Discount 10%",
    FS: "Free shipping",
    forPD15: "for 15% discount",
    forPD10: "for 10% discount",
    forFS: "for free shipping",
    validTo: "Valid to",
    d: "d",
    h: "h",
    m: "m",
  },
  header: {
    basket: {
      title: "Cart",
      size: "Size",
      color: "Colour",
      quantity: "Quantity",
      sum: "Total",
      withoutDel: "Excluding shipping",
      buttonText: "Go to cart",
      buttonAdd: "Add to cart",
    },
    favorites: {
      title: "Favourites",
    },
    links: {
      wholesale: "Wholesale",
      personal: "Personal account",
      logIn: "Sign in",
      lang: "Russian",
    },
    hotline: "Hotline phone number",
  },
  footer: {
    copyright: "2015-2024 CLANVI.COM",
    phoneText: "Customer care phone",
    links: {
      contacts: "Contacts",
      refund: "Refund",
      reviews: "Feedback",
      paymentDelivery: "Payment and Shipping",
      about: "About company",
      aboutUs: "About us",
      wholesale: "Wholesale",
      buyers: "To buyers",
      packaging: "Packaging",
      priorityClub: "Priority Club",
      privacyPolicy: "Privacy policy",
      myCabinet: "My cabinet",
      basket: "Cart",
      orders: "My orders",
    },
    subscribeForm: {
      title: "Actual promotions and discounts",
      inputPlaceholder: "Enter your E-Mail",
      submit: "Subscribe",
      success: "Thanks",
    },
  },
  category: {
    all: "All products",
    clothes: "Clothes",
    mobileCategoryTitle: "Categories",
    parametres: {
      size: "Size",
      sizeClothes: "Clothes",
      sizeShoes: "Shoes",
      sizeGloves: "Gloves",
      price: "Price",
      color: "Colour",
    },
    buttons: {
      reset: "Reset",
      sort: "Sort",
      apply: "Apply",
    },
    sort: {
      popular: "By availability",
      price: "By price",
    },
    filter: "Filter",
    empty:
      "Unfortunately, nothing was found on your results. Try changing your search settings 💜",
  },
  popup: {
    wholesaleOff: {
      title: "title",
      text: "Dear partners!<br>Due to&nbsp;the high workload of&nbsp;production and the ever-growing demand for the goods of&nbsp;the CLAN VI&nbsp;brand , the possibility of&nbsp;wholesale orders on&nbsp;a&nbsp;regular basis is&nbsp;unavailable now. You can check the regular dates for wholesale orders with your manager:<br> 7 (913) 908-49-94 Anastasia<br>7 (913) 451-22-96 Marina",
    },
    registered: {
      title: "Account activated",
      text: "Now you can sign in into your account",
      link: "Sign in",
    },
    notifySuccess: {
      title: "Thanks!",
      text: "We'll notify you when the product becomes available",
    },
    emailSuccess: {
      title: "Thanks!",
      text: "We just sent you an email with the promocode",
    },
    emailFail: {
      title: "Oops",
      text: "Something went wrong",
    },
    apiDown: {
      title: "Something went wrong",
      text: "Server is currently not available. Try again later!",
      refresh: "Refresh page",
    },
    wholesaleOrder: {
      rub: "The total amount must be at least 50,000 RUB",
      eur: "The total amount must be at least $ 600",
      usd: "The total amount must be at least 530 €",
    },
    passwordRecover: {
      text: "Confirmation email has been sent to your E-mail",
      title2: "Successful",
      text2: "Your password was updated",
      title3: "Something went wrong",
      text3: "Unable to update password",
    },
    feedback: {
      title: "Success",
      text: "Thanks for your feedback!",
    },
    login: {
      retailTitle: "Retail",
      wholesaleTitle: "Wholesale",
      afterAttempts:
        "If you have problems with authorization by phone number, please call the hotline:",
      title: "Login or registration",
      phone: "Phone",
      email: "E-mail",
      entrySocial: "Sign in via social network",
      haveAccount: "Don't have an account yet?",
      forgetPass: "Forgot password?",
      enterPhone: "Enter your phone number",
      enterMail: "Enter your your mail and password",
      phoneErrors: [
        "Phone number must contain 11 digits",
        "Phone number must contain 12 digits",
      ],
      loginError: "Retries exceeded, please try again later",
      wholesale:
        "To get to the wholesale section, please enter your email address",
      getCode: "Get the code",
      entry: "Sign in",
    },
    getCode: {
      enterCode: "Enter code from SMS",
      getCodeAgain: "Get the code again ",
      after: "(after ",
      invalidCode: "Invalid SMS code",
      attempts: "attempts to enter left",
      request: "request a new authorization code",
      second: "s",
    },
    phoneVerify: "Phone number not verified",
    registration: {
      registry: "Sign up",
      opt: {
        shop: "Shop",
        have: "Yes",
        no: "No",
        fillDetails: "Fill in the details",
        stockCompany: "ООО",
        enterp: "Individual entrepreneur",
      },
    },
    recovery: {
      recoveryPass: "Password recovery",
      button: "Recover",
    },
    discount: {
      purchase: "Purchase discount",
      enterMail:
        "Enter your email and get {discount}% discount on your first purchase!",
      button: "Get a discount",
    },
    sizes: {
      table: "Size table",
      chest: "Chest girth",
      waist: "Waist girth",
      hips: "Hips girth",
      size: "Size",
      length: "Insole length, mm",
      text: "We use an universal European size system: XS, XS-S, S, M, M-L и L. Choosing the size, pay attention to cut and material. Knitted clothes stretch easier than clothes made of thick fabrics. Choosing fitted outfits, consider the height of the belt.",
    },
    invoicing: {
      title: "Billing",
      text: "Our Manager will contact you to issue an invoice ",
    },
    success: {
      title: "Your order has been paid successfully",
      text: "Thanks for buying in our shop",
    },
    afterRegister: {
      title: "Your account is registered",
      text: "The confirmation email has been sent.",
    },
    authorizationFail: {
      title: "Something went wrong",
      text: "Unable to sign in through the social network",
    },
    paymentFail: {
      title: "Something went wrong",
      text: "Repeat the payment or contact our Manager",
    },
    checkoutStockroom: {
      title: "Select warehouse",
      text: "If you prefer delivery with CDEK to warehouse, please, choose a warehouse!",
    },
    checkoutFail: {
      title: "Something went wrong",
      text: "Contact our Manager",
    },
    review: {
      title: "Write a review",
      button: "Send",
    },
    notify: {
      title: "To announce the receipt",
      text: "Leave your email and we will inform you about the receipt of your order.",
    },
    common: {
      personal: "Personal account",
      entry: "Sign in",
      registry: "Sign up",
      buttonMain: "Home",
      buttonSend: "Send",
    },
    successEdit: {
      title: "Successful!",
      link: "Back to cabinet",
    },
    failEdit: {
      title: "Something went wrong",
      text: "Try later",
      link: "Back to cabinet",
    },
    placeholder: {
      search: "Search",
      birthdate: "Birthdate",
      email: "E-mail",
      yourEmail: "Your e-mail address",
      oldPassword: "Old password",
      password: "Password",
      passwordAgain: "Repeat password",
      number: "Phone number",
      city: "City",
      linkShop: "Shop link",
      fullName: "Full name",
      inn: "TIN (Individual taxpayer Identification number)",
      kpp: "KPP (tax registration reason code)",
      okpo: "OCPO (all-Russian classifier of enterprises and organizations) ",
      shortLegal: "Abbreviated name of legal entity",
      message: "Message",
    },
    chooseCountry: {
      title: "Attention please!",
      text: "We kindly ask you to read the information about customs taxes and fees in your country. In the products price on the website clanvi.com possible duties, taxes and additional charges are not included in the customs declaration, as they are paid separately by the recipient.",
    },
    deleteProfile: {
      title: "DELETE PERSONAL ACCOUNT",
      text: "You are going to delete your Personal Account in the CLAN IV online store.",
      text_attention:
        "Deleting a Personal Account will delete all of its contents and all associated data beyond recovery.",
      button_cancel: "Cancel",
      button_delete: "Delete Profile",
    },
    chooseBasket: {
      title: "Changing the list of items in the shopping cart",
      text: "After authorization, the list of products in your shopping cart has changed. Restore the previous one?",
      button_restore: "Restore",
      button_leave: "Keep the current",
    },
  },
  profile: {
    title: "Profile edit",
    save: "Save",
    changePass: "Edit password",
    savePass: "Change password",
    deleteProfile: "Delete profile",
  },
  cabinet: {
    wholesale: {
      title: "Welcome to the personal wholesale account!",
      description:
        "The CLAN VI affiliate program gives you a one-time 5% discount on your first order with a promo code. Make orders and get additional bonuses on the purchasing status and discounts on of the order amount at each stage of cooperation:",
      l1: "Over $1000 - 5%",
      l2: "Over $1200 - 6%",
      l3: "Over  $1400 - 7%",
      l4: "Over  $1600 - 8%",
      l5: "Over  $1800 - 9%",
      l6: "Over  $2000 - 10%",
      l7: "The minimal wholesale order amount is $900",
      l8: "SILVER - over $600.",
      l81: "The basic status of a wholesale customer. The wholesale buyer status confirmation for at least $600 per month.",
      l9: "GOLD - over $10.000.",
      l91: "The increased wholesale customer status. Free delivery to the warehouse (CDEK, DPD). The wholesale buyer status confirmation for at least $1200 per month.",
      l10: "PLATINUM - over $20.000",
      l101: "The highest priority status of the wholesale customer. Free shipping any way. Confirmation of the status not less than $2000 per month.",
      l11: "You need to confirm the status every 45 calendar days from the date of the last order. Otherwise, the total amount of orders is reduced by the amount of confirmation every 45 calendar days.",
      up: "The increasing buyer status system:",
    },
    title: "Personal account",
    edit: "Edit profile",
    addresses: "Delivery addresses",
    orders: "My orders",
    logout: "Log out",
    saleOrder: "Order amount discount",
    status: {
      current: {
        title: "Your current buyer status",
        desc: "One-time 5% discount for registration in the Clan VI affiliate program",
      },
      next: {
        title: "Your next buyer status",
        desc: "Free shipping to the warehouse of transport company in Novosibirsk.",
      },
      close: {
        title: "Priority Club",
        desc: "One-time 5% discount for registration in the Clan VI affiliate program",
      },
      priority: {
        declinedTitle: "Don’t waste your time!",
        declined:
          "Stay with us, make purchases for a total of |replaceThis| RUB and you will get access to the secret category with discounts and special offers only for the CLAN VI Priority club members!",
        access:
          "You have access to the secret category with discounts and special offers!",
        accessTitle: "You’ve joined the CLAN VI Priority!",
      },
    },
    review: {
      title: "Leave a review",
      text: "This will provide you with quality service",
      button: "Leave a review",
      placeholder: "Leave a message",
    },
    help: {
      title: "Need help?",
      text: "Wholesale order Manager",
    },
  },
  contacts: {
    title: "Our addresses",
    clock: "Daily from 12:00 до 20:00",
    button: "Leave a review",
    nsk: {
      adress: "Russia, Novosibirsk, Romanova street,house 25 shop CLAN VI",
    },
    msk: {
      adress:
        "Russia, Moscow, Embankment of academician Tupolev street, house 15 (RC «Cascade») shop CLAN VI",
    },
  },
  main: {
    title: "Online women's clothing store",
    toCatalog: "To catalog",
    showAll: "Show all",
    collectionTitle: "Base collection",
    collectionText: "Something is trendy, but something is eternal",
    instaTitle: "You & CLAN VI",
    instaText: "Tag @clan6 for a chance to be featured",
  },
  product: {
    blogger: {
      recommend: "recommends this model",
      subscribers: "followers",
    },
    titleFor: " for ",
    breadcrumbs: {
      home: "Home",
      catalog: "Catalog",
    },
    parametres: {
      size: "Size",
      color: "Colour",
      count: "Count",
    },
    info: {
      desc: "Description",
      specific: "Details",
    },
    discount: {
      title: "Discount",
      text: "On the first order by promo code",
    },
    addBasket: {
      continue: "Continue shopping",
      checkout: "Place your order",
    },
    detail: "Show more",
    recentlyView: "Recently viewed",
    relatedProducts: "Add an image ",
    button: "Add to cart",
    sizeTable: "Size guide",
    reportOfStock: "Remind me",
    available: "In stock",
    availableCity: {
      nsk: "Novosibirsk",
      msk: "Moscow",
      spb: "Saint Petersburg",
      krd: "Kransnodar",
      ekb: "Ekaterinburg",
    },
    notInStock: "not available",
    notAvailable: "not available",
    unit: "i.",
    call: "Call the store",
    address: "CLAN VI store on",
    addressCity: {
      nsk: "Romanova 25, Novosibirsk",
      msk: "Bolshaya Polyanka 4/10, Moscow",
      spb: "Karpovka river embankment 10, Saint Petersburg",
      krd: "65 Oktyabr’skaya street",
      ekb: "st. Radishcheva, 27",
    },
    newTitle: "Choose a store",
  },
  page404: {
    title: "Page not found",
    text: "Sorry, the page you requested cannot be found.",
    links: {
      main: "Back to main page",
      catalog: "Go to catalog",
    },
  },
  basket: {
    title: "Cart",
    sum: "Total",
    button: "Place your order",
    info: {
      title: "Excluding shipping",
      text: "Refund and exchange within 7 days",
      text2: "Order production takes 7-10 days",
    },
    empty:
      'There\'s nothing in your cart right now. Choose something from our <a href="/catalog/new-collection">new collection</a> ❤️',
    outOfStock: "Out of stock",
    freeBox: "Gift wrapping",
    freeBoxYes: "Your order will be Packed in a gift box for free",
    freeBoxNo:
      "Unfortunately, Your order does not include a free gift box. Order a branded box for an additional 20$ or add at least one product worth at least 100$ to get the package for free",
    freeBag: "Gift bag",
    freeBagYes: "Your order will be shipped in a gift satin pouch!",
    freeBagNo:
      "Unfortunately, the goods from this section do not provide for receiving an order in a gift box, but we offer a nice bonus: you can add a satin bag for 6$ to your order.",
    shoesBox: "Gift package",
    shoesBoxText: "Your order will be shipped in a branded box",
    noBoxTitle: "Add a gift box!",
    noBoxText:
      "Orders in the branded box CLAN VI come with the purchase of 2 goods, not including the section “Hot offers”. You can add the gift box for 20$ ",
    orderBtn: "Place the order",
    noBoxAdd: "Add packaging",
    noBagTitle: "Get Your order in a gift bag",
    noBagText:
      "Unfortunately, the goods from the section “Hot offers” does not provide for gift wrapping. But we offer a nice bonus: You can add a satin pouch for 6$",
    noBagAdd: "Add a satin pouch",
    sunSale:
      'P.S. Gift packaging does not apply to products from the category "Sun SALE".',
    addProducts: {
      t1: "Add the cart for",
      t2: " to get free shipping!",
    },
  },
  favorites: {
    empty: "Add something to favorites ❤️",
  },
  checkout: {
    credit: {
      buy: "Buy for",
      currency: "₽/month",
    },
    packagingDelay: {
      title: "PAY ATTENTION, PLEASE!",
      text: 'Due to the high demand for the brand\'s products, the term of sewing and delivery of your order will be from 2 to 3 weeks after payment. By clicking "OK", You agree to the waiting period for the order.',
    },
    errors: {
      country: "Select country from dropdown",
      city: "Select city from dropdown",
    },
    title: "Order registration",
    textRegister: "Are you already registered?",
    textFirst: "Is this your first time here?",
    cost: "Cost of shipping",
    sum: "Total",
    sumLoyalty: "Loyalty program discount",
    rubleNotify:
      "You will be shown a total in Russian rubles in the payment system. Do not worry, you will be debited an amount approximately equal to {sum} at the exchange rate of your Bank",
    fillFields: "All fields are required!",
    deliveryPoint: "Choose a pickup point!",
    sumText: "With discount and shipping",
    popoverFreeShipping: [
      "Add up to ",
      " to your shopping cart and get DHL Express delivery in one or two days for free",
    ],
    registration: {
      title: "Personal data",
      placeholder: {
        fullName: "Name, Surname and Lastname",
        email: "E-mail",
        number: "Phone number",
      },
    },
    delivery: {
      title: "Shipping address",
      sale: "Discount",
      saving: "Saving",
      placeholder: {
        country: "Country",
        city: "City",
        address: "Address",
        comment: "Сomment to the order",
        index: "Post index",
      },
      logistic: {
        title: "Delivery terms",
        count: "Total",
        with: "With discount and shipping",
        select: {
          sdStock: "CDEK to warehouse",
          sdDoor: "CDEK door-to-door",
          dpdDoor: "DPD door-to-door",
          dpdStockroom: "DPD to warehouse",
          dhlDoor: "DHL door-to-door",
          postRuss: "Russian post (parcel)",
          emsPostRuss: "EMS Russian Post",
          day: "0 days | 1 day | {n} days",
          sale: ["When your order is more than", ", you get free shipping!"],
          enterIndex:
            "To calculate the cost of shipping by Russian Post, EMS, DPD and DHL, enter your index",
          enterStock: "Choose a warehouse",
          collectOrder:
            "Collection period for your order can be up to 7-8 working days.",
          deliveryDays:
            "*Delivery term is specified in working days. This period does not include the manufacture of products and packaging of the order before shipment.",
        },
      },
      check: {
        title: "Payment",
        inform: "Inform me about new collections",
        agree:
          'I give my consent to the processing of personal data and agree to the terms and <a href="/privacy/" target="_blank">privacy policy and return terms</a>',
      },
    },
    promocode: {
      text: "Enter promocode",
      submit: "Submit",
      placeholder: "Discount promocode",
      name: "Promocode",
      delete: "Delete",
    },
    buttons: {
      enter: "Sign in",
      payCard: "Pay",
      payQiwi: "Pay by Qiwi",
      score: "Bill",
      textFirst: "First time with us?",
    },
  },
  myOrders: {
    title: "My orders",
    notOrder: "You have not ordered anything yet",
    status: {
      sent: "Отправлено",
      receive: "Получен",
      AWAITING: "Awaiting for pay",
      IN_WORK: "In work",
      PAID: "Paid",
      REJECTED: "Payment failed",
      CANCELED: "Order",
      SENDED: "Sended",
      DELIVERED: "Delivered",
    },
    buttons: {
      show: "Show the order",
      track: "Track the order",
      pay: "Pay the order",
      reorder: "Re-order",
    },
    date: {
      departure: "Date of shipment",
      estimatedDelivery: "Expected shipping date",
      receive: "Date of receipt",
      track: "Track-code",
    },
  },
  addresses: {
    title: "Delivery addresses",
    buttons: {
      add: "Add address",
      edit: "Change",
      delete: "Delete",
    },
    popup: {
      title_change: "Changing address",
      title_add: "Adding address",
      title_delete: "Are you sure you want to delete the address?",
      title_default: "Set as default address",
      button: {
        save: "Save",
        delete: "Delete",
        cancel: "Сancel",
      },
    },
  },
  reviews: {
    title: "Customer feedback",
    line1:
      "Day by day we receive a lot of grateful reviews from our dear customers, who appreciate the quality of CLAN VI outfits. We get them in Direct Instagram and WhatsApp.",
    line2: "More reviews you can find on the link",
    line3: "We would be very happy to get feedback from you.",
  },
};

const localeRu = {
  gdpr: "ПРОДОЛЖАЯ ИСПОЛЬЗОВАТЬ ЭТОТ САЙТ, ВЫ СОГЛАШАЕТЕСЬ С ПРАВИЛАМИ ИСПОЛЬЗОВАНИЯ COOKIES.",
  promocode: {
    youWin: "Вы выиграли",
    useCode: "Используйте код",
    startBuying: "Начать покупки",
    spinWheel: "Крутить колесо",
    noThanks: "Нет, спасибо",
    auctionTitle: "Аукцион",
    instruction: "Крути, чтобы забрать свой бонус",
    PD15: "Скидка 15%",
    PD10: "Скидка 10%",
    FS: "Бесплатная доставка",
    forPD15: "на скидку 15%",
    forPD10: "на скидку 10%",
    forFS: "на бесплатную доставку",
    validTo: "Срок действия",
    d: "д",
    h: "ч",
    m: "м",
  },
  header: {
    basket: {
      title: "Корзина",
      size: "Размер",
      color: "Цвет",
      quantity: "Количество",
      sum: "Сумма",
      withoutDel: "Без учета доставки",
      buttonText: "Перейти в корзину",
      buttonAdd: "Добавить в корзину",
    },
    favorites: {
      title: "Избранное",
    },
    links: {
      wholesale: "Оптовикам",
      personal: "Личный кабинет",
      logIn: "Войти",
      lang: "Русский",
    },
    hotline: "Бесплатная горячая линия",
  },
  footer: {
    copyright: "2015-2024 CLANVI.COM",
    phoneText: "Телефон заботы о клиентах",
    links: {
      contacts: "Контакты",
      refund: "Возврат",
      reviews: "Отзывы",
      paymentDelivery: "Оплата и доставка",
      about: "О компании",
      aboutUs: "О нас",
      wholesale: "Оптовикам",
      buyers: "Покупателям",
      packaging: "Упаковка",
      priorityClub: "Priority Club",
      privacyPolicy: "Политика конфиденциальности",
      myCabinet: "Мой кабинет",
      basket: "Корзина",
      orders: "Мои заказы",
    },
    subscribeForm: {
      title: "Актуальные акции и скидки",
      inputPlaceholder: "Введите свой E-Mail",
      submit: "Подписаться",
      success: "Спасибо",
    },
  },
  category: {
    all: "Все товары",
    clothes: "Одежда",
    mobileCategoryTitle: "Категории",
    parametres: {
      size: "Размер",
      sizeClothes: "Одежда",
      sizeShoes: "Обувь",
      sizeGloves: "Перчатки",
      price: "Цена",
      color: "Цвет",
    },
    buttons: {
      reset: "Сбросить",
      sort: "Сортировка",
      apply: "Применить",
    },
    sort: {
      popular: "По наличию",
      price: "По цене",
    },
    filter: "Фильтр",
    empty:
      "К сожалению, по вашим параметрам ничего не найдено. Попробуйте изменить параметры поиска ❤️",
  },
  popup: {
    wholesaleOff: {
      title: "титле",
      text: '<p class="h4">Уважаемые партнеры!</p><p>По&nbsp;причине высокой загруженности производства и&nbsp;постоянно растущего спроса на&nbsp;товар бренда CLAN VI , возможность оптовых заказов на&nbsp;постоянной основе сейчас недоступна. О&nbsp;датах, в&nbsp;которые возможен приём оптовых заказов&nbsp;Вы можете уточнять у&nbsp;своего менеджера: <br>+7 (999) 464-49-77 Марина</p><p>Также, обратите внимание, что на&nbsp;сайте появилась новая услуга: теперь вы&nbsp;можете оформить заказ в&nbsp;кредит и&nbsp;оплачивать его частями. Эта функция действительна при заказе от&nbsp;50.000 до&nbsp;150.000&nbsp;рублей.</p>',
    },
    registered: {
      title: "Аккаунт активирован",
      text: "Теперь вы можете войти в свой аккаунт",
      link: "Войти",
    },
    notifySuccess: {
      title: "Спасибо!",
      text: "Мы уведомим вас, когда товар появится в наличии",
    },
    emailSuccess: {
      title: "Спасибо!",
      text: "Мы отправили вам письмо с промокодом",
    },
    emailFail: {
      title: "Ой",
      text: "Что-то пошло не так",
    },
    apiDown: {
      title: "Что-то пошло не так",
      text: "Сервер в данный момент не доступен. Попробуйте позже!",
      refresh: "Обновить страницу",
    },
    wholesaleOrder: {
      rub: "Чтобы Ваш заказ считался оптовым, общая сумма должна быть не менее 50 тыс.  ₽",
      eur: "Чтобы Ваш заказ считался оптовым, общая сумма должна быть не менее $ 600",
      usd: "Чтобы Ваш заказ считался оптовым, общая сумма должна быть не менее 530 €",
    },
    passwordRecover: {
      text: "На ваш E-Mail отправлено письмо с подтверждением",
      title2: "Успешно",
      text2: "Ваш пароль изменен",
      title3: "Что-то пошло не так",
      text3: "Не удалось изменить пароль",
    },
    feedback: {
      title: "Успешно",
      text: "Спасибо вам за отзыв!",
    },
    login: {
      afterAttempts:
        "При наличии проблем с авторизацией по номеру телефона, позвоните на горячую линию:",
      title: "Вход или регистрация",
      phone: "Телефон",
      email: "Почта",
      entrySocial: "Войти через соц.сети",
      haveAccount: "Еще нет аккаунта?",
      forgetPass: "Забыли пароль?",
      enterPhone: "Введите номер телефона",
      enterMail: "Введите электронную почту и пароль",
      phoneErrors: [
        "Телефон должен содержать 11 цифр",
        "Телефон должен содержать 12 цифр",
      ],
      loginError:
        "Превышено допустимое количество попыток, попробуйте повторить запрос позднее",
      retailTitle: "Розница",
      wholesaleTitle: "Опт",
      wholesale:
        "Для входа в оптовый раздел используйте адрес электронной почты",
      getCode: "Получить код",
      entry: "Войти",
    },
    getCode: {
      enterCode: "Введите код из смс",
      getCodeAgain: "Получить код повторно ",
      after: "(через ",
      invalidCode: "Неверный смс код",
      attempts: "попыток осталось",
      request: "запросите новый код авторизации",
      second: "с",
    },
    phoneVerify: "Номер телефона не подтвержён",
    registration: {
      registry: "Зарегистрироваться",
      opt: {
        shop: "Магазин",
        have: "Есть",
        no: "Нет",
        fillDetails: "Заполнить реквизиты",
        stockCompany: "ООО",
        enterp: "ИП",
      },
    },
    recovery: {
      recoveryPass: "Восстановление пароля",
      button: "Восстановить",
    },
    discount: {
      purchase: "Скидка на покупку",
      enterMail:
        "Введите свою почту и получите {discount}% скидку на первую покупку!",
      button: "Получить скидку",
    },
    sizes: {
      table: "Таблица размеров",
      chest: "Обхват груди",
      waist: "Обхват талии",
      hips: "Обхват бедер",
      size: "Размер",
      length: "Длина стельки, мм",
      text: "Мы используем универсальную европейскую систему размеров XS, XS-S, S, M, M-L и L. Подбирая размер, обратите внимание на фасон и материал. Трикотажные вещи тянутся легче, чем одежда из плотных тканей. Выбирая приталенные модели, учитывайте высоту пояса. Вещи свободного кроя подбирать проще: главную роль здесь играет положение плечевых швов. Верхнюю одежду рекомендуется выбирать с небольшим запасом.",
      clothesSizes: [
        ["XS", "80-84", "60-64", "86-90"],
        ["XS-S", "80-88", "60-69", "86-94"],
        ["S", "84-88", "65-69", "90-94"],
        ["M", "88-92", "70-74", "94-98"],
        ["M-L", "88-96", "70-78", "94-102"],
        ["L", "92-96", "75-78", "98-102"],
      ],
      shoesSizes: {
        1: [
          ["35", "230"],
          ["36", "235"],
          ["37", "240"],
          ["38", "245"],
          ["39", "250"],
          ["40", "255"],
          ["41", "260"],
        ],
        2: [
          ["35", "230"],
          ["36", "235"],
          ["37", "240"],
          ["38", "245"],
          ["39", "250"],
          ["40", "255"],
          ["41", "260"],
        ],
        3: [
          ["35", "230"],
          ["36", "235"],
          ["37", "240"],
          ["38", "245"],
          ["39", "250"],
          ["40", "255"],
          ["41", "260"],
        ],
        4: [
          ["35", "230"],
          ["36", "235"],
          ["37", "240"],
          ["38", "245"],
          ["39", "250"],
          ["40", "255"],
          ["41", "260"],
        ],
        5: [
          ["35", "230"],
          ["36", "235"],
          ["37", "240"],
          ["38", "245"],
          ["39", "250"],
          ["40", "255"],
          ["41", "260"],
        ],
      },
      glovesSizes: [
        ["XS-S", "80-84", "60-64", "86-90"],
        ["M-L", "88-92", "70-74", "94-98"],
      ],
    },
    invoicing: {
      title: "Выставление счета",
      text: "Для выставления счета с вами свяжется наш менеджер",
    },
    success: {
      title: "Заказ успешно оплачен",
      text: "Спасибо, что покупаете в нашем магазине",
    },
    afterRegister: {
      title: "Аккаунт создан",
      text: "На&nbsp;почту отправлено письмо с&nbsp;подтверждением.",
    },
    checkoutFail: {
      title: "Что-то пошло не так",
      text: "Свяжитесь с нашим менеджером",
    },
    checkoutStockroom: {
      title: "Выберите пункт выдачи",
      text: "Если вы выбрали доставку СДЭК до пункта выдачи, пожалуйста, выберите пункт выдачи!",
    },
    authorizationFail: {
      title: "Что-то пошло не так",
      text: "Не удалось войти через соц.сеть",
    },
    paymentFail: {
      title: "Что-то пошло не так",
      text: "Повторите оплату или свяжитесь с нашим менеджером",
    },
    review: {
      title: "Оставить отзыв",
      button: "Отправить",
    },
    notify: {
      title: "Сообщить о поступлении",
      text: "Оставьте свой e-mail и мы сообщим о поступлении товара.",
    },
    common: {
      personal: "Личный кабинет",
      entry: "Вход",
      registry: "Регистрация",
      buttonMain: "Вернуться на главную",
      buttonSend: "Отправить",
    },
    successEdit: {
      title: "Данные успешно изменены!",
      link: "Личный кабинет",
    },
    failEdit: {
      title: "Что-то пошло не так",
      text: "Повторите попытку позже",
      link: "Личный кабинет",
    },
    placeholder: {
      search: "Поиск",
      birthdate: "Дата рождения",
      email: "Электронная почта",
      yourEmail: "Ваш e-mail адрес",
      oldPassword: "Старый пароль",
      password: "Пароль",
      passwordAgain: "Пароль еще раз",
      number: "Телефон",
      city: "Город",
      linkShop: "Ссылка на магазин",
      fullName: "ФИО",
      inn: "ИНН",
      kpp: "КПП",
      okpo: "Код по ОКПО",
      shortLegal: "Сокращенное юр. наименование",
      message: "Сообщение",
    },
    chooseCountry: {
      title: "Обратите внимание!",
      text: "Убедительно просим вас ознакомиться с информацией о таможенных налогах и сборах в вашей стране. В стоимость изделий на сайте clanvi.com возможные пошлины, налоги и дополнительные сборы при таможенном декларировании не включены, так как они оплачиваются отдельно получателем в стране назначения.",
    },
    deleteProfile: {
      title: "УДАЛЕНИЕ ЛИЧНОГО КАБИНЕТА",
      text: "Вы собираетесь удалить Ваш Личный кабинет в интернет-магазине CLAN IV.",
      text_attention:
        "Удаление Личного кабинета приведет к удалению всего его содержимого и всех связанных с ним данных без возможности восстановления",
      button_cancel: "Отмена",
      button_delete: "Удалить профиль",
    },
    chooseBasket: {
      title: "Изменение списка товаров в корзине",
      text: "После авторизации список товаров в вашей корзине изменился. Восстановить предыдущую корзину?",
      button_restore: "Восстановить",
      button_leave: "Оставить текущую",
    },
  },
  profile: {
    title: "Редактирование профиля",
    save: "Сохранить",
    changePass: "Изменить пароль",
    savePass: "Сменить пароль",
    deleteProfile: "Удалить профиль",
  },
  cabinet: {
    wholesale: {
      title: "Добро пожаловать в Личный кабинет оптового покупателя!",
      description:
        "Партнёрская программа CLAN VI дарит Вам разовую скидку 5% на первый заказ по промокоду. Совершайте заказы и получайте дополнительные бонусы по покупательскому статусу и скидки по сумме заказа на каждом этапе сотрудничества:",
      l1: "От 50 тыс. - 5%",
      l2: "От 60 тыс. - 6%",
      l3: "От 70 тыс. - 7%",
      l4: "От 80 тыс. - 8%",
      l5: "От 90 тыс. - 9%",
      l6: "От 100 тыс. - 10%",
      l7: "Минимальная сумма оптового заказа: 50.000 ₽",
      l8: "SILVER - от 50.000 ₽",
      l81: "Базовый статус оптового клиента. Подтверждение статуса оптового покупателя не менее чем на 50.000 ₽ в месяц.",
      l9: "GOLD - от 500.000 ₽.",
      l91: "Повышенный статус оптового клиента. Бесплатная доставка до пункта выдачи (CDEK, DPD) . Подтверждение статуса оптового покупателя не менее чем на 60.000 ₽ в месяц.",
      l10: "PLATINUM - от 1000.000 ₽.",
      l101: "Максимальный статус оптового клиента. Бесплатная доставка любым способом. Подтверждение статуса не менее чем на 100.000 ₽ в месяц.",
      l11: "Подтверждать статус нужно каждые 45 календарных дней со дня последнего заказа. В противном случае, общая сумма заказов уменьшается на сумму подтверждения каждые 45 календарных дней.",
      up: "Система повышения покупательского статуса:",
    },
    title: "Личный кабинет",
    edit: "Редактировать профиль",
    addresses: "Адреса доставки",
    orders: "Мои заказы",
    logout: "Выход",
    saleOrder: "Скидка по сумме заказа",
    status: {
      current: {
        title: "Ваш текущий статус покупателя",
        desc: "Разовая скидка 5% за регистрацию в партнерской программе Clan VI",
      },
      next: {
        title: "Ваш следущий статус покупателя",
        desc: "Бесплатная доставка до терминала транспортной компании в Новосибирске.",
      },
      close: {
        title: "Priority Club",
        desc: "Разовая скидка 5% за регистрацию в партнерской программе Clan VI",
      },
      priority: {
        declinedTitle: "Вы ещё не в клубе?",
        declined:
          "Оставайтесь с нами, совершайте покупки на общую сумму |replaceThis| рублей и Вам откроется секретный раздел со скидками и специальными предложениями только для членов клуба CLAN VI Priority!",
        access:
          "Вам доступен секретный раздел со скидками и специальными предложениями.",
        accessTitle: "Вы вступили в CLAN VI Priority!",
      },
    },
    review: {
      title: "Оставить отзыв",
      text: "Это обеспечит вам качественный сервис",
      button: "Оставить отзыв",
      placeholder: "Оставить сообщение",
    },
    help: {
      title: "Нужна помощь?",
      text: "Менеджер оптовых заказов",
    },
  },
  contacts: {
    title: "Наши адреса",
    clock: "Ежедневно с 12:00 до 20:00",
    button: "Написать отзыв",
    nsk: {
      adress: "Россия, Новосибирск, ул. Гоголя 13, ТЦ «Галерея Новосибирск»",
    },
    msk: {
      adress:
        "Россия, Москва, ул. Набережная Академика Туполева, 15 (ЖК «Каскад») магазин CLAN VI",
    },
  },
  main: {
    title: "Интернет-магазин женской одежды",
    toCatalog: "В каталог",
    showAll: "Смотреть все",
    hideProduct: "Скрыть товары",
    showProduct: "Используемые товары",
    collectionTitle: "Новая коллекция",
    collectionText: "Подчеркните свой уникальный стиль новинками CLAN VI",
    instaTitle: "Вы & CLAN VI",
    instaText: "Отмечайте @clan6 на фотографиях",
  },
  product: {
    blogger: {
      recommend: "рекомендует этот товар",
      subscribers: "подписчиков",
    },
    titleFor: " за ",
    breadcrumbs: {
      home: "Главная",
      catalog: "Каталог",
    },
    parametres: {
      size: "Размер",
      color: "Цвет",
      count: "Количество",
    },
    info: {
      desc: "Описание",
      specific: "Характеристики",
    },
    discount: {
      title: "Скидка",
      text: "на первый заказ по промокоду",
    },
    addBasket: {
      continue: "Продолжить покупки",
      checkout: "Оформить заказ",
    },
    sizeTable: "Таблица размеров",
    reportOfStock: "Сообщить о поступлении",
    detail: "Подробнее",
    recentlyView: "Недавно просмотренные",
    relatedProducts: "Дополните образ",
    button: "Добавить в корзину",
    available: "Наличие в г.",
    availableCity: {
      nsk: "Новосибирске",
      msk: "Москве",
      spb: "Санкт-Петербурге",
      krd: "Краснодаре",
      ekb: "Екатеринбурге",
    },
    notInStock: "Нет в наличии",
    notAvailable: "Недоступно для заказа онлайн",
    unit: "шт.",
    call: "Позвонить в магазин",
    address: "Магазин CLAN VI на",
    addressCity: {
      nsk: "ул. Гоголя 13, ТЦ «Галерея Новосибирск»",
      msk: "ул. Большая полянка, 4/10",
      spb: "Набережная Реки Карповки 10",
      krd: "ул. Октябрьская, 65",
      ekb: "ул. Радищева, 27",
    },
    newTitle: "Выберите магазин",
  },
  page404: {
    title: "Страница не найдена",
    text: "К сожалению, запрашиваемая вами страница не найдена.",
    links: {
      main: "Вернуться на главную",
      catalog: "Перейти в каталог",
    },
  },
  basket: {
    title: "Корзина",
    sum: "Сумма",
    button: "Оформить заказ",
    info: {
      title: "Без учета доставки",
      text: "Возврат возможен в течение 14 дней",
      text2: "Срок сбора заказа занимает 7-10 календарных дней",
    },
    empty:
      'Сейчас в вашей корзине ничего нет. Выберите что-нибудь из нашей <a href="/catalog/new-collection">новой коллекции</a> ❤️',
    outOfStock: "Недоступно для заказа онлайн",
    freeBox: "Фирменная коробочка",
    freeBoxYes: "Ваш заказ будет упакован в подарочную коробку бесплатно",
    freeBoxNo:
      "К сожалению, Ваш заказ не предусматривает бесплатную подарочную коробку. Закажите фирменную коробку дополнительно за 1000 ₽ или дополните заказ хотя бы одним изделием стоимостью от 5000 ₽, чтобы получить упаковку бесплатно",
    freeBag: "Подарочный мешочек",
    freeBagYes: "Ваш заказ будет отправлен в подарочном атласном мешочке!",
    freeBagNo:
      "К сожалению, товары из данного раздела не предусматривают получение заказа в подарочной упаковке, но Вы можете дополнительно добавить атласный мешочек за 290 ₽",
    shoesBox: "Подарочная упаковка",
    shoesBoxText: "Ваш заказ будет отправлен в фирменной коробочке бренда",
    noBoxTitle: "Закажите фирменную упаковку!",
    noBoxText:
      "Заказы в фирменной коробочке CLAN VI приходят при покупке от 2-ух вещей, не включая раздел “Горячие предложения”. Вы можете заказать подарочную упаковку дополнительно за 1000 ₽ или дополнить свой заказ.",
    orderBtn: "Оформить заказ",
    noBoxAdd: "Добавить упаковку",
    noBagTitle: "Получите Ваш заказ в подарочной упаковке!",
    noBagText:
      "К сожалению, товары из данного раздела не соответствуют условиям получения заказа в подарочной упаковке, но мы предлагаем приятный бонус: к своему заказу Вы можете добавить атласный мешочек за 290 ₽",
    noBagAdd: "Добавить мешочек",
    sunSale:
      "P.S. На товары из категории «Солнечный SALE» не распространяется подарочная упаковка.",
    addProducts: {
      t1: "Дополните корзину на",
      t2: ", чтобы получить бесплатную доставку!",
    },
  },
  favorites: {
    empty: "Добавьте что-нибудь в избранное ❤️",
  },
  checkout: {
    credit: {
      buy: "Купить за",
      currency: "₽/мес",
    },
    packagingDelay: {
      title: "ОБРАТИТЕ ВНИМАНИЕ!",
      text: "В связи с высоким спросом на продукцию бренда и загруженностью нашего производства, срок изготовления и отправки заказа на текущий момент составляет от 2-х до 3-х недель после оплаты заказа. Нажимая «OK», Вы соглашаетесь со сроками ожидания заказа.",
    },
    errors: {
      country: "Выберите страну из выпадающего списка",
      city: "Выберите город из выпадающего списка",
    },
    title: "Оформление заказа",
    textRegister: "Уже зарегистрированы?",
    textFirst: "Впервые у нас?",
    cost: "Стоимость доставки",
    fillFields: "Заполните все поля!",
    deliveryPoint: "Выберите пункт самовывоза!",
    sum: "Сумма",
    sumLoyalty: "Скидка по программе лояльности",
    rubleNotify:
      "В платежной системе вам будет показанно число в рублях, не пугайтесь, с вас будет списана сумма, примерно равная {sum} по курсу вашего банка",
    sumText: "С учетом скидки и доставки",
    popoverFreeShipping: [
      "Дополните корзину до ",
      " и получите экспресс доставку DHL за один-два дня бесплатно",
    ],
    registration: {
      title: "Личные данные",
      placeholder: {
        fullName: "ФИО",
        email: "Электронная почта",
        number: "Номер телефона",
      },
    },
    delivery: {
      title: "Адрес доставки",
      sale: "Скидка",
      saving: "Экономия",
      placeholder: {
        country: "Страна",
        city: "Город",
        address: "Адрес",
        comment: "Комментарий к заказу",
        index: "Индекс",
      },
      logistic: {
        title: "Условия доставки",
        count: "Сумма",
        with: "С учетом скидки и доставки",
        select: {
          sdStock: "СДЭК до пункта выдачи",
          sdDoor: "СДЭК до двери",
          dpdDoor: "DPD до двери",
          dpdStockroom: "DPD до пункта выдачи",
          dhlDoor: "DHL до двери",
          postRuss: "Почта России (посылка)",
          emsPostRuss: "EMS Почта России",
          day: "0 дней | 1 день | {n} дня | {n} дней",
          sale: [
            "При заказе на сумму более",
            " рублей — доставка по России бесплатно!",
          ],
          enterIndex:
            "Для расчета стоимости доставки Почтой России, EMS, DPD и DHL укажите свой индекс",
          enterStock: "Выбрать пункт выдачи",
          collectOrder:
            "Срок сбора вашего заказа может составить до 7-8 рабочих дней",
          deliveryDays:
            "*Срок доставки указан в рабочих днях. В данный срок не включён сбор заказа перед отправкой.",
        },
      },
      check: {
        title: "Оплата",
        inform: "Сообщать мне о новых коллекциях",
        agree:
          'Я даю своё согласие на обработку персональных данных и соглашаюсь с условиями и <a href="/privacy/" target="_blank">политикой конфиденциальности и условиями возврата</a>',
        agree_policy:
          'Я даю своё согласие на обработку персональных данных и соглашаюсь с условиями и <a href="/privacy/" target="_blank">политикой конфиденциальности</a>',
       },
    },
    promocode: {
      text: "Введите промокод",
      submit: "Применить",
      placeholder: "Промокод на скидку",
      name: "Промокод",
      delete: "Удалить",
    },
    buttons: {
      enter: "Войти",
      payCard: "Оплатить",
      payQiwi: "Оплатить Qiwi",
      score: "Выставить счет",
      textFirst: "Впервые у нас?",
    },
  },
  myOrders: {
    title: "Мои заказы",
    notOrder: "Вы пока ничего не заказывали",
    status: {
      sent: "Отправлено",
      receive: "Получен",
      AWAITING: "Ожидает оплаты",
      PAID: "Оплачен",
      IN_WORK: "В работе",
      REJECTED: "Оплата не удалась",
      CANCELED: "Заказ отменен",
      SENDED: "Отправлен",
      DELIVERED: "Доставлен",
    },
    buttons: {
      show: "Смотреть заказ",
      track: "Отследить заказ",
      pay: "Оплатить заказ",
      reorder: "Повторить заказ",
    },
    date: {
      departure: "Дата отправки",
      estimatedDelivery: "Предпологаемая дата доставки",
      receive: "Дата получения",
      track: "Трек-код",
    },
  },
  addresses: {
    title: "Адреса доставки",
    buttons: {
      add: "Добавить адрес",
      edit: "Изменить",
      delete: "Удалить",
    },
    popup: {
      title_change: "Изменение адреса",
      title_add: "Добавление адреса",
      title_delete: "Вы действительно хотите удалить адрес?",
      title_default: "Сделать адресом по умолчанию",
      button: {
        save: "Сохранить",
        delete: "Удалить",
        cancel: "Отменить",
      },
    },
  },
  reviews: {
    title: "Отзывы",
    line1:
      "За 9 лет работы мы получили множество благодарных отзывов от наших покупательниц, оценивших качество нарядов от CLAN VI. Мы получаем их ежедневно в Директ Instagram<span>*</span> и WhatsApp.",
    line2: "Больше отзывов вы можете найти по ссылке",
    line3: "Мы будем безумно рады получить отзыв и от Вас.",
  },
};

export { localeEn, localeRu };
