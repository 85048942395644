<template>
  <div @click="$emit('input', !value)" class="wide-checkbox" :class="{ active: value }">
    <div class="wide-checkbox-round" :class="{ active: value }"></div>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean
  }
};
</script>

<style lang="scss">
.wide-checkbox {
  background: #E0E0E0;
  border-radius: 30px;
  width: 48px;
  height: 24px;
  position: relative;
  cursor: pointer;
}

.wide-checkbox-round {
  width: 24px;
  height: 24px;
  background: #828282;
  border-radius: 15px;
  transition: 0.3s;
  &.active {
    transform: translateX(24px);
  }
}
</style>
