<template>
  <div class="popup_get-code--code-typer">
    <input
      v-model="digits[0].value"
      v-mask="'#'"
      type="number"
      class="hidden"
      ref="codeTyper1"
      @blur="digits[0].isFocus = false"
      @input.prevent="inputHandler($event, 0)"
      :disabled="isDisabled"
    />
    <input
      v-model="digits[1].value"
      v-mask="'#'"
      type="number"
      class="hidden"
      ref="codeTyper2"
      @blur="digits[1].isFocus = false"
      @input.prevent="inputHandler($event, 1)"
      :disabled="isDisabled"
    />
    <input
      v-model="digits[2].value"
      v-mask="'#'"
      type="number"
      class="hidden"
      ref="codeTyper3"
      @blur="digits[2].isFocus = false"
      @input.prevent="inputHandler($event, 2)"
      :disabled="isDisabled"
    />
    <input
      v-model="digits[3].value"
      v-mask="'#'"
      type="number"
      class="hidden"
      ref="codeTyper4"
      @blur="digits[3].isFocus = false"
      @input.prevent="inputHandler($event, 3)"
      :disabled="isDisabled"
    />
    <div
      class="popup_get-code-typer-item"
      :class="{ active: digits[0].isFocus }"
      @click="focusInput(1)"
    >
      {{ digits[0].value }}
    </div>
    <div
      class="popup_get-code-typer-item"
      :class="{ active: digits[1].isFocus }"
      @click="focusInput(2)"
    >
      {{ digits[1].value }}
    </div>
    <div
      class="popup_get-code-typer-item"
      :class="{ active: digits[2].isFocus }"
      @click="focusInput(3)"
    >
      {{ digits[2].value }}
    </div>
    <div
      class="popup_get-code-typer-item"
      :class="{ active: digits[3].isFocus }"
      @click="focusInput(4)"
    >
      {{ digits[3].value }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
  },
  data: () => ({
    digits: [
      { value: "", isFocus: false },
      { value: "", isFocus: false },
      { value: "", isFocus: false },
      { value: "", isFocus: false },
    ],
    isDisabled: false,
  }),
  mounted() {
    this.$root.$on("codeTyper:clean", () => {
      this.digits[0].value = "";
      this.digits[1].value = "";
      this.digits[2].value = "";
      this.digits[3].value = "";
      this.$refs["codeTyper1"].focus();
      this.digits[0].isFocus = true;
    });
  },
  methods: {
    focusInput(ref) {
      this.$refs["codeTyper" + ref].focus();
      this.digits[ref - 1].isFocus = true;
    },
    inputHandler(event, index) {
      if (index !== 3) {
        if (event.target.value.length > 1)
          this.digits[index].value = event.target.value.substr(1, 1);
        else {
          this.digits[index].value = event.target.value;
        }
        this.$refs["codeTyper" + (+index + 2)].focus();
        this.digits[index + 1].isFocus = true;
      }
      if (index === 3) {
        if (event.target.value.length > 1)
          this.digits[index].value = event.target.value.substr(1, 1);
        else {
          this.digits[index].value = event.target.value;
        }
      }
      if (event.target.value.length < 1 && index !== 0) {
        this.$refs["codeTyper" + index].focus();
        this.digits[index - 1].isFocus = true;
      } else if (event.target.value.length < 1 && index === 0) {
        this.$refs["codeTyper1"].focus();
        this.digits[0].isFocus = true;
      }
      this.$emit(
        "input",
        this.digits[0].value +
          this.digits[1].value +
          this.digits[2].value +
          this.digits[3].value
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.hidden {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  cursor: default;
  width: 0;
  height: 0;
  bottom: 0;
  pointer-events: none;
}

.popup_get-code--code-typer {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, max-content);
  grid-gap: 10px;
  justify-content: center;
  margin-top: 27px;
  cursor: pointer;
}

.popup_get-code-typer-item {
  cursor: pointer;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  display: grid;
  align-items: center;
  justify-items: center;
  &.active {
    border: 1px solid #4f4f4f;
  }
}
</style>
