// Interface
import "bootstrap/dist/css/bootstrap.css";
import "popper.js";
import "cleave.js/dist/cleave.min.js";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "nouislider/distribute/nouislider.css";
import { clanvi } from "./js/action.js";
import { localeEn, localeRu } from "./app/Translations/Translations";
import { Axios } from "./app/Plugins/axios.js";

// Vue components
import Vue from "vue";
import VueResource from "vue-resource";
import vueDebounce from "vue-debounce";
import Vuex from "vuex";
import VueRouter from "vue-router";
import VueLocalStorage from "vue-localstorage";
import VueI18n from "vue-i18n";
import VeeValidate, { Validator } from "vee-validate";
import VuePageTitle from "vue-page-title";
import ru from "vee-validate/dist/locale/ru.js";
import en from "vee-validate/dist/locale/en.js";
import VueCookies from "vue-cookies";
import VueCountdown from "@chenfengyuan/vue-countdown";
import { VueMaskDirective } from "v-mask";
import * as Sentry from "@sentry/vue";

Vue.directive("mask", VueMaskDirective);

const moment = require("moment");
require("moment/locale/ru");

Vue.use(require("vue-moment"), {
  moment
});

// Raven.config("https://6d3add3afbe8490b852072e2d720ac53@sentry.hpdev.ru/3")
//   .addPlugin(vuePlugin)
//   .install();

// Pages

import Skeleton from "./app/Pages/Skeleton.vue";
import Main from "./app/Pages/Main/Main.vue";
const Category = () => import("./app/Pages/Category/Category.vue");
const Product = () => import("./app/Pages/Product/Product.vue");
const Authorize = () => import("./app/Pages/Authorized/Authorized.vue");
const AuthorizationFailed = () =>
  import("./app/Pages/AuthorizationFailed/AuthorizationFailed.vue");
const Cabinet = () => import("./app/Pages/Cabinet/Cabinet.vue");
const Contacts = () => import("./app/Pages/Contacts/Contacts.vue");
const DynamicPages = () => import("./app/Pages/DynamicPages/DynamicPages.vue");
const Error404 = () => import("./app/Pages/404/404.vue");
const Basket = () => import("./app/Pages/Basket/Basket.vue");
const Checkout = () => import("./app/Pages/Checkout/Checkout.vue");
const MyOrders = () => import("./app/Pages/MyOrders/MyOrders.vue");
const Addresses = () => import("./app/Pages/Addresses/Addresses.vue");
const Profile = () => import("./app/Pages/Profile/Profile.vue");
const Reviews = () => import("./app/Pages/Reviews/Reviews.vue");
const Recovery = () => import("./app/Pages/Recovery/Recovery.vue");
const MobileApp = () => import("./app/Pages/Mobile-App/Mobile-App.vue");

import "./index.scss";

// Services
import AuthService from "./app/Services/AuthService.js";

// Plugins
import AuthPlugin from "./app/Plugins/AuthPlugin.js";

//  Vuex global store
const store = new Vuex.Store({
  namespaced: true,
  state: {
    globalSearch: "",
    pageSearch: "",
    versionLongPollingInterval: 60000,
    appVersion: null,
    versionLongPollingTimer: null
  },

  mutations: {
    setGlobalSearch(state, payload) {
      state.globalSearch = payload;
    },
    setPageSearch(state, payload) {
      state.pageSearch = payload;
    },
    setVersionLongPollingInterval(state, payload) {
      state.versionLongPollingInterval = payload;
    },
    setVersionLongPollingTimer(state, payload) {
      state.versionLongPollingTimer = payload;
    },
    setAppVersion(state, payload) {
      state.appVersion = payload;
    }
  }
});

Vue.use(vueDebounce);
Vue.use(VueCookies);
VueCookies.config("7d");
Vue.component(VueCountdown.name, VueCountdown);
Vue.use(VuePageTitle, {
  suffix: "| CLANVI"
});
Vue.use(AuthPlugin);
Vue.use(AuthService);
Vue.use(VueRouter);
Vue.use(VueResource);
Vue.use(store);
Vue.use(VueLocalStorage, {
  name: "ls",
  bind: true
});
Vue.use(VueI18n);
Validator.localize("ru", ru);
Vue.use(VeeValidate, {
  locale: "ru",
  behavior: "eager"
});

Validator.extend("fio", {
  validate: value => {
    const fio = value.split(" ");

    if (fio.length < 3) {
      return false;
    }

    if (fio[0].length < 2) {
      return false;
    }

    if (fio[1].length < 2) {
      return false;
    }

    if (fio[2].length < 2) {
      return false;
    }

    return true;
  },
  getMessage: () => "Введите имя, фамилию и отчество!"
});

Validator.extend("fi", {
  validate: value => {
    const fio = value.split(" ");

    if (fio.length < 2) {
      return false;
    }

    if (fio[0].length < 2) {
      return false;
    }

    if (fio[1].length < 2) {
      return false;
    }

    return true;
  },
  getMessage: () => "Введите имя, фамилию и отчество!"
});

const dict = {
  en: {
    messages: {
      fio: () => "Please enter your name, surname and lastname!",
      fi: () => "Please enter your name and surname!"
    }
  },
  ru: {
    messages: {
      fio: () => "Пожалуйста, введите имя, фамилию и отчество!",
      fi: () => "Пожалуйста, введите имя и фамилию!"
    }
  }
};

Validator.localize(dict);

Vue.http.options.root = API_URL;
Vue.http.options.credentials = true;
Vue.http.options.xhr = { withCredentials: true };

Vue.prototype.$ui = clanvi;
window.$ui = clanvi;

Vue.prototype.$isColorLight = hex => {
  if (hex) {
      const rgb = hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => "#" + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      .map(x => parseInt(x, 16));
    return 0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2] > 240; 
  }
};

VueI18n.prototype.getChoiceIndex = (choice, choicesLength) => {
  if (this.locale === "ru") {
    if (choice === 0) {
      return 0;
    }

    const teen = choice > 10 && choice < 20;
    const endsWithOne = choice % 10 === 1;

    if (!teen && endsWithOne) {
      return 1;
    }

    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
      return 2;
    }

    return choicesLength < 4 ? 2 : 3;
  }

  choice = Math.abs(choice);

  if (choicesLength === 2) {
    return VueI18n.prototype.getOldChoiceIndexFixed(choice);
  }

  return choice ? Math.min(choice, 2) : 0;
};

const i18n = new VueI18n({
  locale: "ru",
  messages: {
    ru: localeRu,
    en: localeEn
  }
});

const isEmptyObject = obj => {
  for (let i in obj) {
    if (obj.hasOwnProperty(i)) return false;
  }
  return true;
};

const scrollBehavior = (to, from, savedPosition) => {
  const position = { x: 0, y: 0 };

  if (
    to.name === from.name &&
    (to.name === "Category" || to.name === "Catalog") &&
    !isEmptyObject(to.query)
  )
    return false;

  if (savedPosition) {
    return savedPosition;
  }

  position.x = 0;
  position.y = 0;

  return position;
};

const router = new VueRouter({
  mode: "history",
  scrollBehavior,
  routes: [
    {
      name: "Home",
      path: "/",
      components: {
        default: Main
      }
    },
    {
      name: "Mobile-App",
      path: "/mobile-app",
      components: {
        default: MobileApp
      }
    },
    {
      path: "/catalog",
      name: "Catalog",
      components: {
        default: Category
      }
    },
    {
      name: "Category",
      path: "/catalog/:category",
      components: {
        default: Category
      }
    },
    {
      name: "Product",
      path: "/product/:product",
      components: {
        default: Product
      }
    },
    {
      name: "Authorized",
      path: "/authorized",
      components: {
        default: Authorize
      }
    },
    {
      name: "AuthorizationFailed",
      path: "/authorization_failed",
      components: {
        default: AuthorizationFailed
      }
    },
    {
      name: "Recovery",
      path: "/recovery",
      components: {
        default: Recovery
      }
    },
    {
      name: "Cabinet",
      path: "/cabinet",
      components: {
        default: Cabinet
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      name: "Basket",
      path: "/basket",
      components: {
        default: Basket
      }
    },
    {
      name: "Checkout",
      path: "/checkout",
      components: {
        default: Checkout
      }
    },
    {
      name: "MyOrders",
      path: "/my-orders",
      components: {
        default: MyOrders
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      name: "Addresses",
      path: "/my-addresses",
      components: {
        default: Addresses
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      name: "Contacts",
      path: "/contacts",
      components: {
        default: Contacts
      }
    },
    {
      name: "Reviews",
      path: "/reviews",
      components: {
        default: Reviews
      }
    },
    {
      name: "Profile",
      path: "/profile",
      components: {
        default: Profile
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      name: "Error404",
      path: "/404",
      component: Error404
    },
    {
      name: "DynamicPage",
      path: "/:slug",
      components: {
        default: DynamicPages
      }
    }
  ]
});

if (SENTRY_DSN) {
  const integrations =  [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["*"],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
  ]
  // let replaysOnErrorSampleRate = 0.0;
  // let replaysSessionSampleRate = 0.0;

  // if (SERVER_MODE === "prod" && SITE_URL === "https://dev-clanvi.terabitvu.ru/") {
  //   integrations.push(new Sentry.Replay());
  //   replaysOnErrorSampleRate = 1.0;
  //   replaysSessionSampleRate = 1.0;
  // }

  Sentry.init({
    Vue,
    dsn: SENTRY_DSN,
    integrations,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // replaysSessionSampleRate,
    // replaysOnErrorSampleRate,
  });
}

router.beforeEach((to, from, next) => {
  if (Vue.ls.get("access")) {
    Vue.$AuthService.tokenVerify(response => {
      if (to.matched.some(record => record.meta.requiresAuth)) {
        if (response) {
          next();
        } else {
          next("/");
        }
      } else {
        next();
      }
    });
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    next("/");
  } else {
    next();
  }
});

const extractHostname = function(url) {
  let hostname = null;

  if (url.indexOf("//") > -1) {
    hostname = url.split("/")[2];
  } else {
    hostname = url.split("/")[0];
  }

  hostname = hostname.split(":")[0];
  hostname = hostname.split("?")[0];

  return hostname;
};

Vue.http.interceptors.push((request, next) => {
  const token = Vue.ls.get("access");
  const browserLang = navigator.language.split("-")[0];

  let lang =
    browserLang === "ru" || browserLang === "uk" || browserLang === "be"
      ? "ru"
      : "en";
  let currency = "RUB";

    //  TODO: CLA-352
    // browserLang === "ru" || browserLang === "uk" || browserLang === "be"
    //   ? "RUB"
    //   : "USD";

  if (window.localStorage) {
    lang = window.localStorage.lang ? window.localStorage.lang : lang;

    //  TODO: CLA-352
    // currency = window.localStorage.currency
    //   ? window.localStorage.currency
    //   : currency;

    window.localStorage.currency = currency;
  }

  if (
    extractHostname(request.url) === extractHostname(Vue.http.options.root) ||
    !request.url.startsWith("https://")
  ) {
    request.params.lang = lang;
    request.params.currency = currency;

    if (token) {
      request.headers.set("Authorization", "JWT " + token);
    }
  }

  next(response => {
    if (response.status === 401 || response.status === 403) {
      Vue.ls.remove("access");
      request.headers.delete("Authorization");

      // window.location = '/';

      // Vue.http.post('api/auth/token-refresh/', {
      //   refresh: Vue.ls.get('refresh')
      // }).then(response => {
      //   console.log(response.body);
      //   Vue.ls.set('access', response.data.access);
      //   Vue.ls.set('refresh', 'Bearer ' + response.data.refresh);
      // });
    } else if (
      (response.status === 500 || response.status === 502) &&
      extractHostname(request.url) === extractHostname(Vue.http.options.root)
    ) {
      clanvi.popup.openPopup("api-down");
    }
  });
});

if (process.env.NODE_ENV !== "development") {
  Vue.prototype.fetchVersion = async function(remindMeLater = false) {
    try {
      if (!remindMeLater) {
        const res = await Axios.get(`${window.location.origin}/version.json`);

        if (res.status === 200 && res.data.version) {
          if (!this.$store.state.appVersion) {
            this.$store.commit("setAppVersion", res.data.version);
          } else if (this.$store.state.appVersion !== res.data.version) {
            this.$ui.popup.openPopup("app-version");
          }
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      if (this.$store.state.versionLongPollingTimer)
        clearInterval(this.$store.state.versionLongPollingTimer);
      this.$store.commit(
        "setVersionLongPollingTimer",
        setTimeout(() => {
          this.fetchVersion();
        }, this.$store.state.versionLongPollingInterval)
      );
    }
  };
}

export default new Vue({
  i18n,
  el: "#root",
  router,
  store,
  methods: {
    ready() {
      document.onreadystatechange = () => {
        if (document.readyState === "complete") {
          this.$ui.init();
          const dolamiScript = document.createElement("script");
          dolamiScript.src = `//aq.dolyame.ru/${ENV_DOLYAMI_SITE_ID}/client.js?ts=${Date.now()}`;
          dolamiScript.defer = true;
          dolamiScript.async = true;
          document.body.appendChild(dolamiScript);
        }
      };
    },
    isColorLight(hex) {
      const rgb = hex
        .replace(
          /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
          (m, r, g, b) => "#" + r + r + g + g + b + b
        )
        .substring(1)
        .match(/.{2}/g)
        .map(x => parseInt(x, 16));
      return 0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2] > 127.5;
    }
  },
  beforeCreate() {
    Vue.$title = "CLAN VI";

    const queryLang = this.$route.query.lang;
    const browserLang = navigator.language.split("-")[0];
    //  TODO: CLA-352
    // const lsLang = this.$ls.get("lang");
    // const lsCurrency = this.$ls.get("currency");

    let lang =
      browserLang === "ru" || browserLang === "uk" || browserLang === "be"
        ? "ru"
        : "en";
    //  TODO: CLA-352
    // let currency =
    //   browserLang === "ru" || browserLang === "uk" || browserLang === "be"
    //     ? "RUB"
    //     : "USD";

    //if (window.localStorage) {
      //   lang = lsLang ? lsLang : lang;
      //  TODO: CLA-352
      // currency = lsCurrency ? lsCurrency : currency;
    //}

    if (queryLang) {
      this.$i18n.locale = queryLang;
      this.$ls.set("lang", queryLang);
    } else if (lang) {
      this.$i18n.locale = lang;
      this.$ls.set("lang", lang);
    }
    //  TODO: CLA-352
    // this.$ls.set("currency", currency);

    if (this.$i18n.locale === "ru") {
      this.$validator.localize("ru", ru);
    } else {
      this.$validator.localize("en", en);
    }
  },
  mounted() {
    this.ready();
    if (this.fetchVersion) this.fetchVersion();
  },
  watch: {
    $route() {
      this.$ui.scroll.addScroll();
    }
  },
  render: h => h(Skeleton)
});
