<template>
  <div class="header-dropdown"
       :class="{'header-dropdown_language': type === 'header'}"
       :data-toggle_popup="(type === 'header') ? 'language' : null">
    <div class="header-dropdown__wrap">
      <div class="header-dropdown__text" v-if="currentLang == 'ru'">Русский</div>
      <div class="header-dropdown__text" v-if="currentLang == 'en'">English</div>
      <div class="header-dropdown__arrow fal fa-angle-down"></div>
      <select v-if="type !== 'header'"
              @change="switchLang"
              v-model="lang">
        <option v-for="item in languages"
                :value="item.slug">
          {{item.title}}
        </option>
      </select>
    </div>
    <div class="header-popup" v-if="type === 'header'">
      <ul class="header-popup-list list-unstyled">
        <li class="animate-delay" v-for="item in languages" :class="{inactive: currentLang == item.slug}"
            @click="(currentLang == item.slug) ? null : switchLang(item.slug)">
          <div>{{item.title}}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import AuthStore from '../../Stores/AuthStore.vue';

  export default {
    name: 'LangSwitcher',
    props: ['type', 'current'],
    data() {
      return {
        currentLang: this.current,
        lang: null,
        languages: [
          {
            title: 'Русский',
            slug: 'ru'
          },
          {
            title: 'English',
            slug: 'en'
          }
        ]
      };
    },
    methods: {
      switchLang(lang) {
        if(this.type !== 'header') {
          lang = this.lang;
        }

        this.$ls.set('lang', lang);

        if (!this.tokenCurrency) {
          if (lang == 'en') {
            this.$ls.set('currency', 'USD');
          } else {
            this.$ls.set('currency', 'RUB');
          }
        }

        const route = this.$route.name;

        if (route === 'Product' || route === 'Category') {
          this.$root.$emit('update:lang', lang);
        } else {
          window.history.go();
        }
      }
    },
    computed: {
      tokenCurrency () {
        return AuthStore.state.status.currency
      }
    },
    created() {
      if (this.type !== 'header') {
        this.lang = this.currentLang;
      }
    }
  };
</script>
