const AuthService = {
  install(Vue) {
    Vue.$AuthService = {
      tokenVerify(cb) {
        if (Vue.$AuthStore.auth) {
          cb(true);
        } else if (window.localStorage) {
          const token = Vue.ls.get('access');

          if (token) {
            Vue.http.post('api/auth/token-verify/', {token}).then(response => {
              if (response.body.currency) {
                Vue.ls.set('currency', response.body.currency);
                Vue.$AuthStore.commit('currency', response.body.currency);
              }

              Vue.$AuthStore.commit('person_type', response.body.person_type);

              Vue.$AuthStore.commit('updateStatus', true);

              Vue.$AuthStore.commit('wholesale_access', response.body.wholesale_access);

              if (response.body.wholesale_access === false &&
                (Vue.ls.get('wholesale-off') === true || !Vue.ls.get('wholesale-off')) &&
                response.body.person_type === 'WB') {
                window.$ui.popup.togglePopup('wholesale-off');
                Vue.ls.set('wholesale-off', true);
              }

              if (response.body.wholesale_access === true && Vue.ls.get('wholesale-off')) {
                Vue.ls.remove('wholesale-off');
              }

              cb(true);
            }, () => {
              Vue.$AuthStore.commit('updateStatus', false);
              cb(false);
            });
          } else {
            Vue.$AuthStore.commit('updateStatus', false);
            cb(false);
          }
        } else {
          Vue.$AuthStore.commit('updateStatus', false);
          cb(false);
        }
      }
    };
  }
};

export default AuthService;
