import * as CryptoJs from "crypto-js"
import {CONFIG} from "../config";

export function isMobile() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
      /iPhone|iPad|iPod/i.test(navigator.platform)) {
      return true
    } else {
      return false
    }
  }

function makeRandString(length) {
  let result           = '';
  const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
 }
 return result;
}

export function cryptoSign(val) {
  // Подписывает строку val. Добавляет в нее случайны salt и результат хеширует
  let base = `${val}:${makeRandString(5)}`
  let toSign = `${base}:${CONFIG.ACHA}`

  const sign = CryptoJs.SHA256(toSign);
  return `${base}:${sign}`;
}

export const dividingNumberDigits = (price)=>{
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
