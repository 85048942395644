<template>
  <div class="popup_login-switcher">
    <div
      class="popup_login-switcher__item"
      :class="{ active: value === 1 }"
      @click="$emit('input', 1)"
    >
      {{ $t("popup.login.retailTitle") }}
    </div>
    <div
      class="popup_login-switcher__item"
      :class="{ active: value === 2 }"
      @click="$emit('input', 2)"
    >
      {{ $t("popup.login.wholesaleTitle") }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
  },
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
.popup_login-switcher {
  margin-top: 27px;
  background: #e9e9e9;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2px;
}
.popup_login-switcher__item {
  cursor: pointer;
  border-radius: 4px;
  display: grid;
  align-items: center;
  justify-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #333333;

  &.active {
    background: #ffffff;
  }
}
</style>
